import {BrowserModule, Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {APP_INITIALIZER, NgModule} from "@angular/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
// 3rd party
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
// main modules
import {CommonsModule} from "./commons/commons.module";
import {SocialModule} from "./social/social.module";
import {HomeModule} from "./home/home.module";
import {UserSessionService} from "./user/services/user-session/user-session.service";
import {ApiCommunicationService} from "./model/services/api-communication/api-communication.service";
import {ModelModule} from "./model/model.module";
import {PageTitleService} from "./commons/services/page-title/page-title.service";
import {ProfileModule} from "./profile/profile.module";
import {UserModule} from "./user/user.module";
import {NgProgressModule} from "@ngx-progressbar/core";
import {FeedEventsService} from "./commons/services/feed-events/feed-events.service";
import {NgxOAuthModule} from "@bkoti/ngx-oauth-client";
import {OAuthApiClient} from "./model/utility/OAuthApiClient";
import {ModalService} from "./commons/services/modal/modal.service";
import {MODAL_SERVICE_TOKEN, ModalServiceProxy} from "./commons/services/modal/modal.service.proxy";
import {CartService} from "./model/services/cart/cart.service";
import {SweetAlertService} from "./commons/services/sweetalert/sweet-alert.service";
import {NotificationDataService} from "./model/services/notification-data/notification-data.service";
import {PostService} from "./social/services/post/post.service";
import {userSessionStarterFactory} from "./user/util/UserSessionStarterFactory";
import {PermissionLogicService} from "./model/services/permission-logic/permission-logic.service";
import {CheckoutModule} from "./checkout/checkout.module";
import {NoAuthGuardService} from "./user/guard/noauth-guard.service";
import {AuthGuardService} from "./user/guard/auth-guard.service";
import {ResponsiveModule} from "ngx-responsive";
import {CharityModule} from "./charity/charity.module";
import {NotificationModule} from "./notification/notification.module";
import {NgDatepickerModule} from "ng2-datepicker";
import {HomeFeedDataService} from "./home/services/home-feed-data/home-feed-data.service";
import {UpdateService} from "./commons/services/update/update.service";
import {ReportService} from "./social/services/report/report.service";
import {AuthServiceConfig, FacebookLoginProvider, SocialLoginModule} from "angularx-social-login";
import {environment} from "../environments/environment";
import {NgxSocialButtonModule, SocialServiceConfig} from "ngx-social-button";
import {NgProgressHttpModule} from "@ngx-progressbar/http";
import {CategoryService} from "./commons/services/category/category.service";
import {MasonaryService} from "./commons/services/masonary/masonary.service";

// ng2-responsive configuration
const responsiveConfig = {
	breakPoints: {
		xs: {max: 575},
		sm: {min: 576, max: 767},
		md: {min: 768, max: 991},
		lg: {min: 992, max: 1199},
		xl: {min: 1200}
	},
	debounceTime: 1000 // allow to debounce checking timer
};

// http loader for translations file
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}

// social login
const config = new AuthServiceConfig([
	{
		id: FacebookLoginProvider.PROVIDER_ID,
		provider: new FacebookLoginProvider(environment.facebookAppId)
	}
]);

export function provideConfig() {
	return config;
}

// social share
// https://www.npmjs.com/package/ngx-social-button
export function getAuthServiceConfigs() {
	return new SocialServiceConfig()
		.addFacebook(environment.facebookAppId);
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		// angular modules
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		ResponsiveModule.forRoot(responsiveConfig),
		NgDatepickerModule,

		// 3rd party modules
		// ngx-translate
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		// ng-bootstrap
		NgbModule.forRoot(),
		// ngx-progressbar
		NgProgressModule,
		NgProgressHttpModule,
		// ngx-facebook
		// FacebookModule.forRoot(),
		// ngx-oauth
		NgxOAuthModule,
		// social login
		SocialLoginModule,
		// share
		NgxSocialButtonModule,

		// lovegivr modules
		CommonsModule,
		SocialModule,
		HomeModule,
		ModelModule,
		ProfileModule,
		UserModule,
		CheckoutModule,
		CharityModule,
		NotificationModule
	],
	providers: [

		// Title service
		Title,

		// Page title service
		PageTitleService,

		// User session handler service
		UserSessionService,

		// Communication service
		ApiCommunicationService,

		// OAuth API client
		OAuthApiClient,

		// Feed events
		HomeFeedDataService,
		FeedEventsService,

		// Modal service and proxy
		{
			provide: MODAL_SERVICE_TOKEN,
			useClass: ModalService
		},
		// Modal service proxy
		ModalServiceProxy,

		// hook UserSessionService's session start action into app initialization hook
		{
			provide: APP_INITIALIZER,
			useFactory: userSessionStarterFactory,
			deps: [UserSessionService],
			multi: true
		},
		{
			provide: AuthServiceConfig,
			useFactory: provideConfig
		},
		{
			provide: SocialServiceConfig,
			useFactory: getAuthServiceConfigs
		},

		// Alerts
		SweetAlertService,

		// Notifications
		NotificationDataService,

		// Cart service
		CartService,

		// Permissions logic
		PermissionLogicService,

		// Post service
		PostService,

		UpdateService,

		ReportService,

		CategoryService,

		MasonaryService,

		// Auth guard (prevents unauthorized users from using 'login-required' pages)
		AuthGuardService,
		NoAuthGuardService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
