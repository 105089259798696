import {Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";

@Injectable({
	providedIn: "root"
})
export class PostBackgroundService {

	public backgrounds = [];
	private defaultBackground = {
		id: 1,
		selectorStyle: {
			background: "linear-gradient(0deg, green, gold)"
		},
		backgroundHtml: `<div style="border-radius:5px; background: linear-gradient(0deg, green, gold); height: 100%;">
			<div style="height:100%; background: url('https://upload.wikimedia.org/wikipedia/commons/4/40/Triangle_file.486.png')">
			</div>
		</div>`
	};

	constructor(private api: ApiCommunicationService) {
	}

	public loadBackgrounds(): void {
		this.api.post().getPostBackgrounds().subscribe(data => {
			this.backgrounds = data;
		});
	}

	public getBackground(id): any {
		return this.backgrounds.find(option => option.id === id) || this.defaultBackground;
	}
}
