import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {User} from "../../model/data/user/User";
import {ProductListResponse} from "../../model/response/ProductListResponse";

@Injectable()
export class OffersResolverService implements Resolve<ProductListResponse> {

	constructor(private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		Observable<ProductListResponse> | Promise<ProductListResponse> | ProductListResponse {

		// get the user object from parent route
		const user: User = route.parent.data.user;

		return this.api.profile().getOffers(1, user._id);
	}

}
