import {Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {BehaviorSubject} from "rxjs";
import {ContentListResponse} from "../../model/response/ContentListResponse";
import {User} from "../../model/data/user/User";

@Injectable()
export class UserFeedService {

	private _activeUser: User;
	private _userFeed$: BehaviorSubject<ContentListResponse> = new BehaviorSubject<ContentListResponse>(undefined);
	private _pageType: string;

	constructor(private api: ApiCommunicationService) {
	}

	public getNextPage() {
		if (this.getUserFeed().hasNextPage) {
			switch (this._pageType) {
				case "conversations":
					this.getNextConversations();
					break;
				case "blogs":
					this.getNextBlogs();
					break;
				case "events":
					this.getNextEvents();
					break;
				case "likes":
					this.getNextLikedContent();
					break;
				default:
					break;
			}
		}
	}

	private getNextConversations() {
		this.api.profile().getConversations(this.getUserFeed().nextPage, this.activeUser._id).subscribe(response =>
			this.setNextUserFeed(response)
		);
	}

	private getNextBlogs() {
		this.api.profile().getBlogPosts(this.getUserFeed().nextPage, this.activeUser._id).subscribe(response =>
			this.setNextUserFeed(response)
		);
	}

	private getNextEvents() {
		this.api.profile().getEvents(this.getUserFeed().nextPage, this.activeUser._id).subscribe(response =>
			this.setNextUserFeed(response)
		);
	}

	private getNextLikedContent() {
		this.api.profile().getLiked(this.getUserFeed().nextPage, this.activeUser._id).subscribe(response => {
			this.setNextUserFeed(this.parseLikedContent(response));
		});
	}

	public parseLikedContent(liked): ContentListResponse {
		const likedContent = [];
		const keys: Array<string> = Object.keys(liked);
		const parsedLikedFeed = new ContentListResponse();

		keys.forEach(k => {
			if (liked[k].docs) {
				if (liked[k].docs && liked[k].docs.length) {
					likedContent.push(...liked[k].docs);
				}

				if (liked[k].hasNextPage) {
					parsedLikedFeed.hasNextPage = true;
					parsedLikedFeed.nextPage = liked[k].nextPage;
				}
			}
		});

		parsedLikedFeed.docs = likedContent;

		return parsedLikedFeed;
	}

	get activeUser(): User {
		return this._activeUser;
	}

	set activeUser(value: User) {
		this._activeUser = value;
	}

	public setUserFeed(value: ContentListResponse, pageType: string) {
		this._pageType = pageType;
		this._userFeed$.next(value);
	}

	public setNextUserFeed(value: ContentListResponse) {
		value.docs = this.getUserFeed().docs.concat(value.docs);
		this._userFeed$.next(value);
	}

	public getUserFeed(): ContentListResponse {
		return this._userFeed$.getValue();
	}

	get userFeed$(): BehaviorSubject<ContentListResponse> {
		return this._userFeed$;
	}

}
