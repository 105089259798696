import {Observable, throwError as observableThrowError} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LoginWithCredentialsRequest} from "../request/auth/LoginWithCredentialsRequest";
import {Injector} from "@angular/core";
import {ResponseWrapper} from "../response/wrappers/ResponseWrapper";
import {LoginResponse} from "../response/LoginResponse";
import {RefreshTokenRequest} from "../request/auth/RefreshTokenRequest";
import {AuthorizationError} from "../exception/AuthorizationError";
import {LogoutRequest} from "../request/auth/LogoutRequest";
import {Configuration, DefaultHeaders, NgxOAuthClient, NgxOAuthResponse} from "@bkoti/ngx-oauth-client";
import {FacebookLoginRequest} from "../request/auth/FacebookLoginRequest";
import {LoginRejectionReason} from "../../user/services/user-session/user-session.service";

enum StorageKeys {
	CLIENT_ID = "client_id"
}

enum API_ROUTES {
	AUTH = "/api/v1/auth"
}

@Configuration({
	// base api
	host: environment.apiUrl,
	// local storage prefix
	storage_prefix: "lovegivr_",
	// default client id
	key: ""
})
@DefaultHeaders({
	"Content-Type": "application/json",
	"Accept": "application/json"
})
export class OAuthApiClient extends NgxOAuthClient {

	// last time the too many requests alert was shown
	private lastTooManyRequestsAlert;

	/**
	 * Constructor - all fields are injected.
	 * @param {HttpClient} http
	 * @param {Injector} injector
	 */
	constructor(http: HttpClient,
				private injector: Injector) {

		// pass the injected http client to super class
		super(http);
	}

	/**
	 * Ensures that user is logged in before firing a request.
	 * Throws {AuthorizationError} if no valid token found in local storage.
	 * @returns {OAuthApiClient} The service itself.
	 */
	public authorizedOnly(): OAuthApiClient {

		// check if access token is available
		if (!this.fetchToken("access_token")) {
			throw new AuthorizationError("Can not access this functionality. Please log in.");
		}

		// return the service itself
		return this;
	}

	/**
	 * Login API endpoint.
	 * @param {LoginWithCredentialsRequest} data Object containing login data (email:pw)
	 * @returns {Observable<ResponseWrapper<LoginResponse>>} Response object
	 */
	private endpoint_auth_login(data: LoginWithCredentialsRequest): Observable<LoginResponse> {
		return this.http.post<LoginResponse>(this.getConfig().host + API_ROUTES.AUTH, data);
	}

	/**
	 * Refresh token api endpoint.
	 * @param {RefreshTokenRequest} data Request body containing client id and token.
	 * @returns {Observable<ResponseWrapper<LoginResponse>>} Response object
	 */
	private endpoint_auth_refresh_token(data: RefreshTokenRequest): Observable<LoginResponse> {
		return this.http.post<LoginResponse>(
				this.getConfig().host + API_ROUTES.AUTH + "/refresh",
				// set date and headers
				data);
	}

	/**
	 * Logout api endpoint.
	 * @param token
	 * @param token_type
	 * @param data
	 */
	private endpoint_auth_logout(token: string, token_type: string, data: LogoutRequest): Observable<any> {
		return this.http.get<any>(
			this.getConfig().host + API_ROUTES.AUTH + "/logout", {headers: {Authorization: `${token_type} ${token}`}}
			);
	}

	/**
	 * Sets Client ID in local storage.
	 * @param {string} clientId The Client ID to store.
	 */
	protected setClientId(clientId: string): void {
		// set client id in local storage
		localStorage.setItem(this.fetchStorageNameOf(StorageKeys.CLIENT_ID), clientId);
	}

	/**
	 * Fetches name of a given storage.
	 * @param {StorageKeys} suffix The key of the storage.
	 * @returns {string} The prefixed name of the storage.
	 */
	protected fetchStorageNameOf(suffix: StorageKeys) {

		// check if suffix is set properly
		if (suffix === undefined || suffix === null) {
			throw new Error("Suffix of storage name can not be empty or null");
		}

		const prefix: string = this.fetchConfig("storage_prefix");

		let token = "";
		if (prefix) {
			token += prefix;
		}
		token += suffix.toString();
		return token;
	}

	/**
	 * Fetches Client ID from local storage.
	 * @returns {string} The Client ID or empty string.
	 */
	protected fetchClientId(): string {
		const value = localStorage.getItem(this.fetchStorageNameOf(StorageKeys.CLIENT_ID));

		// return value or empty string
		return value ? value : "";
	}

	/**
	 * Removes Client ID from local storage.
	 */
	protected clearClientId(): void {
		localStorage.removeItem(this.fetchStorageNameOf(StorageKeys.CLIENT_ID));
	}

	/**
	 * Translates {AuthenticationResponse} to {NgxOAuthResponse}.
	 * @param {LoginResponse} response The response to transform.
	 * @returns {NgxOAuthResponse} The transformed response
	 */
	protected translateAuthenticationResponse(response: LoginResponse): NgxOAuthResponse {
		return <NgxOAuthResponse> {
			token_type: response.type,
			access_token: response.accessToken,
			refresh_token: response.refreshToken
		};
	}

	/**
	 * Rewritten token acquiring process to match our api.
	 * @param data login data - only if grant type is "password"
	 * @param grant_type
	 * @returns {Observable<any>}
	 */
	public getToken(grant_type?: string, data?: any): Observable<NgxOAuthResponse> {

		if (grant_type && ["client_credentials", "authorization_code", "password", "refresh_token"].indexOf(grant_type) === -1) {
			throw new Error(`Grant type ${grant_type} is not supported`);
		}

		// fetch client id
		const client_id = this.fetchClientId();

		// refresh token request
		if (grant_type === "refresh_token") {

			// check for validity of the client id
			if (client_id === undefined || client_id === null || client_id.trim() === "") {
				throw new Error("Could not refresh token: client_id must be set");
			}

			// fetch refresh token and token type
			const refresh_token = this.fetchToken("refresh_token");
			// const token_type = this.fetchToken("token_type") || "Bearer";

			// check for validity of the refresh token
			if (refresh_token === undefined || refresh_token === null || refresh_token.trim() === "") {
				throw new Error("Could not refresh token: no refresh_token to use");
			}

			// Refresh token request (refresh_token grant type)
			return this.endpoint_auth_refresh_token({token: refresh_token, clientId: client_id}).pipe(map((res: LoginResponse) => {

				// translate the result parameters
				const transformed = this.translateAuthenticationResponse(res);

				// set and store client id
				this.setClientId(res.clientId);

				// set token
				this.setToken(transformed);

				// return the result
				return transformed;

			}));

		// normal login request using username and password
		} else if (grant_type === "password") {

			// add client id to the request data if not present
			if (!(<LoginWithCredentialsRequest> data).clientId && !(client_id === undefined || client_id === null || client_id.trim() === "")) {
				(<LoginWithCredentialsRequest> data).clientId = client_id;
			}

			// fire login request trough api
			return this.endpoint_auth_login(<LoginWithCredentialsRequest> data).pipe(

				// transform results to match the requirements of oauth module
				map((res: LoginResponse) => {

					// translate the result parameters
					const transformed = this.translateAuthenticationResponse(res);

					// set and store client id
					this.setClientId(res.clientId);

					// set token
					this.setToken(transformed);

					// return the result
					return transformed;

			}));

		// other grant types
		} else {

			// Currently no other grant types are implemented
			throw new Error(`The following grant type is not implemented: ${grant_type}.`);

		}
	}

	public endpoint_auth_facebook(data: FacebookLoginRequest): Promise<any> {
		return new Promise<LoginRejectionReason>((resolve, reject) => {
			this.http.post(this.getConfig().host + "/api/v1/facebook", data).subscribe((response: LoginResponse) => {

				// translate the result parameters
				const transformed = this.translateAuthenticationResponse(response);

				// set and store client id
				this.setClientId(response.clientId);

				// set token
				this.setToken(transformed);


				resolve();
			}, (error) => {
				// error handling
				reject(error);
			});
		});

	}

	/**
	 * This function logs out user, clears all entries in storage and calls server to invalidate tokens and client id.
	 * @returns {Promise<void>}
	 */
	public logoutAndClearStoredData(): Promise<void> {
		return new Promise((resolve, reject) => {

			const token = this.fetchToken("access_token");
			const token_type = this.fetchToken("token_type") || "Bearer";

			// clear stored token
			this.clearToken();

			// clear client id
			this.clearClientId();

			// create payload
			const logoutPayload: LogoutRequest = {
				client_id: this.fetchClientId()
			};

			// logout from server
			this.endpoint_auth_logout(token, token_type, logoutPayload).subscribe(
				// logout successful
				data => {
					resolve();
				},
				// could not log out
				err => {
					console.error("Logout error:");
					console.error(err);
					reject();
				}
			);

		});
	}

	/**
	 * Request interceptor. Intercepts request for adding auth header.
	 * @param request The request to intercept.
	 * @returns {any}
	 */
	requestInterceptor(request) {

		// get token and token type
		const token = this.fetchToken("access_token");
		const token_type = this.fetchToken("token_type") || "Bearer";

		// add token  to the request if it is available
		if (token) {
			return request.setHeaders({Authorization: token_type + " " + token});
		}

		return request;
	}

	/**
	 * Error interceptor handles request errors.
	 * Special case for status code "401 Unauthorized", for this case the following action will be taken:
	 * - Try to refresh token using refresh_token
	 * - Re-fire the request and return the results of it.
	 * @param request The request to intercept.
	 * @param error The http error.
	 * @returns {Observable<any>}
	 */
	errorInterceptor(request, error): Observable<any> {

		// special case for 401 Unauthorized
		if (error.status === 401) {

			// get refresh token
			const refresh_token = this.fetchToken("refresh_token");

			// fallback if refresh token is not available
			if (!refresh_token) {
				return observableThrowError(error);
			}

			// refresh token and re-fire request
			return this.getToken("refresh_token", {refresh_token}).pipe(

				// use new token to re-fire request
				switchMap(token => {

					// re-fire original request
					return this.getClient().request(
						request.method, request.url,
						this.requestInterceptor(request.setHeaders({Authorization: `${token.token_type} ${token.access_token}`}))
					);

				}),

				// catch token refresh errors and invalidate tokens
				catchError( refreshTokenError => {

					console.log("Could not refresh token: " + refreshTokenError);

					// clear stored token
					this.clearToken();

					// return the original error
					return observableThrowError(error);
				}), );
		} else if (error.status === 429) {
			// when was the last too many requests error message was shown
			// 15 seconds must pass in order to display it again
			if (!this.lastTooManyRequestsAlert || new Date().getTime() - this.lastTooManyRequestsAlert.getTime() > 15000) {
				alert("You have been making too many requests recently, please wait a few minutes.");
				this.lastTooManyRequestsAlert = new Date();
			}
		}

		// normal case: pass trough the error
		return observableThrowError(error);
	}

	public getAuthToken(): string {
		// get token and token type
		const token = this.fetchToken("access_token");
		const token_type = this.fetchToken("token_type") || "Bearer";

		if (!token) {
			throw new Error("Token was not found.");
		}

		return token_type + " " + token;
	}

	public formDataRequest(method: "POST" | "PUT" | "PATCH", endpoint: string, formData: FormData): Promise<any> {
		return new Promise((resolve, reject) => {

			const xhr: XMLHttpRequest = new XMLHttpRequest();

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						resolve(<ResponseWrapper<any>>JSON.parse(xhr.response));
					} else {
						reject(xhr.response);
					}
				}
			};

			xhr.open(method, environment.apiUrl + endpoint, true);
			xhr.setRequestHeader("Authorization", this.getAuthToken());
			xhr.send(formData);

		});
	}

}
