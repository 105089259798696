import {Injectable} from "@angular/core";
import {NgbDateAdapter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class IsoDateAdapter extends NgbDateAdapter<string> {

	pad(number) {
		if (number < 10) {
			return "0" + number;
		}
		return number;
	}

	fromModel(date: string): NgbDateStruct {
		const date2: Date = new Date(date);
		return (date2 && date2.getFullYear) ? {year: date2.getFullYear(), month: date2.getMonth() + 1, day: date2.getDate()} : null;
	}

	toModel(date: NgbDateStruct): string {
		return date ? date.year +
			"-" + this.pad(date.month + 1) +
			"-" + this.pad(date.day) : null;
	}
}
