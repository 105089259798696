import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {PostService} from "../../services/post/post.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Content} from "../../../model/content/Content";
import {Comment} from "../../../model/data/social/Comment";
import {CommentType} from "../../../model/data/enums/CommentType";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {TranslateService} from "@ngx-translate/core";
import {PostBackgroundService} from "../../../commons/services/post-background/post-background.service";

@Component({
	selector: "app-post-modal",
	templateUrl: "./post-modal.component.html",
	styleUrls: ["./post-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostModalComponent implements OnInit, OnDestroy, AfterViewChecked {

	// user avatar display mode
	public readonly _userAvatarDisplayType = UserAvatarDisplayType.IMAGE_ONLY;

	// Post data
	public post: Content;

	// Reference to the modal object (passed by PostService)
	public modalRef: NgbModalRef;

	// edit action reference (passed by PostService)
	public postServiceRef: PostService;

	public background: any;

	// if media contains audio (e.g. podcast audio). Shows audio player
	public audio = false;

	@ViewChild("backgroundRef", {static: true})
	private backgroundElementRef: ElementRef;

	// is editable
	public isEditMode = false;

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService,
				private translateService: TranslateService,
				private postBackgroundService: PostBackgroundService,
				@Inject(Router) private router: Router,
				@Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
				@Inject(UserSessionService) public sessionService: UserSessionService,
				@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef) {
		// this.router.events.subscribe((event) => {
		// 	if (event instanceof NavigationEnd) {
		// 		this.modalRef.close();
		// 	}
		// });
	}

	ngOnInit(): void {
		if (this.sessionService.userDataValue &&
			// @ts-ignore
			(this.post.user === this.sessionService.userDataValue._id || this.post.user._id === this.sessionService.userDataValue._id)) {
			this.isEditMode = true;
		}
		this.background = this.postBackgroundService.getBackground(this.post.backgroundId);

		// check for audio
		this.audio = this.post.media.some(m => m.audio);
	}

	ngOnDestroy(): void {
		// remove post query param on close modal event
		// this.router.navigate([], {
		// 	relativeTo: this.activatedRoute,
		// 	queryParams: {post: null},
		// 	queryParamsHandling: "merge"
		// }).then();
	}

	ngAfterViewChecked() {
		if (this.post.backgroundId) {
			this.setBackgroundSize();
		}
	}

	setBackgroundSize() {
		const text = document.querySelectorAll("#post-modal .description")[0];
		const post = document.querySelectorAll("#post-modal .text-only-post")[0];
		// @ts-ignore
		let textHeight = text.offsetHeight;
		if (textHeight < 400) {
			textHeight = 400;
		}
		// @ts-ignore
		post.style.height = textHeight + "px";
	}

	public sendComment(event: KeyboardEvent): void {

		if (event.key === "Enter") {

			// the source element of the event
			const inputElement: HTMLInputElement = (<HTMLInputElement>event.srcElement);

			// request to send
			const requestData = new Comment();
			requestData.message = inputElement.value;
			requestData.content = this.post._id;
			requestData.type = CommentType.POST;

			this.api.comment().comment(requestData)
				.subscribe((res) => {
					// add comment from server to the comments
					this.post.numberOfComments++;
					requestData.user = this.sessionService.userDataValue;
					requestData._id = res._id;
					requestData.comments = [];
					this.post.comments.push(requestData);

					// reset field
					(<HTMLInputElement>event.srcElement).value = "";

					// let the change detector know that changes happened
					this.changeDetector.markForCheck();
				});

		}
	}

	public editPostAction() {
		this.postServiceRef.openPostEditModal(this.post);
		this.modalRef.close();
	}

	deletePostAction() {
		this.alertService.confirm({title: this.translateService.instant("alert.delete")}).then((resp) => {
			if (resp.hasOwnProperty("value") && resp.value) {
				this.api.admin().deleteUgcContent(this.post._id).subscribe(() => {
					this.router.navigate(["/"]).then(() => {
						location.reload();
					});
				});
			}
		});
	}

	get isLongContent() : boolean {
		return this.post.description.length > 100;
	}

}
