import {AbstractApiConnector} from "./AbstractApiConnector";
import {ResponseWrapper} from "../response/wrappers/ResponseWrapper";
import {Observable} from "rxjs";
import {RatingRequest} from "../request/rating/RatingRequest";
import {Rating} from "../data/shop/rating/Rating";
import {Product} from "../data/shop/product/Product";

export class RatingApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/rating";

	public getProductRatings(productId: string): Observable<ResponseWrapper<Array<Rating>>> {
		return this.apiClient
			.get(this.apiRoute + "/" + productId);
	}

	public createProductRating(productId: string, rating: RatingRequest): Observable<ResponseWrapper<Product>> {
		return this.apiClient
			.authorizedOnly()
			.post(this.apiRoute + "/" + productId, rating);
	}

	public editProductRating(ratingId: string, rating: RatingRequest): Observable<ResponseWrapper<Product>> {
		return this.apiClient
			.authorizedOnly()
			.put(this.apiRoute + "/" + ratingId, rating);
	}

	public checkEligibility(productId: string): Observable<ResponseWrapper<Rating>> {
		return this.apiClient
			.authorizedOnly()
			.get(this.apiRoute + "/eligibility/" + productId);
	}

	public deleteProductRating(ratingId: string): Observable<ResponseWrapper<Product>> {
		return this.apiClient
			.authorizedOnly()
			.delete(this.apiRoute + "/" + ratingId);
	}


}
