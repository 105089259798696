import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {Router} from "@angular/router";
import {ApiCommunicationService} from "../api-communication/api-communication.service";
import {NotificationStatus} from "../../data/enums/NotificationStatus";
import {UserNotification} from "../../data/notification/UserNotification";
import {NotificationType} from "../../data/enums/NotificationType";
import {PostService} from "../../../social/services/post/post.service";

@Injectable()
export class NotificationDataService {

	// all notifications
	private _notifications: BehaviorSubject<Array<UserNotification>> = new BehaviorSubject<Array<UserNotification>>(null);

	// short list of notifications
	private _lastNotifications: BehaviorSubject<Array<UserNotification>> = new BehaviorSubject<Array<UserNotification>>(null);

	// number of notifications
	private _numberOfNotifications: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	// the ids of the notifications that don't need popup
	private noNeedForPopup: Set<string> = new Set();

	// interval timer
	private interval = null;

	constructor(public userSessionService: UserSessionService,
	            private router: Router,
	            private postService: PostService,
	            private api: ApiCommunicationService) {

		// create last notifications list from all notifications
		this._notifications.subscribe((notifications) => {
			// if notifications arrived
			if (notifications) {
				// use the newest 10 items
				this._lastNotifications.next(notifications.slice(0, 10));
			// possibly null or undefined
			} else {
				this._lastNotifications.next(notifications);
			}
		});

		// watch for user changes
		this.userSessionService.userData.subscribe((user) => {

			// get user's notifications
			if (user) {
				// do not start twice
				if (!this.interval) {
					this.fetchNotifications();
					this.interval = setInterval(() => this.fetchNotifications(), 20000);
				}
			} else {
				clearInterval(this.interval);
				this.interval = undefined;
				this._notifications.next(null);
			}
		});

	}

	private fetchNotifications(): void {

		// TODO RR
		// // get notifications
		// this.api.notification().getNotifications().subscribe((resp) => {
		//
		// 	if (!this._notifications.value) {
		// 		resp.data.forEach((n) => this.noNeedForPopup.add(n._id));
		// 	}
		//
		// 	if (resp) {
		//
		// 		// set notifications (and filter to only show new ones)
		// 		// this._notifications.next(resp.data.sort((n) => n.status === NotificationStatus.NEW ? -1 : 1));
		// 		this._notifications.next(resp.data);
		//
		// 		// filter - show only new notifications
		// 		const filteredResp = resp.data.filter((n) => n.status === NotificationStatus.NEW);
		//
		// 		// set number of notifications
		// 		this._numberOfNotifications.next(filteredResp.length);
		//
		// 		// check all notifications
		// 		resp.data.forEach((n) => {
		// 			if (!this.noNeedForPopup.has(n._id)) {
		//
		// 				// show text as popup
		// 				// this.simpleNotifications.success(n.text);
		// 				// add it to the known ones
		// 				this.noNeedForPopup.add(n._id);
		//
		// 			}
		// 		});
		//
		// 	} else {
		// 		this._numberOfNotifications.next(0);
		// 		this.noNeedForPopup = new Set();
		// 	}
		//
		// });

	}

	public handleNotification(notification: UserNotification): void {

		// make notification seen
		this.api.notification()
			.updateNotification(notification._id, {status: NotificationStatus.SEEN})
			.subscribe(() => {
				this.fetchNotifications();
			});

		// open content page/modal
		switch (notification.type) {

			// content is a product
			case NotificationType.PRODUCT:
				// go to product page
				this.router.navigate(["/product", notification.product[0].content._id]);
				break;

			// content is ugc
			case NotificationType.UGC:

				// sub-type is post
				if (notification.contentType === "post") {
					// open post modal
					// TODO RR
					// this.postService.openPostModal(notification.post[0].content);

				// sub-type is microblogpost
				} else if (notification.contentType === "microblogpost") {
					// go to blogpost page
					this.router.navigate(["/blogpost", notification.microblogpost]);

				// unknown type
				} else {
					console.error("Unknown content type for notification:" + notification.contentType);
				}
				break;

			// system notifications
			case NotificationType.SYSTEM:
				break;
		}

	}

	public clearAllNotifications() {
		// TODO RR
		// this.api.notification()
		// 	.clearAllNotifications()
		// 	.subscribe((response) => {
		// 		// update notifications
		// 		this.fetchNotifications();
		// 	});
	}


	get notifications(): Observable<Array<UserNotification>> {
		return this._notifications
			.asObservable();
	}

	get lastNotifications(): Observable<Array<UserNotification>> {
		return this._lastNotifications
			.asObservable();
	}

	get numberOfNotifications(): number {
		return this._numberOfNotifications.getValue();
	}
}
