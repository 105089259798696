import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import {FeedEventsService} from "../../../commons/services/feed-events/feed-events.service";
import {Product} from "../../../model/data/shop/product/Product";
import {Observable} from "rxjs";

declare var $: any;
declare var Packery: any;

@Component({
	selector: "app-product-feed",
	templateUrl: "./product-feed.component.html",
	styleUrls: ["./product-feed.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductFeedComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

	@ViewChild("productFeedContainer", { static: true })
	private containerElement: ElementRef;

	@Input()
	public feedData: Observable<Array<Product>>;

	private _feedElements: Array<Product> = [];

	// breakpoints - based on the number of columns displayed
	private breakPoints = {
		"columns-1": 400,
		"columns-2": 600,
		"columns-3": 1100,
		"columns-4": -1
	};

	// class representing the displayed columns
	private _columnsClass = "";

	// container size change subscription
	private containerSizeChangeEventSubscription: any;

	private packery: any;

	constructor(private productFeedEventsService: FeedEventsService,
	            private changeDetector: ChangeDetectorRef) {
	}

	/**
	 *
	 */
	ngOnInit() {

		// adjust column class
		this.adjustColumnClass();

		// subscribe on size change events
		this.containerSizeChangeEventSubscription = this.productFeedEventsService.containerSizeChangeEvent.subscribe(
			() => this.adjustLayout()
		);

		// fetch feed data and adjust layout
		this.feedData.subscribe((response) => {
			this._feedElements = response;
			this.changeDetector.detectChanges();
			// this.adjustLayout();
			setTimeout(() => {
				this.packery.reloadItems();
				this.packery.layout();
			}, 500);

		});

	}

	ngAfterViewInit(): void {

		// init packery layout
		this.packery = new Packery(this.containerElement.nativeElement, {
				itemSelector: ".item",
				percentPosition: true,
				resize: true
		});

		setTimeout(() => {
			this.packery.reloadItems();
			this.packery.layout();
		}, 500);

		setTimeout(() => {
			this.packery.reloadItems();
			this.packery.layout();
		}, 2500);
	}

	ngAfterViewChecked() {


	}

	ngOnDestroy(): void {
		// unsubscribe from feed events
		this.containerSizeChangeEventSubscription.unsubscribe();
	}

	private adjustLayout(): void {
		setTimeout(() => {

			this.adjustColumnClass();

			setTimeout(() => {
				this.packery.layout();
			}, 200);

		}, 300);
	}

	private adjustColumnClass() {

		// TODO make it less ugly in the future
		if (this.containerElement.nativeElement.offsetWidth <= this.breakPoints["columns-1"]) {
			this._columnsClass = "columns-1";
		} else if (this.containerElement.nativeElement.offsetWidth <= this.breakPoints["columns-2"]) {
			this._columnsClass = "columns-2";
		} else if (this.containerElement.nativeElement.offsetWidth <= this.breakPoints["columns-3"]) {
			this._columnsClass = "columns-3";
		} else {
			this._columnsClass = "columns-4";
		}

	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.changeDetector.detectChanges();
	}

	get feedElements(): Array<Product> {
		return this._feedElements;
	}

	get columnsClass(): string {
		return this._columnsClass;
	}

}
