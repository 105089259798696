import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {CheckoutPreProcessingRequest} from "../../../model/request/checkout/CheckoutPreProcessingRequest";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {Countries} from "../../../model/static/Countries";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {Router} from "@angular/router";
import {ShippingInfoService} from "../../services/shipping-info.service";

enum StorageKeys {
	BILLING_ADDRESS = "lovegivr_billing_address",
	SHIPPING_ADDRESS = "lovegivr_shipping_address",
	SHIPPING_IS_SHIPPING_SAME_AS_BILLING = "lovegivr_is_shipping_same_as_billing"
}

@Component({
	selector: "app-checkout-shipping-info-page",
	templateUrl: "./checkout-shipping-info-page.component.html",
	styleUrls: ["./checkout-shipping-info-page.component.scss"]
})
export class CheckoutShippingInfoPageComponent implements OnInit {

	public request: CheckoutPreProcessingRequest = new CheckoutPreProcessingRequest();

	@ViewChild("shippingForm", { static: true })
	private shippingForm: ElementRef;

	// save shipping address on this computer
	public saveShippingAddress = false;

	constructor(private userSessionService: UserSessionService,
				private api: ApiCommunicationService,
				private swal: SweetAlertService,
				private router: Router) {
	}

	ngOnInit() {
		// pre fill some data
		this.request.shipping_address.country = Countries[0].label;
		this.request.billing_address.country = Countries[0].label;

		// restore billing address if stored
		const stored_billing_address = localStorage.getItem(StorageKeys.BILLING_ADDRESS);
		if ( stored_billing_address ) {
			this.request.billing_address = JSON.parse(stored_billing_address);
		}

		// restore shipping address if stored
		const stored_shipping_address = localStorage.getItem(StorageKeys.SHIPPING_ADDRESS);
		if ( stored_shipping_address ) {
			this.request.billing_address = JSON.parse(stored_shipping_address);
		}
		const stored_is_shipping_same_as_billing = localStorage.getItem(StorageKeys.SHIPPING_IS_SHIPPING_SAME_AS_BILLING);
		if ( stored_is_shipping_same_as_billing ) {
			this.request.is_shipping_same_as_billing = JSON.parse(stored_is_shipping_same_as_billing);
		}

		// user based data
		this.userSessionService.userData.subscribe((user) => {
			if (user) {
				this.request.isLoggedInUser = true;
				this.request.shipping_address.fullName = user.displayName;
				this.request.billing_address.fullName = user.displayName;
				this.request.billing_address.email_address = user.email;
				this.request.shipping_address.email_address = user.email;
			}
		});
	}

	processForPayment() {

		// check form validity
		const isFormValid = (<HTMLFormElement> this.shippingForm.nativeElement).checkValidity();

		// set bootstrap class indicating state
		(<HTMLFormElement> this.shippingForm.nativeElement).classList.add("was-validated");

		// only take action when form is valid
		if (isFormValid) {
			// finalize request
			this.request.finalize();

			// save shipping and billing address
			if (this.saveShippingAddress) {
				localStorage.setItem(StorageKeys.BILLING_ADDRESS, JSON.stringify(this.request.billing_address));
				localStorage.setItem(StorageKeys.SHIPPING_ADDRESS, JSON.stringify(this.request.shipping_address));
				localStorage.setItem(StorageKeys.SHIPPING_IS_SHIPPING_SAME_AS_BILLING, JSON.stringify(this.request.is_shipping_same_as_billing));
			}

			// set data
			ShippingInfoService.setShippingInfo(this.request, this.userSessionService.isLoggedInValue);

			this.router.navigate(["/checkout", "payment"]);

		}
	}

	nav() {
		this.router.navigate([]).then(result => {
			window.open("/information", "_blank");
		});
	}
}
