import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Content} from "../content/Content";
import {ContentType} from "../data/enums/ContentType";
import {EventRequest} from "../request/event/EventRequest";
import {ReportRequest} from "../request/report/ReportRequest";

export class EventApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/event";

	public createEvent(request: EventRequest, images?: FileList): Promise<Content> {
		const formData = new FormData();

		if (request.link) {
			// external event
			formData.append("link", request.link);
		} else {
			// internal event
			formData.append("title", request.title);
			formData.append("description", request.description);
			// @ts-ignore
			formData.append("startDate", request.startDate);
			// @ts-ignore
			formData.append("endDate", request.endDate);
			formData.append("genreOfEvent", request.genreOfEvent);
			formData.append("location", request.location);
			// @ts-ignore
			formData.append("price", request.price);
			formData.append("linkToBookingSite", request.linkToBookingSite);
			// append the images if any
			if (images && images.length) {
				for (let i = 0; i < images.length; i++) {
					formData.append("image", images[i], images[i].name);
				}
			}
		}

		return this.apiClient.formDataRequest("POST", this.apiRoute, formData);
	}

	public editEvent(eventId: string, request: EventRequest, images?: FileList): Promise<Content> {
		const formData = new FormData();

		if (request.link) {
			// external event
			formData.append("link", request.link);
		} else {
			// internal event
			formData.append("title", request.title);
			formData.append("description", request.description);
			formData.append("startDate", request.startDate.toUTCString());
			formData.append("endDate", request.endDate.toUTCString());
			formData.append("genreOfEvent", request.genreOfEvent);
			formData.append("location", request.location);
			// @ts-ignore
			formData.append("price", request.price);
			formData.append("linkToBookingSite", request.linkToBookingSite);
			// append the images if any
			if (images && images.length) {
				for (let i = 0; i < images.length; i++) {
					formData.append("image", images[i], images[i].name);
				}
			}
		}

		return this.apiClient.formDataRequest("PATCH", this.apiRoute + `/${eventId}`, formData);
	}

	public getEvent(eventId: string): Observable<Content> {
		return this.apiClient.get(this.apiRoute + "/" + eventId);
	}

	public getEvents(pageNumber: number): Observable<Content> {
		return this.apiClient.get(`${this.apiRoute}/${pageNumber}/list`, {type: ContentType.EVENT});
	}

	public deleteEvent(eventId: string): Observable<any> {
		return this.apiClient.delete(`${this.apiRoute}/${eventId}`);
	}

	public report(request: ReportRequest): Observable<any> {
		return this.apiClient.post(this.apiRoute + "/report", request);
	}

}
