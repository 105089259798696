import {Component, OnInit} from "@angular/core";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";

@Component({
	selector: "app-profile-wallet",
	templateUrl: "./profile-wallet.component.html",
	styleUrls: ["./profile-wallet.component.scss"]
})
export class ProfileWalletComponent implements OnInit {

	constructor(public userSessionService: UserSessionService) {
	}

	ngOnInit() {
	}

}
