// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: true,
	// apiUrl: "http://localhost:3000",
	apiUrl: "https://sandbox.lovegivr.com/",
	facebookAppId: "472022596717506",
	recaptchaSiteKey: "6LeCmrsUAAAAACEotymynbxGkgEZzwjDfb65RTe7",
	stripeKey: "pk_test_8glXp421nOaK69WYalvdmsrp00yvmlyEjn",
	adminPortalUrl: "https://sandbox.lovegivr.com/admin/"
};
