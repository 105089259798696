export enum OrderStatus {
	AWAITING_PAYMENT = "AWAITING_PAYMENT",
	AWAITING_FULFILMENT = "AWAITING_FULFILMENT",
	AWAITING_SHIPMENT = "AWAITING_SHIPMENT",
	PARTIALLY_DISPATCHED = "PARTIALLY_DISPATCHED",
	COMPLETED = "COMPLETED",
	DISPATCHED = "DISPATCHED",
	CANCELLED = "CANCELLED",
	DECLINED = "DECLINED",
	REFUNDED = "REFUNDED",
	PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED"
}
