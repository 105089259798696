import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {ActivatedRoute} from "@angular/router";
import {ProfileChangeEventService} from "../../services/profile-change-event.service";

@Component({
	selector: "app-user-followers",
	templateUrl: "./user-followers.component.html",
	styleUrls: ["./user-followers.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFollowersComponent implements OnInit, OnDestroy {

	public username: string;

	public followers: Array<User> = [];
	private sub;

	constructor(@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
				private route: ActivatedRoute,
				private profileService: ProfileChangeEventService) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe( (data) => {

			this.username = data.username;
			this.followers = data.followers;

			this.changeDetector.detectChanges();
		});

		this.sub = this.profileService.followEvent.subscribe((followers: Array<User>) => {
			this.followers = followers;
			this.changeDetector.detectChanges();
		});


	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

}
