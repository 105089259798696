import {Component, Input, OnInit} from "@angular/core";
import {CartItem} from "../../../model/data/shop/cart/CartItem";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {CartService} from "../../../model/services/cart/cart.service";

@Component({
	selector: "app-checkout-cart-item",
	templateUrl: "./checkout-cart-item.component.html",
	styleUrls: ["./checkout-cart-item.component.scss"]
})
export class CheckoutCartItemComponent implements OnInit {

	public _userAvatarDisplayType = UserAvatarDisplayType.NAME_ONLY;

	@Input()
	public item: CartItem;

	/**
	 * Are cart changes enabled?
	 */
	@Input()
	public changesEnabled = true;

	constructor(private cartService: CartService) {
	}

	ngOnInit() {
	}

	updateQuantityAction(variantId: string, quantity: number) {
		this.cartService.updateQuantity(variantId, quantity, false);
	}

}
