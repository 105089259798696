import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {Observable} from "rxjs";
import {User} from "../../model/data/user/User";
import {LikedContent} from "../../model/data/social/LikedContent";
import {UserFeedService} from "../services/user-feed.service";

@Injectable()
export class LikedContentResolverService implements Resolve<LikedContent> {

	constructor(private api: ApiCommunicationService,
				private userFeedService: UserFeedService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LikedContent> | Promise<LikedContent> | LikedContent {

		// get the user object from parent route
		const user: User = route.parent.data.user;
		this.userFeedService.activeUser = user;

		return this.api.profile().getLiked(1, user._id);
	}

}
