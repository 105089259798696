import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {Collection} from "../../../model/data/collection/Collection";

@Component({
	selector: "app-user-collection",
	templateUrl: "./user-collection.component.html",
	styleUrls: ["./user-collection.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCollectionComponent implements OnInit {

	public username: string;
	public collection: Collection;
	public writePermission = false;

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private changeDetector: ChangeDetectorRef,
		private api: ApiCommunicationService) {
	}

	ngOnInit() {
		// get product from api
		this.route.data.subscribe((data) => {

			this.username = data.username;
			this.collection = data.collection;

			this.changeDetector.detectChanges();

		});

		this.route.parent.data.subscribe((parentData) => {
			this.writePermission = parentData.writePermission;

			this.changeDetector.detectChanges();
		});

	}

}
