import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {SearchRequest} from "../request/search/SearchRequest";
import {SearchSuggestionsResponse} from "../response/SearchSuggestionsResponse";
import {SearchListResponse} from "../response/SearchListResponse";

export class SearchApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/search";


	public search(pageNumber: number, searchRequest: SearchRequest): Observable<SearchListResponse> {
		return this.apiClient.post(`${this.apiRoute}/${pageNumber}`, SearchRequest.transform(searchRequest));
	}

	public getSuggestions(request: SearchRequest): Observable<SearchSuggestionsResponse> {
		return this.apiClient.post(`${this.apiRoute}/suggestions`, SearchRequest.transform(request));
	}

	public getTopTags(): Observable<Array<string>> {
		return this.apiClient.get(`${this.apiRoute}/topTags`);
	}

	public getTopPrice(): Observable<any> {
		return this.apiClient.get(`${this.apiRoute}/topPrice`);
	}

	public getTopBrands(): Observable<any> {
		return this.apiClient.get(`${this.apiRoute}/topBrands`);
	}

}
