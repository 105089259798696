import {of as observableOf, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {UserSessionService} from "../../user/services/user-session/user-session.service";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";

@Injectable()
export class PermissionResolverService implements Resolve<boolean> {

	constructor(private userSessionService: UserSessionService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		const user = this.userSessionService.userDataValue;

		// get the username from parent route's resolver
		return observableOf(user && route.params["username"] === user.userName);
	}
}
