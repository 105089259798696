import {Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";

@Component({
	selector: "app-checkout-page",
	templateUrl: "./checkout-page.component.html",
	styleUrls: ["./checkout-page.component.scss"]
})
export class CheckoutPageComponent implements OnInit {

	constructor(private location: Location,
				private router: Router,
				private title: PageTitleService) {

	}

	ngOnInit() {
		this.title.setPageTitle("Checkout");
	}

	backButtonAction() {
		this.location.back();
	}

}
