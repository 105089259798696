import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Comment} from "../data/social/Comment";

export class CommentApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/comment";

	public comment(comment: Comment): Observable<any> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, comment);
	}

	public getComment(commentId: string): Observable<Comment> {
		return this.apiClient.get(`${this.apiRoute}`, {content: commentId});
	}

	public deleteComment(commentId: string): Observable<any> {
		return this.apiClient.delete(`${this.apiRoute}/${commentId}`);
	}

	public editComment(commentId: string, message: string): Observable<Comment> {
		return this.apiClient.patch(`${this.apiRoute}/${commentId}`, {message: JSON.stringify(message)});
	}

}
