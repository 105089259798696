import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {Observable} from "rxjs";
import {User} from "../../model/data/user/User";
import {ContentListResponse} from "../../model/response/ContentListResponse";
import {UserFeedService} from "../services/user-feed.service";

@Injectable()
export class ConversationsResolverService implements Resolve<ContentListResponse> {

	constructor(private api: ApiCommunicationService,
				private userFeedService: UserFeedService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		Observable<ContentListResponse> | Promise<ContentListResponse> | ContentListResponse {

		// get the user object from parent route
		const user: User = route.parent.data.user;
		this.userFeedService.activeUser = user;

		return this.api.profile().getConversations(1, user._id);
	}

}
