import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {BlogPost} from "../../../model/data/social/BlogPost";

@Component({
	selector: "app-profile-blogposts",
	templateUrl: "./profile-blogposts.component.html",
	styleUrls: ["./profile-blogposts.component.scss"]
})
export class ProfileBlogpostsComponent implements OnInit {

	posts: Array<BlogPost>;

	constructor(private api: ApiCommunicationService,
	            private cd: ChangeDetectorRef) {
	}

	ngOnInit() {
        this.api.microblogpost().getUserMicroBlogPosts()
	        .subscribe((r) => {
	        	this.posts = r;
	        	this.cd.detectChanges();
	        });
	}

}
