import {DatabaseEntity} from "../data/DatabaseEntity";
import {User} from "../data/user/User";
import {Product} from "../data/shop/product/Product";
import {Like} from "../data/social/Like";
import {Comment} from "../data/social/Comment";

export class Review extends DatabaseEntity {

	product: Product;
	message: string;
	user: Partial<User>;
	rating: number;
	likes?: Array<Like>;
	comments?: Array<Comment>;

}
