import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
	// lazy loaded blogging
	{
		path: "blogs",
		loadChildren: () => import("./blogs/blogs.module.js").then(m => m.BlogsModule)
	},
	// lazy loaded registration module
	{
		path: "registration",
		loadChildren: () => import("./registration/registration.module").then(m => m.RegistrationModule)
	},
	// lazy loaded charity module
	{
		path: "charities",
		loadChildren: () => import("./charity/charity.module").then(m => m.CharityModule)
	},
	// lazy loaded information module
	{
		path: "information",
		loadChildren: () => import("./information/information.module").then(m => m.InformationModule)
	},
	// lazy loaded profile module
	{
		path: "profile",
		loadChildren: () => import("./my-profile/my-profile.module").then(m => m.MyProfileModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: false, anchorScrolling: "enabled"})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
