import {Component, EventEmitter, OnInit} from "@angular/core";
import {Product} from "../../../model/data/shop/product/Product";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {ReviewListResponse} from "../../../model/response/ReviewListResponse";

@Component({
	selector: "app-product-ratings-modal",
	templateUrl: "./product-ratings-modal.component.html",
	styleUrls: ["./product-ratings-modal.component.scss"]
})
export class ProductRatingsModalComponent implements OnInit {

	public reviews: ReviewListResponse;

	public product: Product;

	public avatarDisplayType = UserAvatarDisplayType.NAME_NEXT_TO_IMAGE;

	public openEditModalEvent: EventEmitter<void> = new EventEmitter<void>();

	constructor(public userSessionService: UserSessionService) {
	}

	ngOnInit() {
	}

	public openEditModal() {
		this.openEditModalEvent.emit();
	}

	public isEditable(userId: string) {
		return this.userSessionService.userDataValue._id === userId;
	}


}
