import {animate, style, transition, trigger} from "@angular/animations";

export const mainFeedAnimations =
	trigger("fadeIn", [
		transition(":enter", [
			style({ opacity: 0 }),
			animate(700, style({ opacity: 0, transform: "none" })),
			// animate(200, style({ opacity: 1 }))
		])
	]);

