import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {ChangePasswordRequest} from "../../../model/request/user/ChangePasswordRequest";
import {TranslateService} from "@ngx-translate/core";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";

@Component({
	selector: "app-change-password-page",
	templateUrl: "./change-password-page.component.html",
	styleUrls: ["./change-password-page.component.scss"]
})
export class ChangePasswordPageComponent implements OnInit, AfterViewInit {

	@ViewChild("changeForm", { static: true })
	private changeForm: ElementRef;
	@ViewChild("passwordInputFlied", { static: true })
	private passwordInputField: ElementRef;
	@ViewChild("passwordConfirmInputField", { static: true })
	private passwordConfirmInputField: ElementRef;
	@ViewChild("currentPasswordInputField", { static: true })
	private currentPasswordInputField: ElementRef;

	public errorMessage: string;
	public success = false;
	public isLoading = false;

	private userName: string;

	public changePasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();

	constructor(private api: ApiCommunicationService,
				private router: Router,
				private route: ActivatedRoute,
				private pageTitleService: PageTitleService,
				private alertService: SweetAlertService,
				private translate: TranslateService) {
	}

	ngOnInit() {
		this.pageTitleService.setPageTitle("Change password");
		this.userName = this.route.snapshot.params["userName"];
	}

	ngAfterViewInit(): void {
		// focus on element when modal is shown
		(<HTMLInputElement> this.passwordInputField.nativeElement).focus();
	}

	public sendChangePasswordRequest() {

		// reset error message
		this.errorMessage = null;

		// check form validity
		const isFormValid = (<HTMLFormElement> this.changeForm.nativeElement).checkValidity();

		// set bootstrap class indicating state

		(<HTMLFormElement> this.changeForm.nativeElement).classList.add("was-validated");

		if (isFormValid) {
			this.isLoading = true;
			this.success = false;

			this.api.profile().changePassword(this.changePasswordRequest).subscribe((response) => {
				this.success = true;
				this.errorMessage = this.translate.instant("account.change-password.change-success-hint");

				// show alert about successful password change
				this.alertService.success({title: this.errorMessage})
				// after pressing OK
					.then(() => {
						// go to profile screen
						this.router.navigate(["/user", this.userName]);
					});
	
			}, (error) => {
				// display error if passwords do not match or other issue happened
				if (error.status === 400) {
					this.errorMessage = this.translate.instant("account.change-password.change-no-match-hint");
				} else {
					this.errorMessage = this.translate.instant("account.reset-password.reset-error-hint");
				}
				this.isLoading = false;
			});
		}

	}

	// set validity of confirm field
	public passwordConfirmChange() {
		if (this.changePasswordRequest.newPassword !== this.changePasswordRequest.newPasswordConfirm) {
			(<HTMLInputElement> this.passwordConfirmInputField.nativeElement).setCustomValidity("does-not-match");
		} else {
			(<HTMLInputElement> this.passwordConfirmInputField.nativeElement).setCustomValidity("");
		}
	}

}
