import {AbstractApiConnector} from "./AbstractApiConnector";
import {PostRequest} from "../request/post/PostRequest";
import {Observable} from "rxjs";
import {ContentListResponse} from "../response/ContentListResponse";
import {ContentType} from "../data/enums/ContentType";
import {Content} from "../content/Content";
import {ReportRequest} from "../request/report/ReportRequest";

export class PostApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/post";

	public createPost(request: PostRequest, images?: FileList, videos?: FileList): Promise<Content> {
		const formData = new FormData();

		if (request.description && request.description.trim() !== "") {
			formData.append("description", request.description);
		}

		if (request.backgroundId) {
			formData.append("backgroundId", request.backgroundId.toString());
		}

		// append the images if any
		if (images && images.length) {
			for (let i = 0; i < images.length; i++) {
				formData.append("image", images[i], images[i].name);
			}
		}

		// append the images if any
		if (videos && videos.length) {
			for (let i = 0; i < videos.length; i++) {
				formData.append("video", videos[i], videos[i].name);
			}
		}


		return this.apiClient
			.formDataRequest("POST", this.apiRoute, formData);
	}

	public editPost(postId: string, request: PostRequest, images?: FileList, videos?: FileList): Promise<Content> {
		const formData = new FormData();

		if (request.description && request.description.trim() !== "") {
			formData.append("description", request.description);
		}

		if (request.backgroundId) {
			formData.append("backgroundId", request.backgroundId.toString());
		}

		// append the images if any
		if (images && images.length) {
			for (let i = 0; i < images.length; i++) {
				formData.append("image", images[i], images[i].name);
			}
		}

		// append the images if any
		if (videos && videos.length) {
			for (let i = 0; i < videos.length; i++) {
				formData.append("video", videos[i], videos[i].name);
			}
		}

		return this.apiClient
			.formDataRequest("PATCH", this.apiRoute + "/" + postId, formData);
	}

	public report(request: ReportRequest): Observable<any> {
		return this.apiClient.authorizedOnly().post(this.apiRoute + "/report", request);
	}

	public getPost(id: string): Observable<Content> {
		return this.apiClient.get(`${this.apiRoute}/${id}`);
	}

	public getPosts(pageNumber: number): Observable<ContentListResponse> {
		return this.apiClient.get(`${this.apiRoute}/${pageNumber}/list`, {type: ContentType.POST});
	}

	public deletePost(postId: string): Observable<any> {
		return this.apiClient.delete(`${this.apiRoute}/${postId}`);
	}

	public getPostBackgrounds(): Observable<any> {
		return this.apiClient.get(`${this.apiRoute}/background`);
	}

}
