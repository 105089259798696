import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DrawerComponent} from "../drawer/drawer.component";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {ModalServiceProxy} from "../../services/modal/modal.service.proxy";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {HomeFeedDataService} from "../../../home/services/home-feed-data/home-feed-data.service";
import {Subscription} from "rxjs";
import {ContentType} from "../../../model/data/enums/ContentType";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {

	private _text = "";

	@ViewChild("drawer", { static: true })
	private drawer: DrawerComponent;

	private updateSubscription: Subscription;

	private _isFloatingSearchActive = false;
	private readonly floatingSearchBreakpoint = 767;

	constructor(
		private modalServiceProxy: ModalServiceProxy,
		private feedService: HomeFeedDataService,
		public userSessionService: UserSessionService,
		private router: Router
	) {
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.updateSubscription = this.feedService.filter$.subscribe(f => {
			if (f && f.text) {
				this._text = f.text;
			} else {
				this._text = "";
			}
		});
	}

	ngOnDestroy(): void {
		this.updateSubscription.unsubscribe();
	}

	public loginAction(): void {
		this.modalServiceProxy.get().showLoginModal();
	}

	// hard reset for root
	public onClickRoot() {
		this.router.navigate(["/"]).then(() => {
			location.reload();
			window.history.replaceState({}, "", "");
		});
	}

	public onSearchAction() {
		this.search();
	}

	public activateFloatingSearch() {
		// weird hack, but works
		setTimeout(() => {
			this._isFloatingSearchActive = true;
		}, 50);
	}


	public onFloatingSearchAction(text: string) {
		this._isFloatingSearchActive = false;
		if (this.text !== text) {
			this.text = text;
			this.search();
		}
	}

	private search() {
		this.router.navigate(["/"]).then(() => {
			const f = this.getFilter();
			if (this.text.trim().length) {
				let suggestedType;
				if (this.text.toLowerCase().includes("event")) {
					suggestedType = ContentType.EVENT;
				}
				if (this.text.toLowerCase().includes("product")) {
					suggestedType = ContentType.PRODUCT;
				}
				if (this.text.toLowerCase().includes("post") || this.text.toLowerCase().includes("conversation")) {
					if (this.text.toLowerCase().includes("blog")) {
						suggestedType = ContentType.MICROBLOGPOST;
					} else {
						suggestedType = ContentType.POST;
					}
				}
				if (this.text.toLowerCase().includes("blog")) {
					suggestedType = ContentType.MICROBLOGPOST;
				}

				// set type filter if we found any word to that effect
				if (suggestedType) {
					f.types = [suggestedType];
				}

				f.text = this.text;
			} else {
				delete f.text;
			}
			this.feedService.setFilter(f);
		});
	}

	public isScreenSizeBelowMd(): boolean {
		return window.innerWidth <= this.floatingSearchBreakpoint;
	}

	public getFilter() {
		return this.feedService.getFilter();
	}

	get adminPortalUrl(): string {
		return environment.adminPortalUrl;
	}

	get text(): string {
		return this._text;
	}

	set text(value: string) {
		this._text = value;
	}

	get isFloatingSearchActive(): boolean {
		return this._isFloatingSearchActive;
	}

}
