import {Component, Inject, OnInit} from "@angular/core";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: "app-email-verification",
	templateUrl: "./email-verification.component.html",
	styleUrls: ["./email-verification.component.scss"]
})
export class EmailVerificationComponent implements OnInit {

	constructor(private sweetAlertService: SweetAlertService,
				private modalProxy: ModalServiceProxy,
				private route: ActivatedRoute,
				private router: Router,
				@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	ngOnInit() {
		const token = this.route.snapshot.params["token"];

		if (token) {
			this.api.profile().verifyAccount(token).subscribe((resp) => {
				this.sweetAlertService.success({title: "Your account has been successfully verified.\n You can login now."}).then(() => {
					this.router.navigate(["/"]).then(() => {
						this.modalProxy.get().showLoginModal();
					});
				});
			}, error => {
				console.log(error);
				this.popupError();
			});
		} else {
			this.popupError();
		}
	}

	private popupError() {
		this.sweetAlertService.error({title: "Unknown error happened. Please try again later."}).then(() => {
			this.router.navigate(["/"]);
		});
	}

}
