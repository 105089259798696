import {Component, HostListener, Inject, OnInit} from "@angular/core";
import {FeedEventsService} from "../../../commons/services/feed-events/feed-events.service";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {ActivatedRoute} from "@angular/router";
import {PostService} from "../../../social/services/post/post.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";

@Component({
	selector: "app-home-page",
	templateUrl: "./home-page.component.html",
	styleUrls: ["./home-page.component.scss"]
})
export class HomePageComponent implements OnInit {

	// sm screen
	private readonly bootstrapScreenWidthSm = 770;

	// toggles for sidebars
	private _isLeftActive = true;
	private _isRightActive = true;

	private contentId: string;

	constructor(
		@Inject(FeedEventsService)
		private productFeedEventsService: FeedEventsService,

		@Inject(PageTitleService)
		private pageTitleService: PageTitleService,

		@Inject(ApiCommunicationService)
		private api: ApiCommunicationService,

		public route: ActivatedRoute,
		private postService: PostService,
	) {

	}

	ngOnInit() {
		this.route.queryParams.subscribe((params => {
			this.contentId = params["content"];
			if (this.contentId) {
				this.api.post().getPost(this.contentId).subscribe((data) => {
					this.postService.openPostModal(data);
				});
			}
		}));

		// reset page title
		this.pageTitleService.setPageTitle("Home");

		// show or hide left and right sidebars based on window width
		if (window.innerWidth <= this.bootstrapScreenWidthSm) {
			this._isLeftActive = false;
			this._isRightActive = false;
		}

	}

	toggleLeftSideBar() {
		// toggle the sidebar
		this._isLeftActive = !this._isLeftActive;

		// wait for the animation to finish
		setTimeout( () => {
			// emit size change event
			this.productFeedEventsService.containerSizeChangeEvent.emit();
		}, 1000);
	}

	toggleRightSideBar() {
		// toggle the sidebar
		this._isRightActive = !this._isRightActive;

		// wait for the animation to finish
		setTimeout( () => {
			// emit size change event
			this.productFeedEventsService.containerSizeChangeEvent.emit();
		}, 1000);
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {

		// show or hide left and right sidebars based on window width
		if (event.target.innerWidth < this.bootstrapScreenWidthSm) {
			this._isLeftActive = false;
			this._isRightActive = false;
		} else {
			this._isLeftActive = true;
			this._isRightActive = true;
		}
		// emit size change event
		this.productFeedEventsService.containerSizeChangeEvent.emit();
	}


	get isLeftActive(): boolean {
		return this._isLeftActive;
	}

	get isRightActive(): boolean {
		return this._isRightActive;
	}

}
