import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {PostRequest} from "../../../model/request/post/PostRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Content} from "../../../model/content/Content";
import {PostService} from "../../services/post/post.service";
import {UpdateService} from "../../../commons/services/update/update.service";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {User} from "../../../model/data/user/User";

@Component({
	selector: "app-create-post-modal",
	templateUrl: "./create-post-modal.component.html",
	styleUrls: ["./create-post-modal.component.scss"],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePostModalComponent implements OnInit {

	public request: PostRequest = new PostRequest();
	public isLoading = false;

	@ViewChild("imageInput", { static: true })
	public imageInput: ElementRef;

	@ViewChild("videoInput", { static: true })
	public videoInput: ElementRef;

	public modalRef: NgbModalRef;

	// edit action reference (passed by PostService)
	public postServiceRef: PostService;

	public selectedBackground: any;
	imagePreviewUrls = [];
	videoPreviewUrl = null;

	public currentUser: User;

	constructor(private api: ApiCommunicationService,
				private updateService: UpdateService,
				private alertService: SweetAlertService,
				private userService: UserSessionService) {
	}

	ngOnInit() {
		this.userService.userData.subscribe(u => this.currentUser = u);
	}

	onImageSelected(event) {
		console.log(event.target);
		this.imagePreviewUrls = [];
		const files = event.target.files;
		Array.from(files).forEach(file => {
			const reader = new FileReader();

			reader.readAsDataURL(<Blob> file);
			reader.onload = (event) => {
				this.imagePreviewUrls.push((<FileReader>event.target).result);
			};
		});
	}

	onVideoSelected(event) {
		const file = event.target.files && event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(<Blob> file);
			reader.onload = (event) => {
				this.videoPreviewUrl = (<FileReader>event.target).result;
			};
		}
	}

	mediaAttached(): boolean {
		return this.imagePreviewUrls.length > 0;
	}

	createPostAction(): void {
		this.isLoading = true;

		if (!this.request.description) {
			this.isLoading = false;
			this.alertService.error({title: `Could not create your conversation. <br> Text is missing.`});
			return;
		}

		if (this.selectedBackground) {
			this.request.backgroundId = this.selectedBackground.id;
		}

		this.api.post()
			.createPost(
				this.request,
				(<HTMLInputElement>this.imageInput.nativeElement).files, (<HTMLInputElement>this.videoInput.nativeElement).files
			)
			.then((data: Content) => {
				this.isLoading = false;
				this.modalRef.close();
				this.alertService.success({title: "Your conversation has been added"}).then(() => {
					this.modalRef.close();
					this.updateService.updatePost(null);
					this.api.post().getPost(data._id).subscribe((p) => {
						this.postServiceRef.openPostModal(p);
					});
				});
			}).catch((err) => {
				err = JSON.parse(err);
				if (err.status === 413) {
					this.alertService.error({title: `Could not add your conversation. <br> The size of the selected image(s) is too large. (10Mb)`});
				} else {
					if (err.message === "IMAGE RESIZE ERROR") {
						err.message = "Image(s) can not be resized";
					}
					this.alertService.error({title: `Could not add your conversation. <br> ${err.message}.`});
				}
				this.isLoading = false;
				(<HTMLInputElement>this.videoInput.nativeElement).value = "";
				(<HTMLInputElement>this.imageInput.nativeElement).value = "";
		});
	}

}
