import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthApiClient} from "../../utility/OAuthApiClient";
import {AbstractApiConnector} from "../../connectors/AbstractApiConnector";
import {AuthApiConnector} from "../../connectors/AuthApiConnector";
import {CategoryApiConnector} from "../../connectors/CategoryApiConnector";
import {PaymentApiConnector} from "../../connectors/PaymentApiConnector";
import {ProfileApiConnector} from "../../connectors/ProfileApiConnector";
import {CartApiConnector} from "../../connectors/CartApiConnector";
import {FeedApiConnector} from "../../connectors/FeedApiConnector";
import {LikeApiConnector} from "../../connectors/LikeApiConnector";
import {CollectionApiConnector} from "../../connectors/CollectionApiConnector";
import {CommentApiConnector} from "../../connectors/CommentApiConnector";
import {EventApiConnector} from "../../connectors/EventApiConnector";
import {ImageApiConnector} from "../../connectors/ImageApiConnector";
import {InventoryApiConnector} from "../../connectors/InventoryApiConnector";
import {MicroBlogPostApiConnector} from "../../connectors/MicroBlogPostApiConnector";
import {PostApiConnector} from "../../connectors/PostApiConnector";
import {RatingApiConnector} from "../../connectors/RatingApiConnector";
import {ShareApiConnector} from "../../connectors/ShareApiConnector";
import {ApiConnectorWithIdAlreadyPresentError} from "../../exception/ApiConnectorWithIdAlreadyPresentError";
import {VideoApiConnector} from "../../connectors/VideoApiConnector";
import {WishListApiConnector} from "../../connectors/WishListApiConnector";
import {environment} from "../../../../environments/environment";
import {NotificationApiConnector} from "../../connectors/NotificationApiConnector";
import {ProductApiConnector} from "../../connectors/ProductApiConnector";
import {CharityApiConnector} from "../../connectors/CharityApiConnector";
import {SearchApiConnector} from "../../connectors/SearchApiConnector";
import {RelationshipApiConnector} from "../../connectors/RelationshipApiConnector";
import {ReviewApiConnector} from "../../connectors/ReviewApiConnector";
import {OrderApiConnector} from "../../connectors/OrderApiConnector";
import {AdminApiConnector} from "../../connectors/AdminApiConnector";
import {CommissionApiConnector} from "../../connectors/CommissionApiConnector";

/**
 * API Connectors have a unique enum identifier assigned to them.
 */
export enum Connector {
	AUTH = "Auth",
	ADMIN = "Admin",
	CART = "Cart",
	CATEGORY = "Category",
	CHARITY = "Charity",
	COLLECTION = "Collection",
	COMMENT = "Comment",
	EVENT = "Event",
	FEED = "Feed",
	IMAGE = "Image",
	INVENTORY = "Inventory",
	LIKE = "Like",
	MICROBLOGPOST = "MicroBlogPost",
	NOTIFICATION = "Notification",
	PAYMENT = "Payment",
	POST = "Post",
	PRODUCT = "Product",
	RATING = "Rating",
	SHARE = "Share",
	PROFILE = "Profile",
	VIDEO = "Video",
	WISHLIST = "WishList",
	SEARCH = "Search",
	RELATIONSHIP = "Relationship",
	REVIEW = "Review",
	ORDER = "Order",
	COMMISSION = "Commission"
}

/**
 * This service is responsible for all the api communication towards the backend, acting as a hub for all the different connectors.
 */
@Injectable()
export class ApiCommunicationService {

	// api base url
	private apiBaseUrl: string = environment.apiUrl;

	// connector list
	private connectors: Map<Connector, AbstractApiConnector>;

	constructor(@Inject(HttpClient) private http: HttpClient,
				@Inject(OAuthApiClient) private apiClient: OAuthApiClient) {

		// create the map of connectors
		this.connectors = new Map<Connector, AbstractApiConnector>();

		// register all the connectors
		this.registerConnector(Connector.AUTH, new AuthApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.ADMIN, new AdminApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.CART, new CartApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.CATEGORY, new CategoryApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.CHARITY, new CharityApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.COLLECTION, new CollectionApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.COMMENT, new CommentApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.EVENT, new EventApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.FEED, new FeedApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.IMAGE, new ImageApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.INVENTORY, new InventoryApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.LIKE, new LikeApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.MICROBLOGPOST, new MicroBlogPostApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.NOTIFICATION, new NotificationApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.PAYMENT, new PaymentApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.PRODUCT, new ProductApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.POST, new PostApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.RATING, new RatingApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.SHARE, new ShareApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.PROFILE, new ProfileApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.VIDEO, new VideoApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.WISHLIST, new WishListApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.SEARCH, new SearchApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.RELATIONSHIP, new RelationshipApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.REVIEW, new ReviewApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.ORDER, new OrderApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.COMMISSION, new CommissionApiConnector(http, apiClient, this.apiBaseUrl));

	}

	/**
	 * This function registers a connector to the connector pool.
	 * @param {Connector} id The unique identifier for a connector.
	 * @param {AbstractApiConnector} connector The connector to register.
	 */
	private registerConnector(id: Connector, connector: AbstractApiConnector) {

		// check if id is not already registered
		if (this.connectors.has(id)) {
			throw new ApiConnectorWithIdAlreadyPresentError("A connector with ID '" + id + "' has already been registered.");
		}

		// register connector with the given ID.
		try {
			this.connectors.set(id, connector);
		} catch (e) {
			console.error("Could not register connector: " + e);
		}
	}

	protected getConnector(connector: Connector): AbstractApiConnector {

		// check if connector is registered
		if (!this.connectors.has(connector)) {
			throw new Error("No connector is registered for: " + connector);
		}

		return this.connectors.get(connector);
	}

	/*** API connector getters ***/

	public auth(): AuthApiConnector {
		return (<AuthApiConnector> this.getConnector(Connector.AUTH));
	}

	public admin(): AdminApiConnector {
		return (<AdminApiConnector> this.getConnector(Connector.ADMIN));
	}

	public cart(): CartApiConnector {
		return (<CartApiConnector> this.getConnector(Connector.CART));
	}

	public category(): CategoryApiConnector {
		return (<CategoryApiConnector> this.getConnector(Connector.CATEGORY));
	}

	public charity(): CharityApiConnector {
		return (<CharityApiConnector> this.getConnector(Connector.CHARITY));
	}

	public comment(): CommentApiConnector {
		return (<CommentApiConnector> this.getConnector(Connector.COMMENT));
	}

	public event(): EventApiConnector {
		return (<EventApiConnector> this.getConnector(Connector.EVENT));
	}

	public feed(): FeedApiConnector {
		return (<FeedApiConnector> this.getConnector(Connector.FEED));
	}

	public image(): ImageApiConnector {
		return (<ImageApiConnector> this.getConnector(Connector.IMAGE));
	}

	public inventory(): InventoryApiConnector {
		return (<InventoryApiConnector> this.getConnector(Connector.INVENTORY));
	}

	public like(): LikeApiConnector {
		return (<LikeApiConnector> this.getConnector(Connector.LIKE));
	}

	public microblogpost(): MicroBlogPostApiConnector {
		return (<MicroBlogPostApiConnector> this.getConnector(Connector.MICROBLOGPOST));
	}

	public notification(): NotificationApiConnector {
		return (<NotificationApiConnector> this.getConnector(Connector.NOTIFICATION));
	}

	public payment(): PaymentApiConnector {
		return (<PaymentApiConnector> this.getConnector(Connector.PAYMENT));
	}

	public product(): ProductApiConnector {
		return (<ProductApiConnector> this.getConnector(Connector.PRODUCT));
	}

	public post(): PostApiConnector {
		return (<PostApiConnector> this.getConnector(Connector.POST));
	}

	public rating(): RatingApiConnector {
		return (<RatingApiConnector> this.getConnector(Connector.RATING));
	}

	public share(): ShareApiConnector {
		return (<ShareApiConnector> this.getConnector(Connector.SHARE));
	}

	public profile(): ProfileApiConnector {
		return (<ProfileApiConnector> this.getConnector(Connector.PROFILE));
	}

	public video(): VideoApiConnector {
		return (<VideoApiConnector> this.getConnector(Connector.VIDEO));
	}

	public wishlist(): WishListApiConnector {
		return (<WishListApiConnector> this.getConnector(Connector.WISHLIST));
	}

	public search(): SearchApiConnector {
		return (<SearchApiConnector> this.getConnector(Connector.SEARCH));
	}

	public relationship(): RelationshipApiConnector {
		return (<RelationshipApiConnector> this.getConnector(Connector.RELATIONSHIP));
	}

	public collection(): CollectionApiConnector {
		return (<CollectionApiConnector> this.getConnector(Connector.COLLECTION));
	}

	public review(): ReviewApiConnector {
		return (<ReviewApiConnector> this.getConnector(Connector.REVIEW));
	}

	public order(): OrderApiConnector {
		return (<OrderApiConnector> this.getConnector(Connector.ORDER));
	}

	public commission(): CommissionApiConnector {
		return (<CommissionApiConnector> this.getConnector(Connector.COMMISSION));
	}

}

