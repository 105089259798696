import {Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {BehaviorSubject, Observable} from "rxjs";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {Product} from "../../../model/data/shop/product/Product";
import {CollectionType} from "../../../model/data/enums/CollectionType";
import {CollectionListResponse} from "../../../model/response/CollectionListResponse";
import {EditCollectionRequest} from "../../../model/request/collection/EditCollectionRequest";
import {Collection} from "../../../model/data/collection/Collection";
import {CreateCollectionRequest} from "../../../model/request/collection/CreateCollectionRequest";
import {Role} from "../../../model/data/enums/Role";

@Injectable()
export class CollectionService  {

	private collections: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

	constructor(private api: ApiCommunicationService,
				private userSessionService: UserSessionService) {
		if (userSessionService.userDataValue) {
			this.fetchCollections().then();
		}
	}

	private fetchCollections(): Promise<boolean> {
		return new Promise((resolve => {
			this.api.collection().getCollections(1, this.userSessionService.userDataValue._id,
				this.userSessionService.userDataValue.role === Role.MERCHANT ? CollectionType.SHOP : CollectionType.WISHLIST)
				.subscribe((response: CollectionListResponse) => {
					this.collections.next(response.docs);
					resolve();
				});
		}));
	}

	public getCollections(): Observable<Array<any>> {
		return this.collections.asObservable();
	}

	public addToCollection(wishListId: string, product: Product): Observable<Collection> {
		const request = new EditCollectionRequest();
		request.products = [product._id];
		return this.api.collection().addToCollection(wishListId, request);
	}

	public addToNewCollection(collectionName: string, product: Product): Promise<Collection> {
		return new Promise(((resolve, reject) => {

			const user = this.userSessionService.userDataValue;
			const request = new CreateCollectionRequest();
			request.name = collectionName;
			request.type = user.role === Role.MERCHANT ? CollectionType.SHOP : CollectionType.WISHLIST;
			request.products = [product._id];

			this.api.collection().createCollection(request).subscribe((response: Collection) => {
				this.fetchCollections().then(() => {
					resolve(response);
				});
			}, () => {
					reject();
			});
		}));
	}

}
