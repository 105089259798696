import {HttpClient} from "@angular/common/http";
import {OAuthApiClient} from "../utility/OAuthApiClient";

export abstract class AbstractApiConnector {

	// api route
	protected abstract readonly apiRoute: string;

	constructor(protected http: HttpClient,
				protected apiClient: OAuthApiClient,
				protected apiBaseUrl: string) {

		// check if HTTP client is wired in
		if (!http) {
			throw new Error("No HTTP client is provided for api connector");
		}

		// check if API client is wired in
		if (!apiClient) {
			throw new Error("No api client is provided for api connector");
		}

		// check if API base url is provided
		if (!apiBaseUrl) {
			throw new Error("No api base url is provided");
		}
	}

}
