export class CheckoutRequest {
	stripeToken: string;

	billingAddress: {
		address: string,
		city: string,
		country: string,
		province: string,
		zip: string
	};

	shippingAddress: {
		address: string,
		city: string,
		country: string,
		province: string,
		zip: string
	};

	shippingSameAsBilling: boolean;

	acceptedTC: boolean;

	customer: {
		fullName: string,
		email: string,
		phone: string
	};

	// in case of guest user
	cart?: {
		charityAccount?: string,
		items: [{
			variant: string,
			quantity: number
		}]
	};

}
