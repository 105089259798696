import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Product} from "../../../model/data/shop/product/Product";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
	selector: "app-merchant-top-sellers",
	templateUrl: "./merchant-top-sellers.component.html",
	styleUrls: ["./merchant-top-sellers.component.scss"]
})
export class MerchantTopSellersComponent implements OnInit {

	public username: string;
	public _products: BehaviorSubject<Array<Product>> = new BehaviorSubject([]);

	constructor(private route: ActivatedRoute,
				private location: Location,
				private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe( (data) => {

			this.username = data.username;
			this._products.next(data.products.docs);

			this.changeDetector.detectChanges();

		});

	}

	get products(): Observable<Array<Product>> {
		return this._products.asObservable();
	}

}
