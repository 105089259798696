export abstract class AbstractSocialButton {

	protected constructor(buttonClass: string, iconClass: string) {
		this._buttonClass = buttonClass;
		this._iconClass = iconClass;
	}

	protected _buttonClass: string;
	protected _iconClass: string;
	protected _iconState = false;
	protected _displayedNumber = 0;
	protected _displayCounter = true;

	public abstract clickAction(): void;

	get buttonClass(): string {
		return this._buttonClass;
	}

	get iconClass(): string {
		return this._iconClass;
	}

	get iconState(): boolean {
		return this._iconState;
	}

	get displayedNumber(): number {
		return this._displayedNumber;
	}

	get displayCounter(): boolean {
		return this._displayCounter;
	}

	/**
	 * Action executed when user clicks on like button.
	 */
	public clickEvent($event: MouseEvent): void {
		$event.stopPropagation();

		this.clickAction();
	}



	}
