import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "../../../model/data/user/User";
import {Role} from "../../../model/data/enums/Role";

@Component({
	selector: "app-profile-router",
	templateUrl: "./profile-router.component.html",
	styleUrls: ["./profile-router.component.scss"]
})
export class ProfileRouterComponent implements OnInit {

	constructor(private route: ActivatedRoute,
				private router: Router) {
	}

	ngOnInit() {
		this.route.parent.data.subscribe((data) => {
			const user: User = data.user;
			if (user.role === Role.MERCHANT) {
				this.router.navigate(["shop"], {relativeTo: this.route});
			} else {
				this.router.navigate(["conversations"], {relativeTo: this.route});
			}
		});
	}

}
