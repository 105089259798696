import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Cart} from "../data/shop/cart/Cart";
import {AddToCartRequest} from "../request/cart/AddToCartRequest";

export class CartApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/cart";

	/**
	 * GET /api/v1/cart Returns the cart with items in it for the logged in user
	 */
	public getCart(): Observable<Cart> {
		return this.apiClient.get(this.apiRoute);
	}

	/**
	 * POST /api/v1/cart Creates a cart and put items in it.
	 * @param request
	 */
	public addToCart(request: AddToCartRequest): Observable<Cart> {
		return this.apiClient.post(this.apiRoute, request);
	}
	
	/**
	 * /api/v1/cart/{variantId} Delete item from the cart.
	 * @param variantId
	 */
	public deleteFromCart(variantId: string): Observable<Cart> {
		return this.apiClient.delete(this.apiRoute + "/" + variantId);
	}
	
	/**
	 * /api/v1/cart/{variantId} Updates the cart item based on the provided body for the logged in user
	 * @param variantId
	 * @param quantity
	 */
	public updateCartItem(variantId: string, quantity: number): Observable<Cart> {
		return this.apiClient.patch(this.apiRoute + "/" + variantId, {quantity: quantity});
	}

	public addCharity(charityId: string): Observable<Cart> {
		return this.apiClient.authorizedOnly().patch(this.apiRoute, {charityAccount: charityId});
	}

}
