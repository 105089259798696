import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import {FeedEventsService} from "../../../commons/services/feed-events/feed-events.service";
import {Subscription} from "rxjs";
import {Feed} from "../../../model/data/feed/Feed";
import {ContentType} from "../../../model/data/enums/ContentType";
import {HomeFeedDataService} from "../../services/home-feed-data/home-feed-data.service";
import {UpdateService} from "../../../commons/services/update/update.service";
import {Content} from "../../../model/content/Content";
import {NgxMasonryComponent, NgxMasonryOptions} from "ngx-masonry";
import {MasonaryService} from "../../../commons/services/masonary/masonary.service";

@Component({
	selector: "app-main-feed",
	templateUrl: "./main-feed.component.html",
	styleUrls: ["./main-feed.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainFeedComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild("productFeedContainer", {static: true})
	private containerElement: NgxMasonryComponent;

	private _feedElements: Feed = [];

	public readonly selector = "#main-feed-wrapper";

	public readonly POST = ContentType.POST;
	public readonly EVENT = ContentType.EVENT;
	public readonly MICROBLOGPOST = ContentType.MICROBLOGPOST;

	public nextPageAvailable : boolean = true;

	// container size change subscription
	private containerSizeChangeEventSubscription: Subscription;
	private contentChangeEventSubscription: Subscription;
	private productFeedDataSubscription: Subscription;

	constructor(private productFeedEventsService: FeedEventsService,
				private feedService: HomeFeedDataService,
				private changeDetector: ChangeDetectorRef,
				private updateService: UpdateService,
				private masonaryService: MasonaryService) {
	}

	public onScrollDown() {
		this.feedService.getFeedNextPage();
	}

	public ngOnInit() {
		// fetch feed data and adjust layout
		this.productFeedDataSubscription = this.feedService.feedData$.subscribe((response) => {
			this._feedElements = response;
			this.nextPageAvailable = this.feedService.feedListResponse.hasNextPage
										|| !this.feedService.feedListResponse.hasPrevPage;

			this.changeDetector.detectChanges();
			// this.adjustLayout();
			setTimeout(() => {
				if (response && response.length) {
					this.masonaryService.adjustLayout();
					if (!this.containerHasScrollBar()) {
						this.feedService.getFeedNextPage();
					}
				}
			}, 500);
		});

		this.contentChangeEventSubscription = this.updateService.contentChangeEvent.subscribe((response: Content) => {
			if (response === null) {
				this.feedService.getFeedFirstPage();
			} else {
				const index = this._feedElements.indexOf(this._feedElements.find(f => f._id === response._id));
				if (index >= 0) {
					this._feedElements[index] = response;
					this.changeDetector.detectChanges();

					setTimeout(() => {
						if (response) {
							this.masonaryService.adjustLayout();
						}
					}, 500);
				}
			}
		});
	}

	public ngAfterViewInit(): void {
		// subscribe on size change events
		this.containerSizeChangeEventSubscription = this.productFeedEventsService.containerSizeChangeEvent.subscribe(
			() => {
				setTimeout(() => {
					this.changeDetector.detectChanges();
					this.masonaryService.adjustLayout();
					// debugger;
				}, 2000);
			}
		);

		// set actual container
		this.masonaryService.containerElement = this.containerElement;
		this.masonaryService.adjustLayout();
		this.changeDetector.detectChanges();
	}

	public ngOnDestroy(): void {
		// unsubscribe from feed events
		this.containerSizeChangeEventSubscription.unsubscribe();
		this.contentChangeEventSubscription.unsubscribe();
		this.productFeedDataSubscription.unsubscribe();

		this.masonaryService.containerElement = null;
	}

	public onImageLoaded() {
		this.masonaryService.onImageLoaded();
	}

	private containerHasScrollBar(): boolean {
		const container = document.querySelector(this.selector);
		return container.scrollHeight > (container.clientHeight + container.scrollTop);
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.changeDetector.detectChanges();
	}

	get feedElements(): Feed {
		return this._feedElements;
	}

	public getColumnsClass(): string {
		return this.masonaryService.columnsClass;
	}

	public getOptions(): NgxMasonryOptions | {transitionDuration: number} {
		return this.masonaryService.masonryOptions;
	}

}
