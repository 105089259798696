import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserPageComponent} from "./pages/user-page/user-page.component";
import {UserModule} from "../user/user.module";
import {ModelModule} from "../model/model.module";
import {SocialModule} from "../social/social.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {UserProfileCardComponent} from "./components/user-profile-card/user-profile-card.component";
import {UserCollectionsComponent} from "./components/user-collections/user-collections.component";
import {ShopModule} from "../shop/shop.module";
import {RouterModule, Routes} from "@angular/router";
import {UserResolverService} from "./resolvers/user-resolver.service";
import {UserFollowersComponent} from "./components/user-followers/user-followers.component";
import {CollectionResolverService} from "./resolvers/collection-resolver.service";
import {FollowersResolverService} from "./resolvers/followers-resolver.service";
import {UsernameResolverService} from "./resolvers/username-resolver.service";
import {CollectionsResolverService} from "./resolvers/collections-resolver.service";
import {PermissionResolverService} from "./resolvers/permission-resolver.service";
import {UserCollectionComponent} from "./components/user-collection/user-collection.component";
import {UserFollowingComponent} from "./components/user-following/user-following.component";
import {FollowingResolverService} from "./resolvers/following-resolver.service";
import {LikedContentResolverService} from "./resolvers/liked-content-resolver.service";
import {EventsResolverService} from "./resolvers/events-resolver.service";
import {MerchantOffersComponent} from "./components/merchant-offers/merchant-offers.component";
import {OffersResolverService} from "./resolvers/offers-resolver.service";
import {MerchantBrandsComponent} from "./components/merchant-brands/merchant-brands.component";
import {MerchantShopComponent} from "./components/merchant-shop/merchant-shop.component";
import {ShopResolverService} from "./resolvers/shop-resolver.service";
import {MerchantTopSellersComponent} from "./components/merchant-top-sellers/merchant-top-sellers.component";
import {ProfileRouterComponent} from "./components/profile-router/profile-router.component";
import {ProfileChangeEventService} from "./services/profile-change-event.service";
import {FollowersResolverChildService} from "./resolvers/followers-resolver-child.service";
import {UserStatsResolverService} from "./resolvers/user-stats-resolver.service";
import {TopSellersResolverService} from "./resolvers/top-sellers-resolver.service";
import {ConversationsResolverService} from "./resolvers/conversations-resolver.service";
import {CanNavigate} from "../commons/guards/can-navigate";
import {BlogsResolverService} from "./resolvers/blogs-resolver.service";
import {NgxMasonryModule} from "ngx-masonry";
import {UserFeedComponent} from "./components/user-feed/user-feed.component";
import {UserFeedService} from "./services/user-feed.service";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {MyProfileModule} from "../my-profile/my-profile.module";

const routes: Routes = [
	// Profile page
	{
		path: "user/:username/:userId",
		component: UserPageComponent,
		canDeactivate: [CanNavigate],
		resolve: {
			user: UserResolverService,
			stats: UserStatsResolverService,
			followers: FollowersResolverService,
			writePermission: PermissionResolverService
		},
		children: [
			{
				path: "",
				component: ProfileRouterComponent
			},
			{
				path: "conversations",
				component: UserFeedComponent,
				resolve: {
					username: UsernameResolverService,
					content: ConversationsResolverService,
				}
			},
			{
				path: "blogs",
				component: UserFeedComponent,
				resolve: {
					username: UsernameResolverService,
					content: BlogsResolverService,
				}
			},
			{
				path: "events",
				component: UserFeedComponent,
				resolve: {
					username: UsernameResolverService,
					content: EventsResolverService,
				}
			},
			{
				path: "likes",
				component: UserFeedComponent,
				resolve: {
					username: UsernameResolverService,
					content: LikedContentResolverService
				}
			},
			{
				path: "collections",
				component: UserCollectionsComponent,
				resolve: {
					username: UsernameResolverService,
					collections: CollectionsResolverService
				}
			},
			{
				path: "collections/:collectionId",
				component: UserCollectionComponent,
				resolve: {
					username: UsernameResolverService,
					collection: CollectionResolverService
				}
			},
			{
				path: "followers",
				component: UserFollowersComponent,
				resolve: {
					username: UsernameResolverService,
					followers: FollowersResolverChildService
				},
				runGuardsAndResolvers: "always",
			},
			{
				path: "following",
				component: UserFollowingComponent,
				resolve: {
					username: UsernameResolverService,
					followings: FollowingResolverService
				},
				runGuardsAndResolvers: "always",
			},
			// Merchant specific pages
			{
				path: "shop",
				component: MerchantShopComponent,
				resolve: {
					username: UsernameResolverService,
					products: ShopResolverService
				}
			},
			{
				path: "top-sellers",
				component: MerchantTopSellersComponent,
				resolve: {
					username: UsernameResolverService,
					products: TopSellersResolverService,
				}
			},
			{
				path: "offers",
				component: MerchantOffersComponent,
				resolve: {
					username: UsernameResolverService,
					offers: OffersResolverService
				}
			}
		]
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule.forChild(),
		NgbModule,
		RouterModule.forChild(routes),
		UserModule,
		ModelModule,
		SocialModule,
		ShopModule,
		NgxMasonryModule,
		InfiniteScrollModule,
		MyProfileModule
	],
	declarations: [
		UserPageComponent,
		UserProfileCardComponent,
		UserCollectionsComponent,
		UserCollectionComponent,
		UserFollowersComponent,
		UserFollowingComponent,
		MerchantOffersComponent,
		MerchantBrandsComponent,
		MerchantShopComponent,
		MerchantTopSellersComponent,
		ProfileRouterComponent,
		UserFeedComponent
	],
	providers: [
		UserResolverService,
		UsernameResolverService,
		UserStatsResolverService,
		FollowersResolverService,
		FollowersResolverChildService,
		ConversationsResolverService,
		BlogsResolverService,
		EventsResolverService,
		FollowingResolverService,
		CollectionResolverService,
		CollectionsResolverService,
		LikedContentResolverService,
		OffersResolverService,
		TopSellersResolverService,
		ShopResolverService,
		PermissionResolverService,
		ProfileChangeEventService,
		UserFeedService
	],
})
export class ProfileModule {
}
