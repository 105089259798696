import {Component, Inject, OnInit} from "@angular/core";
import {CartService} from "../../../model/services/cart/cart.service";
import {Cart} from "../../../model/data/shop/cart/Cart";
import {CharityModalService} from "../../services/charity-modal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {animate, style, transition, trigger} from "@angular/animations";
import {preventInitialChildAnimations} from "../../../commons/animations/preventInitialChildAnimations";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-checkout-summary-panel",
	templateUrl: "./checkout-summary-panel.component.html",
	styleUrls: ["./checkout-summary-panel.component.scss"],
	animations: [
		trigger("openClose", [
			transition("void => *", [
				style({height: 0, paddingTop: 0, paddingBottom: 0, opacity: 0}),
				animate(300, style({height: "*", paddingTop: "1.5rem", paddingBottom: "1.5rem", opacity: 1}))
			]),
			transition("* => void", [
				style({height: "*", paddingTop: "1.5rem", paddingBottom: "1.5rem", opacity: 1}),
				animate(300, style({height: 0, paddingTop: 0, paddingBottom: 0, opacity: 0}))
			])
		]),
		preventInitialChildAnimations
	]
})
export class CheckoutSummaryPanelComponent implements OnInit {

	public cart: Cart;
	public pendingOrder = false;
	public isPaymentPageActive = false;

	private activeSubscription: Subscription;

	constructor(@Inject(ActivatedRoute) private route: ActivatedRoute,
				@Inject(Router) private router: Router,
				@Inject(CartService) private cartService: CartService,
				@Inject(SweetAlertService) private alertService: SweetAlertService,
				@Inject(TranslateService) private translate: TranslateService,
				@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(CharityModalService) private charityModalService: CharityModalService) {
	}

	ngOnInit() {

		this.route.url.subscribe((url) => {
			this.isPaymentPageActive = this.router.url.indexOf("/payment") > -1;
		});

		this.route.data.subscribe((data) => {
			// close active subscription if available
			if (this.activeSubscription && !this.activeSubscription.closed) {
				this.activeSubscription.unsubscribe();
			}

			// check if pending order exists
			this.pendingOrder = (data && data.activeOrder && data.activeOrder.cart);
			if (this.pendingOrder) {
				this.cart = data.activeOrder.cart;
			} else {
				this.activeSubscription = this.cartService.cart.subscribe((cart) => {
					this.cart = cart;
				});
			}
		});

	}

	public chooseYourCharityButtonAction(): void {
		this.charityModalService.openModal(this.cart)
			.subscribe(() => {});
	}

	/**
	 * Cancel pending order
	 */
	public cancelPendingOrder() {
		// TODO RR
		// confirm with an alert box
		// this.alertService.confirm({title: this.translate.instant("checkout.orders.pending-order-cancel-confirm")})
		// 	.then(() => {
		// 		// send api request to cancel the order
		// 		this.api.report()
		// 			.cancelPendingOrder()
		// 			.subscribe((res) => {
		// 				this.router.navigate(["/orders", res.data._id]);
		// 			});
		// 	});
	}

}
