import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {NgxMasonryComponent, NgxMasonryOptions} from "ngx-masonry";
import {Content} from "../../../model/content/Content";
import {Product} from "../../../model/data/shop/product/Product";
import {ContentType} from "../../../model/data/enums/ContentType";
import {FeedEventsService} from "../../../commons/services/feed-events/feed-events.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MasonaryService} from "../../../commons/services/masonary/masonary.service";
import {UserFeedService} from "../../services/user-feed.service";
import {Subscription} from "rxjs";

@Component({
	selector: "app-user-feed",
	templateUrl: "./user-feed.component.html",
	styleUrls: ["./user-feed.component.scss"]
})
export class UserFeedComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild("feedContainer", { static: true })
	private containerElement: NgxMasonryComponent;

	// container size change subscription
	private containerSizeChangeEventSubscription: any;
	private userFeedSubscription: Subscription;

	public username: string;
	public userId: string;
	public title: string;
	public content: Array<Content | Product> = [];

	public readonly selector = "#conversations-feed";

	readonly POST = ContentType.POST;
	readonly EVENT = ContentType.EVENT;
	readonly MICROBLOGPOST = ContentType.MICROBLOGPOST;

	constructor(private productFeedEventsService: FeedEventsService,
				private route: ActivatedRoute,
				private router: Router,
				private userFeedService: UserFeedService,
				private changeDetector: ChangeDetectorRef,
				private masonaryService: MasonaryService) {
	}

	public ngOnInit() {
		this.userId = this.route.parent.snapshot.params["userId"];
		const urlSegments = window.location.href.split("/");
		this.title =  urlSegments[urlSegments.length - 1].split("?")[0];

		this.route.data.subscribe( (data) => {
			this.username = data.username;

			if (data.content && !data.content.docs) {
				this.userFeedService.setUserFeed(this.userFeedService.parseLikedContent(data.content), this.title);
			} else {
				this.userFeedService.setUserFeed(data.content, this.title);
			}

			this.changeDetector.detectChanges();
		});

		this.userFeedSubscription = this.userFeedService.userFeed$.subscribe(feed => {
			this.content = feed.docs;
			this.changeDetector.detectChanges();

			// if there is no scrollbar, fetch next page
			setTimeout(() => {
				if (feed && feed.docs && feed.docs.length) {
					this.masonaryService.adjustLayout();
					if (!this.containerHasScrollBar()) {
						this.userFeedService.getNextPage();
					}
				}
			}, 1000);
		});
	}

	public ngAfterViewInit(): void {
		// subscribe on size change events
		this.containerSizeChangeEventSubscription = this.productFeedEventsService.containerSizeChangeEvent.subscribe(
			() => this.masonaryService.adjustLayout()
		);

		// set actual container
		this.masonaryService.containerElement = this.containerElement;
	}

	public ngOnDestroy(): void {
		// unsubscribe from feed events
		if (this.containerSizeChangeEventSubscription) {
			this.containerSizeChangeEventSubscription.unsubscribe();
		}

		this.masonaryService.containerElement = null;
		this.userFeedSubscription.unsubscribe();
	}

	@HostListener("window:resize", ["$event"])
	public onResize() {
		this.changeDetector.detectChanges();
	}

	public onScrollDown() {
		this.userFeedService.getNextPage();
	}

	public getColumnsClass(): string {
		return this.masonaryService.columnsClass;
	}

	public getOptions(): NgxMasonryOptions | { transitionDuration: number }  {
		return this.masonaryService.masonryOptions;
	}

	public onImageLoaded() {
		this.masonaryService.onImageLoaded();
	}

	private containerHasScrollBar(): boolean {
		const container = document.querySelector(this.selector);
		return container.scrollHeight > (container.clientHeight + container.scrollTop);
	}

}
