import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Product} from "../../model/data/shop/product/Product";
import {ProductPageModalService} from "./product-page-modal.service";

@Injectable()
export class ProductResolverService implements Resolve<Product> {

	constructor(private productPageModalService: ProductPageModalService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Product> | Promise<Product> | Product {
		this.productPageModalService.open(route.params["id"]);
		return null;
	}

}
