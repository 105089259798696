import {ObjectWithIdentity} from "./ObjectWithIdentity";

export abstract class DatabaseEntity implements ObjectWithIdentity {

	// object identifier
	_id: string;
	// entity version
	__v?: number;
	// creation and update date
	createdAt?: string;
	updatedAt?: string;

}
