import {Injectable} from "@angular/core";

@Injectable()
export class LikeService {

	public likes = new Array<string>();

	constructor() {
	}
	
	public add(id: string) {
		this.likes.push(id);
	}

	public exist(id: string): boolean {
		return this.likes.some(likeId => likeId === id);
	}

	public remove(id: string) {
		delete this.likes[this.likes.indexOf(id)];
	}

}
