import {Routes} from "@angular/router";
import {ProfilePageComponent} from "./pages/profile-page/profile-page.component";
import {ProfileDashboardPageComponent} from "./pages/profile-dashboard-page/profile-dashboard-page.component";
import {AuthGuardService} from "../user/guard/auth-guard.service";

export const myProfileRoutes: Routes = [
	{
		path: "",
		component: ProfilePageComponent,
		canActivate: [AuthGuardService],
		// canActivateChild: [AuthGuardService],
		children:  [
			{
				path: "",
				component: ProfileDashboardPageComponent,
			},
			// Previous Orders page
			{
				path: "orders",
				loadChildren: () => import("./orders/orders.module").then(m => m.OrdersModule)
			},
			// Previous Orders page
			{
				path: "blogs",
				loadChildren: () => import("./blogs/blogs.module").then(m => m.BlogsModule)
			}
		]

	},
];
