import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {ReviewListResponse} from "../response/ReviewListResponse";
import {Review} from "../review/Review";

export class ReviewApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/review";

	public checkEligibility(productId: string): Observable<any> {
		return this.apiClient.get(`${this.apiRoute}/eligibility/${productId}`);
	}

	public getReviews(pageNumber: number, productId: string): Observable<ReviewListResponse> {
		return this.apiClient.get(`${this.apiRoute}/${pageNumber}`, {productId: productId});
	}

	public review(productId: string, review: Review): Observable<any> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}/${productId}`, {message: review.message, rating: review.rating});
	}

	public deleteReview(reviewId: string): Observable<any> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${reviewId}`);
	}

	public editReview(reviewId: string, review: Review): Observable<any> {
		return this.apiClient.authorizedOnly().patch(`${this.apiRoute}/${reviewId}`, {message: review.message, rating: review.rating});
	}

}
