import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {UserSessionService} from "../../user/services/user-session/user-session.service";


@Injectable({
	providedIn: "root"
})
export class CanNavigate implements CanDeactivate<any> {

	constructor(@Inject(UserSessionService) private service: UserSessionService) {
	}

	canDeactivate(component: void, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot):
		Observable<boolean> | Promise<boolean> | boolean {
		return this.service.navigation;
	}


}
