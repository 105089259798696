import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from "@angular/core";

@Component({
	selector: "app-floating-text-search",
	templateUrl: "./floating-text-search.component.html",
	styleUrls: ["./floating-text-search.component.scss"]
})
export class FloatingTextSearchComponent implements OnInit {

	private readonly preventClickOut: Array<string> = [
		"floating-text-search-input",
		"floating-quick-search",
		"floating-quick-search-input"
	];

	@Input("initialText")
	private _text: string;

	@Output("onSearchAction")
	public onSearchActionEvent = new EventEmitter<string>();


	@ViewChild("floatingSearchInput", { static: true })
	public searchInput: ElementRef;

	constructor() {
	}

	ngOnInit() {
	}

	public onSearchAction() {
		this.onSearchActionEvent.emit(this.text);
	}

	@HostListener("document:click", ["$event"])
	public clickOut(event) {
		if (!this.preventClickOut.some(p => p === event.target.id)) {
			this.onSearchAction();
		}
	}

	get text(): string {
		return this._text;
	}

	set text(value: string) {
		this._text = value;
	}

}
