import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";

@Component({
	selector: "app-profile-social-panels",
	templateUrl: "./profile-social-panels.component.html",
	styleUrls: ["./profile-social-panels.component.scss"]
})
export class ProfileSocialPanelsComponent implements OnInit {

	public followers: number;
	public followings: number;
	public likes: number;
	public comments: number;

	constructor(private api: ApiCommunicationService,
	            public u: UserSessionService,
	            private cd: ChangeDetectorRef) {

	}

	ngOnInit() {
		this.api.profile().getUserStatistics(this.u.userDataValue._id)
			.subscribe((d) => {
				console.log(d);
				this.followers = d.followers;
				this.followings = d.followings;
				this.likes = d.likes;
				this.comments = d.comments;
				this.cd.detectChanges();
			});
	}

}
