import {AbstractApiConnector} from "./AbstractApiConnector";
import {ComissionTriggerReqeust} from "../request/share/ComissionTriggerReqeust";
import {Observable} from "rxjs";

export class CommissionApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/commission";

	public trigger(request: ComissionTriggerReqeust): Observable<any> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}/trigger`, request);
	}

}
