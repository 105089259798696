import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CheckoutPageComponent} from "./pages/checkout-page/checkout-page.component";
import {CheckoutSummaryPanelComponent} from "./components/checkout-summary-panel/checkout-summary-panel.component";
import {RouterModule, Routes} from "@angular/router";
import {CheckoutCartItemComponent} from "./components/checkout-cart-item/checkout-cart-item.component";
import {CheckoutAddressFormComponent} from "./components/checkout-address-form/checkout-address-form.component";
import {CheckoutShippingInfoPageComponent} from "./pages/checkout-shipping-info-page/checkout-shipping-info-page.component";
import {FormsModule} from "@angular/forms";
import {CheckoutPaymentPageComponent} from "./pages/checkout-payment-page/checkout-payment-page.component";
import {CreditCardDirectivesModule} from "angular-cc-library";
import {TextMaskModule} from "angular2-text-mask";
import {CharityModalComponent} from "./modals/charity-modal/charity-modal.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TranslateModule} from "@ngx-translate/core";
import {MomentModule} from "ngx-moment";
import {CharityModule} from "../charity/charity.module";
import {UserModule} from "../user/user.module";
import {CheckoutPreProcessingRequest} from "../model/request/checkout/CheckoutPreProcessingRequest";
import {ShippingInfoService} from "./services/shipping-info.service";

const routes: Routes = [
	// Checkout page
	{
		path: "checkout",
		component: CheckoutPageComponent,
		children: [
			{
				path: "",
				component: CheckoutShippingInfoPageComponent,
			},
			{
				path: "payment",
				component: CheckoutPaymentPageComponent,
				data: CheckoutPreProcessingRequest
			},
		],
		runGuardsAndResolvers: "always"
	},

];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		FormsModule,
		UserModule,
		BrowserAnimationsModule,
		TranslateModule,
		CreditCardDirectivesModule,
		TextMaskModule,
		CharityModule,
		MomentModule
	],
	declarations: [
		CheckoutPageComponent,
		CheckoutSummaryPanelComponent,
		CheckoutCartItemComponent,
		CheckoutAddressFormComponent,
		CheckoutShippingInfoPageComponent,
		CheckoutPaymentPageComponent,
		CharityModalComponent,
	],
	entryComponents: [
		CharityModalComponent
	],
	providers: [
		ShippingInfoService,
	]
})
export class CheckoutModule {
}
