import {Injectable} from "@angular/core";
import {Title} from "@angular/platform-browser";

@Injectable()
export class PageTitleService {

	private baseTitle = "❤️Givr";
	private separator = " | ";

	constructor(private titleService: Title) {
	}

	public setPageTitle(subTitle: string): void {

		// just the base title
		let pageTitle = this.baseTitle;

		// if sub-title is set
		if (subTitle != null && subTitle !== "") {
			pageTitle = subTitle + this.separator + pageTitle;
		}

		// set the page title
		this.titleService.setTitle(pageTitle);
	}

	public resetPageTitle() {
		this.setPageTitle(null);
	}


}
