import {Component, HostListener, Inject, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {ProductModalState, ProductPageModalService} from "../../services/product-page-modal.service";
import {Product} from "../../../model/data/shop/product/Product";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {ReviewListResponse} from "../../../model/response/ReviewListResponse";
import {ProductRatingsService} from "../../services/product-ratings.service";
import {ActivatedRoute} from "@angular/router";
import {Variant} from "../../../model/data/shop/product/Variant";
import {CartService} from "../../../model/services/cart/cart.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {ProductListResponse} from "../../../model/response/ProductListResponse";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {ReportService} from "../../../social/services/report/report.service";

@Component({
	selector: "app-product-page-modal",
	templateUrl: "./product-page-modal.component.html",
	styleUrls: ["./product-page-modal.component.scss"]
})
export class ProductPageModalComponent implements OnInit, OnDestroy {

	// subscriptions
	public display$: Observable<ProductModalState>;
	// miscellaneous subscriptions
	private ratingSubscription$ = new Subscription();
	private querySubscription$ = new Subscription();
	private userSubscription$ = new Subscription();
	// data related subscriptions
	private productSubscription$ = new Subscription();

	public product: Product;
	public moreLikeThis: Array<Product>;

	// display options for components
	// public _recommendedItemsViewType = TextPositionAttribute.Besides;
	public _userAvatarDisplayType = UserAvatarDisplayType.IMAGE_ONLY;

	// variant related properties
	public selectedVariant = 0;
	public isVariantAvailable = true;
	public available = 0;
	public quantity;

	// share, review and collection related properties
	public hasLiveShare: any = {};
	public reviews: ReviewListResponse;
	public showAddToCollectionLabel = false;

	// close modal on browser back event
	@HostListener("window:popstate", ["$event"])
	private onPopState() {
		if (this.product) {
			this.close();
		}
	}

	constructor(private route: ActivatedRoute,
				private cartService: CartService,
				private api: ApiCommunicationService,
				private pageTitleService: PageTitleService,
				private modalService: ProductPageModalService,
				private userSessionService: UserSessionService,
				private productRatingsService: ProductRatingsService,
				private reportService: ReportService) {
	}

	ngOnInit() {
		// state of modal
		this.display$ = this.modalService.watch();

		this.productSubscription$ = this.modalService.productData$.subscribe(product => {
			this.product = product;

			if (product) {
				this.hasLiveShare.product = this.product._id;

				this.pageTitleService.setPageTitle(this.product.title);

				// get further products
				this.fetchMoreLikeThis();

				// fire select variant
				this.selectVariantAction();

				// get reviews
				this.fetchReviews();

				// enable miscellaneous observables
				this.initializeSubscriptions();
			}
		});

		this.userSubscription$ = this.userSessionService.userData.subscribe((user) => {
			this.showAddToCollectionLabel = user && this.product && user.userName === this.product.user.userName;
		});

	}

	ngOnDestroy(): void {
		this.productSubscription$.unsubscribe();
		this.userSubscription$.unsubscribe();
		this.destroySubscriptions();
	}

	close() {
		this.modalService.close();
		this.destroySubscriptions();
	}

	backButtonAction() {
		this.modalService.close();
	}

	buyAction() {
		this.cartService.addToCart(
			// selected product variant id
			this.product.variants[this.selectedVariant]._id,
			// 1 is the default value for quantity
			this.quantity ? this.quantity : 1,
			// share id
			this.hasLiveShare
		);
	}

	selectVariant(index: number) {
		this.selectedVariant = index;
		this.selectVariantAction();
	}

	selectVariantAction() {
		const selectedVariantId = this.product.variants[this.selectedVariant]._id;
		this.quantity = 1;
		this.isVariantAvailable = false;

		this.api.inventory().getVariantAvailability(selectedVariantId, 1).subscribe((response: any) => {
			if (response.available) {
				this.isVariantAvailable = true;
				this.available = response.quantity;
			}
		});
	}

	public getSpecs(variant: Partial<Variant>): any {
		if (variant && variant.specs && typeof variant.specs === "string") {
			variant.specs = JSON.parse(variant.specs);
		}

		return variant.specs;
	}

	public showRatingAction() {
		this.productRatingsService.openProductRatingModal(this.reviews);
	}

	private fetchReviews() {
		// get reviews
		this.api.review().getReviews(1, this.product._id).subscribe((response: ReviewListResponse) => {
			this.reviews = response;
		});
	}

	private fetchMoreLikeThis() {
		this.api.product().getMoreLikeThis(1, this.product._id).subscribe((response: ProductListResponse) => {
			this.moreLikeThis = response.docs;
		});
	}

	private initializeSubscriptions() {
		this.ratingSubscription$ = this.productRatingsService.onReview.subscribe(() => {
			this.fetchReviews();
		});

		// catch if there is a share or not
		this.querySubscription$ = this.route.queryParams.subscribe((params => {
			const share = params["share"];
			if (share) {
				this.hasLiveShare.id = share;
				console.log("shared", this.hasLiveShare);
			}
		}));
	}

	private destroySubscriptions() {
		this.ratingSubscription$.unsubscribe();
		this.querySubscription$.unsubscribe();
	}

	public getBrandSearchQueryParams() : string {
		return JSON.stringify({'types': ['PRODUCT'], 'brand': this.product.brandName});
	}

	public getCategorySearchQueryParams() : string {
		return JSON.stringify({'types': ['PRODUCT'], 'category': this.product.category._id});
	}

	public reportProductAction(): void {
		this.reportService.openReportModal(this.product);
	}

}
