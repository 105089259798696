import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";

export class InventoryApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/inventory";

	public getVariantAvailability(variantId: string, quantity): Observable<any> {
		return this.apiClient.get(this.apiRoute, {variantId: variantId, quantity: quantity});
	}

}
