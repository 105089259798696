import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

import {SocialModule} from "../social/social.module";

import {TranslateModule} from "@ngx-translate/core";
import {ProductFeedComponent} from "./components/product-feed/product-feed.component";
import {ProductFeedItemComponent} from "./components/product-feed-item/product-feed-item.component";
import {ProductDisplayIconComponent} from "./components/product-display-icon/product-display-icon.component";
import {ProductRatingComponent} from "./components/product-rating/product-rating.component";
import {ProductDisplaySmallComponent} from "./components/product-display-small/product-display-small.component";
import {ProductImageGalleryComponent} from "./components/product-image-gallery/product-image-gallery.component";
import {UserModule} from "../user/user.module";
import {CategorySelectorComponent} from "./components/category-selector/category-selector.component";
import {ProductCommentsComponent} from "./components/product-comments/product-comments.component";
import {ProductResolverService} from "./services/product-resolver.service";
import {FormsModule} from "@angular/forms";
import {KeysPipe} from "./pipes/KeysPipe";
import {SubmitProductRatingModalComponent} from "./modals/submit-product-rating-modal/submit-product-rating-modal.component";
import {ProductRatingsModalComponent} from "./modals/product-ratings-modal/product-ratings-modal.component";
import {ProductRatingsService} from "./services/product-ratings.service";
import {ProductFeedItemDirective} from "./directives/product-feed-item.directive";
import {TypeSelectorComponent} from "./components/type-selector/type-selector.component";
import {ProductPageModalComponent} from "./modals/product-page-modal/product-page-modal.component";
import {ProductPageModalService} from "./services/product-page-modal.service";

const routes: Routes = [
	// Product page
	{
		path: "product/:id",
		component: ProductPageModalComponent,
		resolve: [ProductResolverService]
	}
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		NgbModule,
		FormsModule,
		SocialModule,
		UserModule,
		RouterModule.forChild(routes)
	],
	declarations: [
		ProductFeedComponent,
		ProductFeedItemComponent,
		ProductDisplayIconComponent,
		ProductRatingComponent,
		ProductDisplaySmallComponent,
		ProductImageGalleryComponent,
		CategorySelectorComponent,
		ProductCommentsComponent,
		KeysPipe,
		SubmitProductRatingModalComponent,
		ProductRatingsModalComponent,
		ProductFeedItemDirective,
		TypeSelectorComponent,
		ProductPageModalComponent
	],
	exports: [
		ProductFeedComponent,
		ProductFeedItemComponent,
		ProductDisplayIconComponent,
		ProductDisplaySmallComponent,
		ProductRatingComponent,
		CategorySelectorComponent,
		TypeSelectorComponent,
		ProductPageModalComponent
	],
	providers: [
		ProductResolverService,
		ProductRatingsService,
		ProductPageModalService
	],
	entryComponents: [
		ProductRatingsModalComponent,
		SubmitProductRatingModalComponent
	]
})
export class ShopModule {
}
