import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EventRequest} from "../../../model/request/event/EventRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {Router} from "@angular/router";
import {Content} from "../../../model/content/Content";
import {NGX_EDITOR_CONFIG} from "../../config/ngx-editor-config";

export enum EventCreationType {
	INTERNAL = "Other",
	EXTERNAL = "Eventbrite"
}

@Component({
	selector: "app-create-event-modal",
	templateUrl: "./create-event-modal.component.html",
	styleUrls: ["./create-event-modal.component.scss"]
})
export class CreateEventModalComponent implements OnInit {

	public isLoading = false;
	public modalRef: NgbModalRef;
	public request: EventRequest = new EventRequest();

	@ViewChild("fileInput", { static: true })
	public fileInput: ElementRef;

	// input field references
	@ViewChild("internalForm", { static: false })
	private _internalForm: ElementRef;
	// input field references
	@ViewChild("externalForm", { static: false })
	private _externalForm: ElementRef;

	public eventCreationTypes: Array<EventCreationType> = [EventCreationType.INTERNAL, EventCreationType.EXTERNAL];
	public type: EventCreationType;

	public ngxEditorConfig = NGX_EDITOR_CONFIG;

	public readonly INTERNAL = EventCreationType.INTERNAL;
	public readonly EXTERNAL = EventCreationType.EXTERNAL;

	private now = new Date();

	// errors
	public descriptionError = false;
	public startDateError = false;
	public endDateError = false;

	public options: any = {
		bigBanner: true,
		timePicker: true,
		format: "dd-MM-yyyy hh:mm a",
		defaultOpen: false
	};

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService,
				private router: Router) {
	}

	ngOnInit() {
	}

	clear() {
		this.request = new EventRequest();
	}

	createEventAction() {
		// check form validity
		const isFormValid = this.validate();

		// form is invalid
		if (!isFormValid) {
			return;
		}

		this.isLoading = true;
		this.api.event().createEvent(this.request, (<HTMLInputElement>this.fileInput.nativeElement).files).then((data: Content) => {
			this.isLoading = false;
			this.alertService.success({title: "Event created successfully."}).then(() => {
				this.modalRef.close();
				this.router.navigate(["/event", data._id]);
			});
		}).catch((err) => {
			err = JSON.parse(err);
			if (err.status === 413) {
				this.alertService.error({title: `Could not create your event. <br> The size of the selected image(s) is too large. (10Mb)`});
			} else {
				if (err.message === "IMAGE RESIZE ERROR") {
					err.message = "Image(s) can not be resized";
				}
				this.alertService.error({title: `Could not create your event. <br> ${err.message}.`});
			}
			this.isLoading = false;
			(<HTMLInputElement>this.fileInput.nativeElement).value = "";
		});

	}

	private validate(): boolean {
		let validity;
		if (this.type === EventCreationType.INTERNAL) {
			validity = (<HTMLFormElement> this._internalForm.nativeElement).checkValidity();
			// set bootstrap class indicating state
			(<HTMLFormElement> this._internalForm.nativeElement).classList.add("was-validated");

			this.descriptionError = !this.request.description || this.request.description === "";

			this.startDateError = !this.request.startDate;

			this.endDateError = !this.request.endDate;

			if (this.descriptionError || this.endDateError || this.startDateError) {
				validity = false;
			}

		} else {
			validity = (<HTMLFormElement> this._externalForm.nativeElement).checkValidity();
			// set bootstrap class indicating state
			(<HTMLFormElement> this._externalForm.nativeElement).classList.add("was-validated");
		}

		return validity;
	}

}
