import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CharityPageComponent} from "./pages/charity-page/charity-page.component";
import {RouterModule, Routes} from "@angular/router";
import {CharitiesResolverService} from "./services/charities-resolver.service";
import {TranslateModule} from "@ngx-translate/core";
import {CharityBarComponent} from "./components/charity-bar/charity-bar.component";

const routes: Routes = [
	// Charities page
	{
		path: "",
		component: CharityPageComponent,
		resolve: {
			charities: CharitiesResolverService
		}
	},
];


@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		TranslateModule
	],
	declarations: [
		CharityPageComponent,
		CharityBarComponent
	],
	providers: [
		CharitiesResolverService
	],
	exports: [
		CharityBarComponent
	]
})
export class CharityModule {
}
