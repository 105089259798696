import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreatePostModalComponent} from "../../modals/create-post-modal/create-post-modal.component";
import {CreateBlogpostModalComponent} from "../../modals/create-blogpost-modal/create-blogpost-modal.component";
import {Post} from "../../../model/data/social/Post";
import {PostModalComponent} from "../../modals/post-modal/post-modal.component";
import {EditPostModalComponent} from "../../modals/edit-post-modal/edit-post-modal.component";
import {PermissionLogicService} from "../../../model/services/permission-logic/permission-logic.service";
import {MicroBlogPost} from "../../../model/data/social/MicroBlogPost";
import {EditBlogpostModalComponent} from "../../modals/edit-blogpost-modal/edit-blogpost-modal.component";
import {Content} from "../../../model/content/Content";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";

@Injectable()
export class PostService {

	constructor(private modalService: NgbModal,
				private permissionLogicService: PermissionLogicService,
				private router: Router,
				private activatedRoute: ActivatedRoute,
				private api: ApiCommunicationService) {
		// this.openPostCreationModal();
	}

	/**
	 * Open post creation modal
	 */
	public openPostCreationModal() {

		// open the modal
		const modal = this.modalService.open(CreatePostModalComponent, {backdrop: "static"});
		// pass modal reference
		(<CreatePostModalComponent>modal.componentInstance).modalRef = modal;
		// pass the edit method reference
		(<PostModalComponent>modal.componentInstance).postServiceRef = this;
	}

	/**
	 * Open post display modal
	 * @param {Content} post
	 */
	public openPostModal(post: Content) {
		if (this.modalService.hasOpenModals()) {
			return;
		}

		// this.router.navigate([], {
		// 	relativeTo: this.activatedRoute,
		// 	queryParams: {post: post._id},
		// 	queryParamsHandling: "merge"
		// }).then(() => {
			this.openPostModalAction(post);
		// });
	}

	public openPostModalFromUrl(id: string) {
		if (this.modalService.hasOpenModals()) {
			return;
		}

		this.api.post().getPost(id).subscribe((post: Content) => {
			this.openPostModalAction(post);
		});
	}

	/**
	 * Open post edit modal.
	 * @param {Post} post
	 */
	public openPostEditModal(post: Content) {
		const modal = this.modalService.open(EditPostModalComponent, {backdrop: "static"});
		(<EditPostModalComponent>modal.componentInstance).modalRef = modal;
		(<EditPostModalComponent>modal.componentInstance).setupPost(post);
	}

	/**
	 * Open blog post creation modal
	 */
	public openBlogPostCreationModal() {

		// open the modal
		const modal = this.modalService.open(CreateBlogpostModalComponent, {size: "lg", backdrop: "static"});
		// pass modal reference
		(<CreateBlogpostModalComponent>modal.componentInstance).modalRef = modal;
	}

	/**
	 * Open blog post edit modal.
	 * @param {MicroBlogPost} post
	 */
	public openBlogPostEditModal(post: Content) {
		const modal = this.modalService.open(EditBlogpostModalComponent, {size: "lg", backdrop: "static"});
		(<EditBlogpostModalComponent>modal.componentInstance).modalRef = modal;
		(<EditBlogpostModalComponent>modal.componentInstance).setupPost(post);
	}

	private openPostModalAction(post: Content) {
		// display type depends on uploaded content
		const hasMedia = post.media && post.media.length;
		const windowClass = hasMedia ? "modal-xxl" : "";
		const size = hasMedia ? "lg" : undefined;

		// open the modal
		const modal = this.modalService.open(PostModalComponent, {size: size, windowClass: windowClass, backdrop: true});
		// pass modal reference
		(<PostModalComponent>modal.componentInstance).modalRef = modal;
		// pass the actual post
		(<PostModalComponent>modal.componentInstance).post = post;
		// pass the edit method reference
		(<PostModalComponent>modal.componentInstance).postServiceRef = this;
		// is the post editable
		(<PostModalComponent>modal.componentInstance).isEditMode = this.permissionLogicService.isPostEditable(post);
	}
}
