import {Directive, ElementRef, HostListener, Renderer2} from "@angular/core";

@Directive({
	selector: "[appHoverProductFeedItem]"
})
export class ProductFeedItemDirective {

	constructor(private el: ElementRef, private renderer: Renderer2) {
	}

	@HostListener("mouseover") onMouseOver() {
		const part = this.el.nativeElement.querySelector(".item-description");

		if (part) {
			this.renderer.setStyle(part, "visibility", "visible");
		}
	}

	@HostListener("mouseout") onMouseOut() {
		const part = this.el.nativeElement.querySelector(".item-description");

		if (part) {
			this.renderer.setStyle(part, "visibility", "hidden");
		}
	}

}
