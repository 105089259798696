import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../response/wrappers/ResponseWrapper";
import {Product} from "../data/shop/product/Product";
import {CollectionResponse} from "../response/CollectionResponse";

export class WishListApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/wishlist";

	public createWishList(data: any): Observable<ResponseWrapper<any>> {
		return this.apiClient.post(this.apiRoute, data);
	}

	public getWishLists(): Observable<ResponseWrapper<any>> {
		return this.apiClient.get(this.apiRoute);
	}

	public getWishListById(id: string): Observable<ResponseWrapper<any>> {
		return this.apiClient.get(this.apiRoute + "/" + id);
	}

	public getWishListByUser(username: string): Observable<ResponseWrapper<any>> {
		return this.apiClient.get(this.apiRoute + "/user/" + username);
	}

	public addToWishList(wishlistId: string, productId: string): Observable<ResponseWrapper<any>> {
		return this.apiClient
			.put(this.apiRoute + "/add/" + wishlistId, {product: productId});
	}

	public deleteWishList(wishlistId: string): Observable<ResponseWrapper<any>> {
		return this.apiClient
			.delete(this.apiRoute + "/" + wishlistId);
	}

	public removeFromWishList(wishlistId: string, productId: string): Observable<ResponseWrapper<any>> {
		return this.apiClient
			.put(this.apiRoute + "/remove/" + wishlistId, {product: productId});
	}

}
