import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Charity} from "../data/charity/Charity";

export class CharityApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/charity";

	public getCharities(): Observable<Array<Charity>> {
		return this.apiClient.get(this.apiRoute);
	}

	public getCharitySummarization(): Observable<any> {
		return this.apiClient.get(`${this.apiRoute}/summarize`);
	}

}
