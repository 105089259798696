export class Address {
	address: string;
	city: string;
	country: string;
	province: string;
	zip: string;
	email_address: string;
	fullName: string;
	phone: string;
}
