import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild
} from "@angular/core";
import {HomeFeedDataService} from "../../services/home-feed-data/home-feed-data.service";
import {Product} from "../../../model/data/shop/product/Product";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {forkJoin} from "rxjs";
import {ProductListResponse} from "../../../model/response/ProductListResponse";
import {SearchRequest} from "../../../model/request/search/SearchRequest";
import {IonRangeSliderCallback} from "ng2-ion-range-slider";
import {Category} from "../../../model/data/shop/category/Category";
import {ContentType} from "../../../model/data/enums/ContentType";
import {CategoryService} from "../../../commons/services/category/category.service";

@Component({
	selector: "app-home-filters",
	templateUrl: "./home-filters.component.html",
	styleUrls: ["./home-filters.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeFiltersComponent implements OnInit, OnDestroy, AfterViewInit {

	public topProducts: Array<Product> = [];
	public topTags: Array<string> = [];

	public topPrice: {
		price?: number,
		currency?: string
	} = {};

	public categories: Array<Category>;

	@ViewChild("priceRangeSlider", {static: false})
	public priceRangeSlider = ElementRef;

	private _isProductFilterActive = false;
	private elementsToDisable = [];

	constructor(private feedService: HomeFeedDataService,
				private categoryService: CategoryService,
				private api: ApiCommunicationService,
				private changeDetector: ChangeDetectorRef,
				private renderer: Renderer2) {
	}

	public ngOnInit() {
		forkJoin([
			this.api.search().getTopTags(),
			this.api.search().getTopPrice(),
			this.api.product().getPopularProducts(1),
			// this.api.search().getTopBrands()
		]).subscribe((data: Object[]) => {
			this.topTags = (<Array<string>>data[0]).map((t: any) => t.tag);
			this.topPrice = <any>data[1];
			this.topProducts = (<ProductListResponse>data[2]).docs;
			this.categories = this.categoryService.categories;

			this.changeDetector.detectChanges();
		});
	}

	public ngOnDestroy(): void {
	}

	public ngAfterViewInit(): void {
		// collect all the product filter elements and headers
		// todo fix
		//this.elementsToDisable.push(Array.from(document.getElementsByClassName("filter-box-product-filter")));

		const search = this.getFilter();
		if (search && search.types && search.types.length) {
			this.isProductFilterActive = search.types.some(t => t === ContentType.PRODUCT);
		} else {
			this.isProductFilterActive = true;
		}
	}

	public onSetPriceRange(range: IonRangeSliderCallback) {
		const f = this.getFilter();
		f.bottomPrice = range.from;
		f.topPrice = range.to;

		// set product type if needed
		this.setProductTypeFilter(f);

		this.feedService.setFilter(f);
	}

	public onSetRating(rating: number) {
		const f = this.getFilter();
		f.rating = rating;

		// set product type if needed
		if (rating) {
			this.setProductTypeFilter(f);
		}

		this.feedService.setFilter(f);
	}

	public onSetTag(tag: string) {
		const f = this.getFilter();
		f.tags = [tag];

		this.feedService.setFilter(f);
	}

	public onSetCategories(categories: any) {
		const f = this.getFilter();

		if (categories.hasOwnProperty("category")) {
			f.category = categories.category;
		} else {
			delete f.category;
		}

		if (categories.hasOwnProperty("subCategory")) {
			f.subCategory = categories.subCategory;
		} else {
			delete f.subCategory;
		}

		if (categories.hasOwnProperty("subSubCategory")) {
			f.subSubCategory = categories.subSubCategory;
		} else {
			delete f.subSubCategory;
		}

		// set product type if needed
		this.setProductTypeFilter(f);

		this.feedService.setFilter(f);
	}

	public onSetTypes(types: Array<ContentType>) {
		this.isProductFilterActive = (!types || types.length === 0) || types.some(t => t === ContentType.PRODUCT);

		const f = this.getFilter();

		// reset product related filters when user is deselecting the product type
		// tslint:disable-next-line:max-line-length
		if (!this.isProductFilterActive || (f && f.types && f.types.some(t => t === ContentType.PRODUCT) && types && !types.some(t => t === ContentType.PRODUCT))) {
			// reset categories
			delete f.category;
			delete f.subCategory;
			delete f.subSubCategory;
			// delete rating
			delete f.rating;
			// delete price range
			delete f.topPrice;
			delete f.bottomPrice;
		}

		this.changeDetector.detectChanges();

		if (types && types.length) {
			f.types = types;
		} else {
			delete f.types;
		}

		this.feedService.setFilter(f);
	}

	public clear() {
		this.feedService.clearFilter();

		// @ts-ignore
		// reset price range to default
		this.priceRangeSlider.reset();

		this.isProductFilterActive = true;
	}

	public getFilter(): SearchRequest {
		return this.feedService.getFilter();
	}

	public getFilterAsObservable() {
		return this.feedService.filter$;
	}

	private setProductTypeFilter(f: SearchRequest) {
		if (!f.types) {
			f.types = [];
		}
		// add product type if array does not contain it yet
		if (!f.types.some(t => t === ContentType.PRODUCT)) {
			f.types.push(ContentType.PRODUCT);
		}
	}

	set isProductFilterActive(value: boolean) {
		if (value) {
			this.elementsToDisable.forEach(e => {
				this.renderer.removeClass(e, "disabled");
			});
		} else {
			this.elementsToDisable.forEach(e => {
				this.renderer.addClass(e, "disabled");
			});
		}
		this._isProductFilterActive = value;
	}

	get isProductFilterActive(): boolean {
		return this._isProductFilterActive;
	}

	public hasActiveFilter() {
		const currentFilter = this.getFilter();
		return (
			(currentFilter.hasOwnProperty("types") && currentFilter.types && currentFilter.types.length) ||
			(currentFilter.hasOwnProperty("tags") && currentFilter.tags && currentFilter.tags.length) ||
			(currentFilter.hasOwnProperty("text") && currentFilter.text && currentFilter.text.length > 1) ||
			(currentFilter.hasOwnProperty("rating")) ||
			(currentFilter.hasOwnProperty("bottomPrice")) ||
			(currentFilter.hasOwnProperty("topPrice")) ||
			(currentFilter.hasOwnProperty("category")) ||
			(currentFilter.hasOwnProperty("subCategory")) ||
			(currentFilter.hasOwnProperty("subSubCategory")) ||
			(currentFilter.hasOwnProperty("brand"))
		);
	}

}
