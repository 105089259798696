import {EventEmitter, Inject, Injectable, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../api-communication/api-communication.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Cart} from "../../data/shop/cart/Cart";
import {AddToCartRequest} from "../../request/cart/AddToCartRequest";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {TranslateService} from "@ngx-translate/core";
import {CartItem} from "../../data/shop/cart/CartItem";
import {User} from "../../data/user/User";
import {ComissionTriggerReqeust} from "../../request/share/ComissionTriggerReqeust";

@Injectable()
export class CartService implements OnInit {

	private _cart: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(undefined);

	private _openCartEvent: EventEmitter<void> = new EventEmitter<void>();
	private _loggedInUser: User;

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				private translateService: TranslateService,
				private alertService: SweetAlertService) {
		this.fetchCart();
	}

	ngOnInit(): void {
	}

	/**
	 * Fetch cart
	 */
	public fetchCart(): void {
		try {
			this.api.cart().getCart().subscribe((response: Cart) => {
				// logged in user cart is empty
				if (!response.items.length) {
					// check local storage
					const cart = this.getCartForGuestUser(localStorage.getItem("cart"));

					// if has something in the local storage cart - guest user
					if (cart) {
						this.api.cart().addToCart(this.parseLocalStorageAddToCartObject(localStorage.getItem("cartRequest"))).subscribe();
						localStorage.removeItem("cart");
						localStorage.removeItem("cartRequest");
					}
				} else {
					this._cart.next(response);
				}
			}, (err) => {
				// empty cart
				if (err.status === 404) {
					let cart = this.getCartForGuestUser(localStorage.getItem("cart"));

					if (cart) {
						this.api.cart().addToCart(this.parseLocalStorageAddToCartObject(localStorage.getItem("cartRequest"))).subscribe();
						localStorage.removeItem("cart");
						localStorage.removeItem("cartRequest");
						return;
					} else {
						// noa cart in the local storage // empty cart
						cart = new Cart();
						cart.items = new Array<Partial<CartItem>>();
					}

					this._cart.next(cart);
				} else if (err.status === 401) {
					let guestCart: Cart = this.getCartForGuestUser(localStorage.getItem("cart"));

					if (!guestCart) {
						guestCart = new Cart();
						guestCart.items = new Array<Partial<CartItem>>();
					}

					this._cart.next(guestCart);
				} else {
					// issue
					this._cart.next(null);
					console.log(err);
				}
			});
		} finally {
		}
	}

	public addToCart(variantId: string, quantity: number, share?: any) {
		let request = new AddToCartRequest();

		if (!this._loggedInUser) {
			// guest user
			request = this.addToCartForGuestUser(variantId, quantity);
		} else {
			// logged in user
			request.items = [{variant: variantId, quantity: quantity}];
		}

		this.api.cart()
			.addToCart(request)
			.subscribe((response: Cart) => {

				// save cart to local storage
				if (!this._loggedInUser) {
					localStorage.setItem("cart", JSON.stringify(response));
					localStorage.setItem("cartRequest", JSON.stringify(request));
				}

				// if has logged in user and had share
				if (this._loggedInUser && share && share.hasOwnProperty("id")) {
					// trigger commission
					const commission = new ComissionTriggerReqeust();
					// commission.productId = share.product;
					commission.shareId = share.id;

					this.api.commission().trigger(commission).subscribe((resp) => {
						console.log("commission triggered successfully", resp);
					});
				}

				this._cart.next(response);
				// open cart event
				this._openCartEvent.emit();
			}, (err) => {
				console.log(err);
				this.alertService.error({
					title: this.translateService.instant((err.error.error.message) || "shop.cart.error-add")
				});
			});
	}

	public deleteFromCart(variantId: string) {
		if (!this._loggedInUser) {
			// fetch local storage
			const guestCartRequest = this.parseLocalStorageAddToCartObject(localStorage.getItem("cartRequest"));
			// remove variant
			guestCartRequest.items = this.arrayRemove(guestCartRequest.items, guestCartRequest.items.find(i => i.variant === variantId));
			if (guestCartRequest.items.length) {
				// send request
				this.updateGuestUserCart(guestCartRequest);
			} else {
				// just drop local storage
				localStorage.removeItem("cartRequest");
				localStorage.removeItem("cart");

				const emptyCart = new Cart();
				emptyCart.items = new Array<Partial<CartItem>>();
				this._cart.next(emptyCart);
			}
			return;
		}

		this.api.cart()
			.deleteFromCart(variantId)
			.subscribe((response: Cart) => {
				this._cart.next(response);
				// open cart event
				this._openCartEvent.emit();
			}, (err) => {
				this.alertService.error({
					title: this.translateService.instant("shop.cart.error-delete")
				});
			});
	}

	public updateQuantity(variantId: string, quantity: number, openCartEvent?: boolean) {
		if (!this._loggedInUser) {
			// fetch local storage
			const guestCartRequest = this.parseLocalStorageAddToCartObject(localStorage.getItem("cartRequest"));
			const guestCartItem = guestCartRequest.items.find(i => i.variant === variantId);

			guestCartItem.quantity += quantity;

			if (guestCartItem.quantity <= 0) {
				guestCartRequest.items = this.arrayRemove(guestCartRequest.items, guestCartItem);
			}

			// empty ?
			if (guestCartRequest.items.length) {
				// send request
				this.updateGuestUserCart(guestCartRequest);
			} else {
				// just drop local storage
				localStorage.removeItem("cartRequest");
				localStorage.removeItem("cart");

				const emptyCart = new Cart();
				emptyCart.items = new Array<Partial<CartItem>>();
				this._cart.next(emptyCart);
			}

			return;
		}

		this.api.cart()
			.updateCartItem(variantId, quantity)
			.subscribe((response) => {
				this._cart.next(response);
				// open cart event
				if (openCartEvent) {
					this._openCartEvent.emit();
				}
			}, (err) => {
				this.alertService.error({
					title: this.translateService.instant((err.error.error.message) || "shop.cart.error-update")
				});
			});
	}

	public addCharity(charityId: string): Promise<void> {

		// return new promise
		return new Promise<void>((resolve, reject) => {

			// send request
			this.api.cart()
				.addCharity(charityId)
				.subscribe((res) => {
					// assign new cart
					this._cart.next(res);
					// resolve promise
					resolve();
				}, (err) => {
					// generic error message
					this.alertService.error({
						title: this.translateService.instant("shop.cart.error-charity")
					}).then(() => reject());
				});
		});

	}

	get cart(): Observable<Cart> {
		return this._cart.asObservable();
	}

	set loggedInUser(value: User) {
		this._loggedInUser = value;
	}

	get openCartEvent(): Observable<void> {
		return this._openCartEvent.asObservable();
	}

	private updateGuestUserCart(request: AddToCartRequest) {
		this.api.cart().addToCart(request).subscribe((response: Cart) => {
			// save cart to local storage
			localStorage.setItem("cartRequest", JSON.stringify(request));
			localStorage.setItem("cart", JSON.stringify(response));

			this._cart.next(response);
			// open cart event
			this._openCartEvent.emit();
		}, (err) => {
			console.log(err);
			this.alertService.error({
				title: this.translateService.instant((err.error.error.message) || "shop.cart.error")
			});
		});
	}

	private addToCartForGuestUser(variantId: string, quantity: number): AddToCartRequest {

		const cartObject = localStorage.getItem("cartRequest");
		let cart: AddToCartRequest;

		// cart not exists in local storage
		if (!cartObject) {
			cart = new AddToCartRequest();
			cart.items = [{variant: variantId, quantity: quantity}];
		} else {
			cart = this.parseLocalStorageAddToCartObject(cartObject);

			// exists or not
			const index = cart.items.indexOf(cart.items.find(c => c.variant === variantId));
			if (index >= 0) {
				cart.items[index].quantity += quantity;
			} else {
				cart.items.push({variant: variantId, quantity: quantity});
			}
		}

		return cart;
	}

	private parseLocalStorageAddToCartObject(cartObject: string): AddToCartRequest {
		return <AddToCartRequest> JSON.parse(cartObject);
	}

	private parseLocalStorageCartObject(cartObject: string): Cart {
		return <Cart> JSON.parse(cartObject);
	}

	private getCartForGuestUser(cartObject: string): Cart {
		let cart = new Cart();

		if (cart) {
			cart = this.parseLocalStorageCartObject(cartObject);
		} else {
			// nothing set in local storage
			cart.items = new Array<Partial<CartItem>>();
		}

		return cart;
	}

	private arrayRemove(array, toDelete) {
		return array.filter(function(element) {
			return element.variant !== toDelete.variant;
		});
	}

}
