import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";

@Injectable({
	providedIn: "root"
})
export class CharitiesResolverService implements Resolve<any> {

	constructor(private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		return this.api.charity().getCharitySummarization();
	}

}

