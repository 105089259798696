import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {UserNotification} from "../../../model/data/notification/UserNotification";
import {NotificationDataService} from "../../../model/services/notification-data/notification-data.service";

@Component({
	selector: "app-notification-page",
	templateUrl: "./notification-page.component.html",
	styleUrls: ["./notification-page.component.scss"]
})
export class NotificationPageComponent implements OnInit, AfterViewInit {

	// display options for components
	public userAvatarDisplayType = UserAvatarDisplayType.IMAGE_ONLY;

	public notifications: Array<UserNotification> = null;

	constructor(@Inject(NotificationDataService) public notificationService: NotificationDataService,
	            private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		this.notificationService.notifications.subscribe((data) => {
			this.notifications = data;
			this.changeDetector.detectChanges();
		});
	}

}
