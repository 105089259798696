import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {Product} from "../../../model/data/shop/product/Product";

@Component({
	selector: "app-product-display-icon",
	templateUrl: "./product-display-icon.component.html",
	styleUrls: ["./product-display-icon.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDisplayIconComponent implements OnInit {

	@Input()
	public product: Product;

	constructor() {
	}

	ngOnInit() {
		if (this.product.variants && this.product.variants.length && this.product.variants[0].media && this.product.variants[0].media.length) {
			this.product.variants[0].media = this.product.variants[0].media.sort((a, b) => {
				if (a.position < b.position) { return -1; }
				if (a.position > b.position) { return 1; }
				return 0;
			});
		}
	}

}
