import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {Content} from "../../../model/content/Content";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {EventRequest} from "../../../model/request/event/EventRequest";
import {UpdateService} from "../../../commons/services/update/update.service";
import {Router} from "@angular/router";
import {NGX_EDITOR_CONFIG} from "../../config/ngx-editor-config";

@Component({
	selector: "app-edit-event-modal",
	templateUrl: "./edit-event-modal.component.html",
	styleUrls: ["./edit-event-modal.component.scss"]
})
export class EditEventModalComponent implements OnInit {

	public eventData: Content;
	public isLoading = false;
	public request = new EventRequest();
	public modalRef: NgbModalRef;
	private now = new Date();

	@ViewChild("fileInput", { static: true })
	public fileInput: ElementRef;
	// input field references
	@ViewChild("internalForm", { static: false })
	private _internalForm: ElementRef;
	// input field references
	@ViewChild("externalForm", { static: false })
	private _externalForm: ElementRef;

	// errors
	public descriptionError = false;
	public startDateError = false;
	public endDateError = false;

	public ngxEditorConfig = NGX_EDITOR_CONFIG;
	
	public options: any = {
		bigBanner: true,
		timePicker: true,
		format: "dd-MM-yyyy hh:mm a",
		defaultOpen: false
	};

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService,
				private updateService: UpdateService,
				private router: Router) {
	}

	ngOnInit() {
	}

	setUpEvent(event: Content) {
		this.request = EventRequest.fromEvent(event);
		this.eventData = event;
	}

	editEventAction() {
		// check form validity
		const isFormValid = this.validate();

		// form is invalid
		if (!isFormValid) {
			return;
		}

		this.isLoading = true;
		this.api.event().editEvent(
			this.eventData._id, this.request,
			(<HTMLInputElement>this.fileInput.nativeElement).files).then((data: Content) => {
			this.isLoading = false;
			this.alertService.success({title: "Event updated successfully."}).then(() => {
				this.modalRef.close();
				this.updateService.updateEvent(data);
			});
		}).catch((err) => {
			err = JSON.parse(err);
			if (err.status === 413) {
				this.alertService.error({title: `Could not update your event. <br> The size of the selected image(s) is too large. (10Mb)`});
			} else {
				if (err.message === "IMAGE RESIZE ERROR") {
					err.message = "Image(s) can not be resized";
				}
				this.alertService.error({title: `Could not update your event. <br> ${err.message}.`});
			}
			this.isLoading = false;
			(<HTMLInputElement>this.fileInput.nativeElement).value = "";
		});
	}

	deleteEventAction() {
		this.isLoading = true;
		this.api.event()
			.deleteEvent(this.eventData._id).subscribe((response: any) => {
			this.isLoading = false;
			this.alertService.success({title: "Event deleted successfully."}).then(() => {
				this.modalRef.close();
				this.router.navigate(["/"]).then(() => {
					location.reload();
				});
			});
		}, err => {
			this.alertService.error({title: "Could not delete your event."});
			this.isLoading = false;
		});
	}

	private validate(): boolean {
		let validity;
		if (this.eventData.info.type === "INTERNAL") {
			validity = (<HTMLFormElement> this._internalForm.nativeElement).checkValidity();
			// set bootstrap class indicating state
			(<HTMLFormElement> this._internalForm.nativeElement).classList.add("was-validated");

			this.descriptionError = !this.request.description || this.request.description === "";
			
			this.startDateError = !this.request.startDate;

			this.endDateError = !this.request.endDate;

			if (this.descriptionError || this.endDateError || this.startDateError) {
				validity = false;
			}

		} else {
			validity = (<HTMLFormElement> this._externalForm.nativeElement).checkValidity();
			// set bootstrap class indicating state
			(<HTMLFormElement> this._externalForm.nativeElement).classList.add("was-validated");
		}

		return validity;
	}


}
