import {distinctUntilChanged, share} from "rxjs/operators";
import {Inject, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {User} from "../../../model/data/user/User";
import {LoginWithCredentialsRequest} from "../../../model/request/auth/LoginWithCredentialsRequest";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {OAuthApiClient} from "../../../model/utility/OAuthApiClient";
import {SocialUser} from "angularx-social-login";
import {FacebookLoginRequest} from "../../../model/request/auth/FacebookLoginRequest";
import {Role} from "../../../model/data/enums/Role";

/**
 * Different reasons for login rejection.
 */
export enum LoginRejectionReason {
	ACCEPTED = "",
	UNKNOWN = "account.login-modal.login-error-unknown",
	SERVICE_UNAVAILABLE = "account.login-modal.login-error-service-unavailable",
	BAD_CREDENTIALS = "account.login-modal.login-error-wrong-credentials",
	TOKEN_EXPIRED = "account.login-modal.login-error-token-expired",
	REQUIRES_VERIFICATION = "account.login-modal.login-error-requires-verification",
	NOT_FOUND = "account.login-modal.login-error-not-found",
	TOO_MANY_REQUESTS = "account.login-modal.login-error-too-many-requests"
}

/**
 * This service is responsible for handling the browser session of the user.
 * Common tasks are:
 * - Handling Login
 * - Handling Logout
 */
@Injectable()
export class UserSessionService {

	// object keeping the actual user data
	private _userData: BehaviorSubject<any> = new BehaviorSubject(undefined);

	// object keeping the actual user logged in status
	private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private _navigation = true;

	constructor(
		@Inject(ApiCommunicationService) private api: ApiCommunicationService,
		@Inject(OAuthApiClient) private oAuthApiClient: OAuthApiClient
	) {

		// keep track of isLoggedIn value based on the user object
		this._userData.pipe(share()).subscribe(userObject => {
			this._isLoggedIn.next(userObject != null);
		});

	}

	/**
	 * User login action using email:password combo.
	 * @param {string} email The email of the user.
	 * @param {string} password The password of the user.
	 * @returns {Promise<LoginRejectionReason>} Returns promise with the login rejection reason.
	 */
	public loginWithCredentialsAction(email: string, password: string): Promise<LoginRejectionReason> {

		// assemble login request body
		const loginData: LoginWithCredentialsRequest = {
			email: email,
			password: password
		};

		// return promise
		return new Promise<LoginRejectionReason>((resolve, reject) => {

			// Get initial token with password grant type (email:password based auth)
			this.oAuthApiClient.getToken("password", loginData)
				.subscribe(() => {

					// start user session
					this.startSession().then( (sessionResult) => {
						// resolve with result of session staring
						resolve(sessionResult);
					}).catch( (sessionRejection) => {
						// reject
						reject(sessionRejection);
					});

				}, (err: HttpErrorResponse) => {

					console.log(err.error.message);

					// Wrong username or password
					if (err.status === 401) {
						reject(LoginRejectionReason.BAD_CREDENTIALS);
					// Service unavailable
					} else if (err.status === 0 || err.status === 503) {
						reject(LoginRejectionReason.SERVICE_UNAVAILABLE);
					// too many requests
					} else if (err.status === 429) {
						reject(LoginRejectionReason.TOO_MANY_REQUESTS);
					// Unknown issues
					} else if (err.status === 404) {
						reject(LoginRejectionReason.NOT_FOUND);
					} else if (err.status === 403 && err.error.message === "REQUIRES_VERIFICATION") {
						reject(LoginRejectionReason.REQUIRES_VERIFICATION);
					} else {
						reject(LoginRejectionReason.UNKNOWN);
					}

				});

		});
	}

	public loginWithFacebookAction(user: SocialUser): Promise<any> {
		const facebook = new FacebookLoginRequest();
		facebook.email = user.email;
		facebook.firstName = user.firstName;
		facebook.lastName = user.lastName;
		facebook.url = user.photoUrl;
		facebook.token = user.authToken;

		return new Promise<LoginRejectionReason>((resolve, reject) => {
			this.oAuthApiClient.endpoint_auth_facebook(facebook).then(() => {

				// start user session
				this.startSession().then( (sessionResult) => {
					// resolve with result of session staring
					resolve(sessionResult);
				}).catch( (sessionRejection) => {
					// reject
					reject(sessionRejection);
				});

			}).catch((error) => {
				if (error.status === 0 || error.status === 503) {
					reject(LoginRejectionReason.SERVICE_UNAVAILABLE);
					// Unknown issues
				} else {
					reject(LoginRejectionReason.UNKNOWN);
				}
			});
		});

	}

	/**
	 * This action logs out current user.
	 * @returns {Promise<void>}
	 */
	public logoutAction(): void {

		// fire logout request
		this.oAuthApiClient.logoutAndClearStoredData().then(() => {

			// clear user data
			this._userData.next(null);

		}).catch(() => {

			// clear user data
			this._userData.next(null);

			console.error("Could not log out properly due to network/server issues. All stored data has been removed from the browser anyway.");
		});

	}

	/**
	 * This method starts user session based on the token stored in local storage.
	 * This method is executed on startup, so user is automatically logged in while the token is valid.
	 * @returns {Promise<LoginRejectionReason>} Returns a promise containing the status of the
	 */
	public startSession(): Promise<LoginRejectionReason> {

		// creating a promise
		return new Promise<LoginRejectionReason>((resolve, reject) => {

			// get user's identity
			this.api.profile().getMyProfile().subscribe(
				(response: User) => {

				// save object as user object
				this._userData.next(response);

				// accept login and resolve
				resolve(LoginRejectionReason.ACCEPTED);
			}, (err: HttpErrorResponse) => {
				// Token is invalid
				if (err.status === 401 || err.status === 401) {
					reject(LoginRejectionReason.TOKEN_EXPIRED);
					// No response from server
				} else if (err.status === 0 || err.status === 503) {
					reject(LoginRejectionReason.SERVICE_UNAVAILABLE);
					// Other issue
				} else {
					reject(LoginRejectionReason.UNKNOWN);
				}
			});

		});
	}

	/**
	 * Starts session, but without rejecting promise, so it won't prevent the whole application from loading.
	 */
	public startSessionOnApplicationBootstrap(): Promise<void>  {
		return new Promise<void>(((resolve) => {
			// We don't care about the results.
			// Just start the session to see if user is logged in or not.
			this.startSession()
				.then(() => resolve())
				.catch(() => resolve());
		}));
	}

	get userData(): Observable<User> {
		return this._userData.asObservable().pipe(distinctUntilChanged());
	}

	get userDataValue(): User {
		return this._userData.getValue();
	}

	get isLoggedIn(): Observable<boolean> {
		return this._isLoggedIn.asObservable().pipe(distinctUntilChanged());
	}

	get isLoggedInValue(): boolean {
		return this._isLoggedIn.getValue();
	}

	get navigation(): boolean {
		return this._navigation;
	}

	public preventNavigation() {
		this._navigation = false;
		setTimeout(() => {
			this._navigation = !this._navigation;
		}, 1);
	}

	/*Role queries*/
	get isAdmin(): boolean {
		return this.isLoggedInValue ? this.userDataValue.role === Role.ADMIN : false;
	}

	get isMerchant(): boolean {
		return this.isLoggedInValue ? this.userDataValue.role === Role.MERCHANT : false;
	}

	get isMerchantPending(): boolean {
		return this.isLoggedInValue ? this.userDataValue.role === Role.MERCHANT_PENDING : false;
	}

}
