export const NGX_EDITOR_CONFIG = {
	"editable": true,
	"spellcheck": true,
	"height": "auto",
	"minHeight": 400,
	"width": "auto",
	"minWidth": 0,
	"enableToolbar": true,
	"showToolbar": true,
	"imageEndPoint": "",
	"toolbar": [
		["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
		["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
		["cut", "undo", "redo"],
		["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"]
	]
};
