import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {Role} from "../../../model/data/enums/Role";
import {ActivatedRoute, Router} from "@angular/router";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {ProfileChangeEventService} from "../../services/profile-change-event.service";
import {ImageType, ImageUploadRequest} from "../../../model/request/user/ImageUploadRequest";
import {UserStatisticsResponse} from "../../../model/response/UserStatisticsResponse";
import {BehaviorSubject} from "rxjs";
import {Relationship} from "../../../model/data/enums/Relationship";
import {Gender} from "../../../model/data/enums/Gender";
import {Interest} from "../../../model/data/enums/Interest";

@Component({
	selector: "app-user-profile-card",
	templateUrl: "./user-profile-card.component.html",
	styleUrls: ["./user-profile-card.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileCardComponent implements OnInit {

	@Input()
	public userObject: User;

	@Input()
	public writePermission = false;

	@Input("followers")
	public followersList: Array<User>;

	@Input("stats")
	public stats: UserStatisticsResponse;

	public isFollowed = null;

	public isMerchant: boolean;

	// @ViewChild("avatarUploader")
	// public avatarUploader: ElementRef;

	@ViewChild("coverUploader", { static: false })
	public coverUploader: ElementRef;

	public loggedInUser = new BehaviorSubject<User>(undefined);

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(UserSessionService) private userSessionService: UserSessionService,
				@Inject(SweetAlertService) private alertService: SweetAlertService,
				@Inject(TranslateService) private translate: TranslateService,
				@Inject(Router) private router: Router,
				@Inject(ActivatedRoute) private route: ActivatedRoute,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				private profileChangeEventService: ProfileChangeEventService,
				private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		// is the user merchant
		this.isMerchant = this.userObject.role === Role.MERCHANT;

		this.profileChangeEventService.userUpdateEvent.subscribe((event: User) => {
			this.userObject = event;
			this.changeDetector.markForCheck();
		});

		this.userSessionService.userData.subscribe((user: User) => {
			if (user) {
				// its me?
				if (user._id === this.userObject._id) {
					// yes
					this.writePermission = true;
				} else {
					// nope
					this.isFollowed = this.followersList.some(u => u._id === user._id);
				}
				this.loggedInUser.next(user);
			} else {
				this.isFollowed = null;
				this.writePermission = false;
				this.loggedInUser.next(undefined);
			}

			this.changeDetector.detectChanges();
		});

	}

	/**
	 * Toggles "Followed" status for user.
	 */
	public toggleFollowStatus() {

		if (this.isFollowed === null) {
			this.modalServiceProxy.get().showLoginModal();
		} else if (!this.isFollowed) {
			// follow user
			this.api.relationship()
				.followUser({userId: this.userObject._id, type: Relationship.FOLLOW})
				.subscribe(() => {
					this.stats.followers++;
					this.isFollowed = true;
					this.changeDetector.detectChanges();
					this.profileChangeEventService.onFollowEvent(this.userObject._id);
				});
		} else {
			// un-follow user
			this.api.relationship()
				.unfollowUser(this.userObject._id)
				.subscribe(() => {
					this.isFollowed = false;
					this.stats.followers--;
					this.changeDetector.detectChanges();
					this.profileChangeEventService.onFollowEvent(this.userObject._id);
				});
		}

	}

	/**
	 * Adds user to the list of blocked users.
	 */
	public blockUser() {
		if (this.getLoggedInUser()) {
			this.alertService.confirm(
				{
					title: this.translate.instant(
						"social.block-user-confirm",
						{username: this.userObject.userName})
				})
				.then(() => {
					// send api request
					this.api.relationship()
						.blockUser({userId: this.userObject._id, type: Relationship.BLOCKED})
						.subscribe((data) => {
							this.router.navigate(["/"]);
						});
				});
		} else {
			this.modalServiceProxy.get().showLoginModal();
		}

	}

	/**
	 * Triggers avatar upload action.
	 */
	public uploadAvatarAction() {
		if (this.writePermission) {
			this.modalServiceProxy.get().showImageUploadModal();
		}
	}

	/**
	 * Triggers cover upload action
	 */
	public uploadCoverAction() {
		if (this.writePermission) {
			(<HTMLInputElement>this.coverUploader.nativeElement).click();
		}
	}

	/**
	 * Handles avatar upload action.
	 */
	// public handleAvatarChange() {
	// 	if (this.writePermission) {
	// 		const input = (<HTMLInputElement>this.avatarUploader.nativeElement);
	// 		const files: FileList = input.files;
	//
	// 		if (files.length === 0) {
	// 			return;
	// 		}
	//
	// 		this.modalServiceProxy.get().showImageUploadModal(files);
	// 		// file input
	// 		// const input = (<HTMLInputElement>this.avatarUploader.nativeElement);
	// 		// const files: FileList = input.files;
	// 		//
	// 		// // return if no files
	// 		// if (files.length === 0) {
	// 		// 	return;
	// 		// }
	// 		//
	// 		// const request = new ImageUploadRequest();
	// 		// request.images = files;
	// 		// request.type = ImageType.PROFILE;
	// 		//
	// 		// this.api.profile().uploadProfileImage(request)
	// 		// 	.then((response: User) => {
	// 		// 		this.userObject = response;
	// 		// 		this.changeDetector.markForCheck();
	// 		// 	});
	// 	}
	// }

	/**
	 * Handles cover upload action.
	 */
	public handleCoverChange() {
		if (this.writePermission) {
			// file input
			const input = (<HTMLInputElement>this.coverUploader.nativeElement);
			const files: FileList = input.files;

			// return if no files
			if (files.length === 0) {
				return;
			}

			const request = new ImageUploadRequest();
			request.type = ImageType.BACKGROUND;
			request.images = files;

			this.api.profile().uploadProfileImage(request)
				.then((response: User) => {
					this.userObject = response;
					this.changeDetector.detectChanges();
				});
		}
	}

	public getLoggedInUser(): User {
		return this.loggedInUser.value;
	}

	get isGenderDisplayed() {
		return this.userObject.gender && this.userObject.gender !== Gender.RATHER_NOT_SAY;
	}

	get isInterestDisplayed() {
		return this.userObject.interestedIn && this.userObject.interestedIn !== Interest.RATHER_NOT_SAY;
	}

}
