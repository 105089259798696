import {Component, Input, OnInit} from "@angular/core";
import {Countries} from "../../../model/static/Countries";
import {Address} from "../../../model/data/checkout/Address";
import {InputMasks} from "../../../model/static/InputMasks";

@Component({
	selector: "app-checkout-address-form",
	templateUrl: "./checkout-address-form.component.html",
	styleUrls: ["./checkout-address-form.component.scss"]
})
export class CheckoutAddressFormComponent implements OnInit {

	public countryList = Countries;
	public inputMasks = InputMasks;

	@Input()
	public model: Address;

	@Input()
	public isLoggedInUser: boolean;

	constructor() {
	}

	ngOnInit() {
	}

}
