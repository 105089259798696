import {ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {ProfileEditModalService} from "../../services/profile-edit-modal.service";

@Component({
	selector: "app-profile-dashboard-page",
	templateUrl: "./profile-dashboard-page.component.html",
	styleUrls: ["./profile-dashboard-page.component.scss"]
})
export class ProfileDashboardPageComponent implements OnInit {


	public user: User;

	constructor(@Inject(UserSessionService) public userSessionService: UserSessionService,
	            @Inject(ProfileEditModalService) private profileEditModalService: ProfileEditModalService,
	            private cd: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.userSessionService.userData.subscribe((u) => {
			this.user = u;
		});
	}

	public editProfileModal() {
		this.profileEditModalService.openModal(this.user).subscribe((u) => {
			if (u) {
				this.user = u;
				this.cd.detectChanges();
			}
		});
	}


}
