import {AbstractApiConnector} from "./AbstractApiConnector";
import {CollectionType} from "../data/enums/CollectionType";
import {Observable} from "rxjs";
import {CollectionListResponse} from "../response/CollectionListResponse";
import {Collection} from "../data/collection/Collection";
import {CreateCollectionRequest} from "../request/collection/CreateCollectionRequest";
import {EditCollectionRequest} from "../request/collection/EditCollectionRequest";

export class CollectionApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/collection";

	public getCollection(collectionId: string): Observable<Collection> {
		return this.apiClient.get(`${this.apiRoute}/${collectionId}`);
	}

	public getCollections(pageNumber: number, userId: string, type: CollectionType): Observable<CollectionListResponse> {
		return this.apiClient.get(`${this.apiRoute}/${pageNumber}/list`, {user: userId, type: type});
	}

	public deleteCollection(collectionId): Observable<any> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${collectionId}`);
	}

	public createCollection(collectionRequest: CreateCollectionRequest): Observable<Collection> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, collectionRequest);
	}

	public addToCollection(collectionId: string, request: EditCollectionRequest): Observable<Collection> {
		return this.apiClient.authorizedOnly().patch(`${this.apiRoute}/${collectionId}/add`, request);
	}

	public removeFromCollection(collectionId: string, request: EditCollectionRequest): Observable<Collection> {
		return this.apiClient.authorizedOnly().patch(`${this.apiRoute}/${collectionId}/remove`, request);
	}

}
