import {AbstractApiConnector} from "./AbstractApiConnector";
import {ShareRequest} from "../request/share/ShareRequest";

export class ShareApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/share";

	public shareProduct(shareRequest: ShareRequest) {
		return this.apiClient.post(this.apiRoute, shareRequest);
	}

}
