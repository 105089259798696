import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {UserSessionService} from "../services/user-session/user-session.service";

@Injectable()
export class NoAuthGuardService implements CanActivate {

	constructor(@Inject(UserSessionService) private userSessionService: UserSessionService,
				private router: Router) {
	}


	// functionality only when Not logged in
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		// get value
		const isLoggedIn = this.userSessionService.isLoggedInValue;

		if (isLoggedIn) {
			// fall back to home
			this.router.navigate([route.queryParams["returnUrl"] || "/"]);
		}

		// decision based on logged in state
		return !isLoggedIn;

	}

}
