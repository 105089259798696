import {Pipe, PipeTransform} from "@angular/core";
import * as Autolinker from "autolinker/dist/autolinker.min";

@Pipe({name: "removeLinks"})
export class RemoveLinksPipe implements PipeTransform {
	transform(value: string, options?: any): string {
		return Autolinker.link(value, {
			replaceFn: function (match) {
				switch (match.type) {
					case "url":
						// let's replace all links with an empty string
						return "";
				}
			}
		});
	}
}
