import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Like} from "../data/social/Like";


export class LikeApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/like";

	public getLike(like: Like): Observable<Array<Like>> {
		return this.apiClient.get(`${this.apiRoute}`, like);
	}

	public like(like: Like): Observable<Like> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, like);
	}

	public unlike(likeId: string): Observable<any> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${likeId}`);
	}

}
