import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {Collection} from "../../model/data/collection/Collection";

@Injectable()
export class CollectionResolverService implements Resolve<Collection> {

	constructor(private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Collection> | Promise<Collection> | Collection {
		return this.api.collection().getCollection(route.params["collectionId"]);
	}

}
