import {DatabaseEntity} from "../data/DatabaseEntity";
import {ContentType} from "../data/enums/ContentType";
import {User} from "../data/user/User";
import {Like} from "../data/social/Like";
import {Media} from "../media/Media";
import {Share} from "../share/Share";
import {EventBrite} from "../data/shop/event/EventBrite";
import {Comment} from "../data/social/Comment";

export class Content extends DatabaseEntity {

	title: string;
	description: string;
	backgroundId: number;
	type: ContentType;
	numberOfLikes: number;
	numberOfComments: number;
	numberOfShares: number;
	media: Array<Partial<Media>>;
	user: User;
	info?: Partial<EventBrite>;
	likes?: Array<Partial<Like>>;
	comments?: Array<Partial<Comment>>;
	shares?: Array<Partial<Share>>;

}
