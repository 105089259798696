import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {BehaviorSubject, Observable} from "rxjs";
import {Product} from "../../../model/data/shop/product/Product";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: "app-merchant-brands",
	templateUrl: "./merchant-brands.component.html",
	styleUrls: ["./merchant-brands.component.scss"]
})
export class MerchantBrandsComponent implements OnInit {

	public username: string;
	public _brands: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

	constructor(private route: ActivatedRoute,
	            private location: Location,
	            private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe((data) => {

			this.username = data.username;
			this._brands.next(data.brands);

			this.changeDetector.detectChanges();

		});

	}


	get brands(): Observable<Array<Product>> {
		return this._brands.asObservable();
	}

}
