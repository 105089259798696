import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from "@angular/core";
import {Cart} from "../../../model/data/shop/cart/Cart";
import {CartService} from "../../../model/services/cart/cart.service";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {User} from "../../../model/data/user/User";
import {Router} from "@angular/router";

@Component({
	selector: "app-cart-menu",
	templateUrl: "./cart-menu.component.html",
	styleUrls: ["./cart-menu.component.scss"]
})
export class CartMenuComponent implements OnInit, AfterViewInit {

	public cart: Cart;
	private _isLoading = false;
	public cartSize = 0;

	@ViewChild("menu", { static: true })
	private menu: NgbDropdown;

	@Input()
	public skipMenu = false;

	// if payment page is active, disable menu
	public isPaymentPageActive = false;

	constructor(@Inject(CartService) private cartService: CartService,
				@Inject(Router) private router: Router,
				@Inject(UserSessionService) private userSessionService: UserSessionService) {
	}

	ngOnInit() {
		this.cartService.cart.subscribe((c: Cart) => {
			this._isLoading = c === undefined;
			this.cart = c;

			this.cartSize = 0;
			// count amount of variants in the cart
			if (this.cart) {
				c.items.map(citem => {
					if (citem.items) {
						this.cartSize += citem.items.length;
					}
				});
			}
		});

		this.router.events.subscribe(() => {
			this.isPaymentPageActive = this.router.url.indexOf("/payment") > -1;
		});

		this.userSessionService.userData.subscribe((user: User) => {
			this.cartService.loggedInUser = user;
			this.cartService.fetchCart();

		});

		// subscribe to open cart event
		this.cartService.openCartEvent.subscribe(() => this.menu.open());
	}


	ngAfterViewInit(): void {
	}

	public onOpenChangeEvent(isOpen: any): void {
		if (isOpen) {
			this.cartService.fetchCart();
		}
	}

	deleteItemAction(variantId: string) {
		this.cartService.deleteFromCart(variantId);
	}

	updateQuantityAction(variantId: string, quantity: number) {
		this.cartService.updateQuantity(variantId, quantity);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

}
