import {AfterViewInit, Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CartService} from "../../../model/services/cart/cart.service";
import {Order} from "../../../model/data/checkout/Order";
import {OrderListResponse} from "../../../model/response/OrderListResponse";
import {ShippingInfoService} from "../../services/shipping-info.service";
import {environment} from "../../../../environments/environment";

declare const Stripe: any;

// Stripe element style
const stripeElementStyle = {
	base: {
		color: "#252525",
		lineHeight: "18px",
		fontFamily: "\"Roboto\", \"Helvetica Neue\", Arial, sans-serif",
		fontSmoothing: "antialiased",
		fontSize: "16px",
		"::placeholder": {
			color: "#AAAAAA"
		}
	},
	invalid: {
		color: "#dc3545",
		iconColor: "#dc3545"
	}
};

@Component({
	selector: "app-checkout-payment-page",
	templateUrl: "./checkout-payment-page.component.html",
	styleUrls: ["./checkout-payment-page.component.scss"]
})
export class CheckoutPaymentPageComponent implements OnInit, AfterViewInit {

	// The order data
	public orders: Array<Order>;

	// stripe obj
	private stripe = Stripe(environment.stripeKey);
	private cardForm: any;
	public stripeErrorText: string;

	constructor(private api: ApiCommunicationService,
				private swal: SweetAlertService,
				private cartService: CartService,
				private route: ActivatedRoute,
				private router: Router) {
	}

	ngOnInit() {
		this.route.parent.data.subscribe((data: OrderListResponse) => {
			this.orders = data.docs;
		});
	}

	ngAfterViewInit(): void {
		// initialize stripe elements
		this.initStripe();
	}

	public initStripe(): void {

		// create elements instance
		const elements = this.stripe.elements();

		// create card payment form
		this.cardForm = elements.create("payment", {style: stripeElementStyle});

		// attach it to the corresponding element
		this.cardForm.mount("#card-element");

		// handle real-time validation errors from the card Element.
		this.cardForm.addEventListener("change", (event) => {
			if (event.error) {
				// assign the error message
				this.stripeErrorText = event.error.message;
			} else {
				// remove the error message
				this.stripeErrorText = undefined;
			}
		});
	}

	/**
	 * Triggered when user clicks on the checkout button.
	 * Gets a stripe token and passes it to #{checkoutStripeTokenAction()}.
	 */
	public checkoutAction() {

		// create stripe token via stripe api call
		this.stripe.createToken(this.cardForm).then((result) => {
			if (result.error) {
				// Inform the user if there was an error.
				this.stripeErrorText = result.error.message;
			} else {

				// remove the error message
				this.stripeErrorText = undefined;

				// Send the token to your server.
				this.checkoutStripeTokenAction(result.token.id);

			}
		});
	}

	/**
	 * Triggered by stripe api when token is received.
	 * Passes token to backend server and completes checkout.
	 * @param token the stripe api token.
	 */
	public checkoutStripeTokenAction(token: string) {

		const request = ShippingInfoService.getShippingInfo();
		request.stripeToken = token;

		// fire the request
		this.api.payment().checkout(request).subscribe((data) => {
			this.swal.success({title: "Your order was successful"})
				.then(() => {
					// update cart with now nothing in it

					// remove cart and shipping related properties if request was successful
					ShippingInfoService.clearLocalStorage();

					this.cartService.fetchCart();
					this.router.navigate(["/"]);
				});
		}, (err) => {
			this.stripeErrorText = err.error.message;
		});
	}

}
