import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {myProfileRoutes} from "./my-profile.routing";
import {ProfilePageComponent} from "./pages/profile-page/profile-page.component";
import {ProfileMenuComponent} from "./components/profile-menu/profile-menu.component";
import {ProfileDashboardPageComponent} from "./pages/profile-dashboard-page/profile-dashboard-page.component";
import {ProfileBlogpostsComponent} from "./components/profile-blogposts/profile-blogposts.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {ProfileEditModalComponent} from "./modals/profile-edit-modal/profile-edit-modal.component";
import {ProfileEditModalService} from "./services/profile-edit-modal.service";
import {TranslateModule} from "@ngx-translate/core";
import {ProfileImageUploadModalComponent} from "./modals/profile-image-upload-modal/profile-image-upload-modal.component";
import {ProfileOrdersComponent} from "./components/profile-orders/profile-orders.component";
import {ProfileSocialPanelsComponent} from "./components/profile-social-panels/profile-social-panels.component";
import {ProfileWalletComponent} from "./components/profile-wallet/profile-wallet.component";


@NgModule({
	declarations: [
		// layout
		ProfilePageComponent,
		ProfileMenuComponent,
		// dashboard
		ProfileDashboardPageComponent,
		ProfileBlogpostsComponent,
		ProfileOrdersComponent,
		ProfileEditModalComponent,
		ProfileImageUploadModalComponent,
		ProfileSocialPanelsComponent,
		ProfileWalletComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(myProfileRoutes),
		TranslateModule,
		NgbModule,
		ImageCropperModule,
		FormsModule
	],
	providers: [
		ProfileEditModalService
	],
	exports: [
		ProfileBlogpostsComponent,
		ProfileImageUploadModalComponent,
	],
	entryComponents: [
		ProfileEditModalComponent,
		ProfileImageUploadModalComponent
	]
})
export class MyProfileModule {
}
