export class PagedResponse<T> {

	docs: Array<T>;
	hasNextPage: boolean;
	hasPrevPage: boolean;
	limit: number;
	page: number;
	nextPage: number;
	pagingCounter;
	prevPage: string;
	totalDocs: number;
	totalPages: number;

}
