import {AbstractApiConnector} from "./AbstractApiConnector";
import {RegistrationRequest} from "../request/registration/RegistrationRequest";
import {Observable} from "rxjs";

export class AuthApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "api/v1/auth";

	public signUp(data: RegistrationRequest, asMerchant = false): Observable<any> {
		const suffix = asMerchant ? "/merchant" : "";

		return this.apiClient.post(this.apiRoute + "/signup" + suffix, data);
	}

}
