import {Component, HostListener, Inject, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import {NgbRatingConfig} from "@ng-bootstrap/ng-bootstrap";
import {PageTitleService} from "./commons/services/page-title/page-title.service";
import {UserSessionService} from "./user/services/user-session/user-session.service";
import {PostBackgroundService} from "./commons/services/post-background/post-background.service";
import {PostService} from "./social/services/post/post.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

	public isScrolled = false;

	constructor(
		// injectable fields
		private translate: TranslateService,
		private ratingConfig: NgbRatingConfig,
		private router: Router,
		private postService: PostService,
		@Inject(PageTitleService) private pageTitleService: PageTitleService,
		@Inject(UserSessionService) private userSessionService: UserSessionService,
		@Inject(PostBackgroundService) private postBackgroundService: PostBackgroundService
	) {
		// initialize stuff
		this.initTranslations();
		this.initRatingConfiguration();
		this.initPostBackgrounds();
	}

	ngOnInit() {
		// set up stuff
		this.setupPageTitle();
		this.setupRouterEvents();
		this.checkPostModalAction();
	}

	/*Scroll event handler*/
	@HostListener("window:scroll", ["$event"])
	private onWindowScroll(): void {
		this.isScrolled = window.pageYOffset !== 0;
	}

	private initTranslations(): void {

		// translation service defaults
		this.translate.setDefaultLang("en");
		this.translate.use("en");

	}

	private initPostBackgrounds(): void {
		this.postBackgroundService.loadBackgrounds();
	}

	private initRatingConfiguration(): void {

		// bootstrap rating defaults
		this.ratingConfig.max = 5;
		this.ratingConfig.readonly = false;

	}

	private setupPageTitle(): void {

		// Reset page title
		this.pageTitleService.resetPageTitle();

	}

	private setupRouterEvents(): void {

		// scroll to the top of the page when navigation happens
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});

	}

	private checkPostModalAction() {
		const rawQueryParams = location.href.split(/[?&]/);
		const postQueryParam = rawQueryParams.find(r => r.includes("post"));

		if (postQueryParam) {
			const postId = postQueryParam.split("=")[1];
			this.postService.openPostModalFromUrl(postId);
		}
	}

}
