import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ResetPasswordRequest} from "../../../model/request/user/ResetPasswordRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";

@Component({
	selector: "app-reset-password-page",
	templateUrl: "./reset-password-page.component.html",
	styleUrls: ["./reset-password-page.component.scss"]
})
export class ResetPasswordPageComponent implements OnInit, AfterViewInit {

	@ViewChild("resetForm", { static: true })
	private _resetForm: ElementRef;
	@ViewChild("passwordInputField", { static: true })
	private _passwordInputField: ElementRef;
	@ViewChild("passwordConfirmInputField", { static: true })
	private _passwordConfirmInputField: ElementRef;

	public errorMessage: string;
	public success = false;
	public isLoading = false;

	public resetPasswordRequest: ResetPasswordRequest = new ResetPasswordRequest();

	constructor(private api: ApiCommunicationService,
				private route: ActivatedRoute,
				private router: Router,
				private pageTitleService: PageTitleService,
				private alertService: SweetAlertService,
				private modalServiceProxy: ModalServiceProxy,
				private translate: TranslateService) {
	}

	ngOnInit() {
		// set page title
		this.pageTitleService.setPageTitle("Reset password");

		// get token from route params
		this.resetPasswordRequest.token = this.route.snapshot.paramMap.get("token");
	}

	ngAfterViewInit() {
		// focus on element when modal is shown
		(<HTMLInputElement> this._passwordInputField.nativeElement).focus();
	}

	public sendResetPasswordRequest() {

		// reset error message
		this.errorMessage = null;

		// check form validity
		const isFormValid = (<HTMLFormElement> this._resetForm.nativeElement).checkValidity();

		// set bootstrap class indicating state
		(<HTMLFormElement> this._resetForm.nativeElement).classList.add("was-validated");

		// form is invalid
		if ( isFormValid ) {
			this.errorMessage = null;
			this.isLoading = true;
			this.success = false;

			this.api.profile().resetPassword(this.resetPasswordRequest)
				.subscribe((response) => {
					this.success = true;
					this.errorMessage = this.translate.instant("account.reset-password.reset-success-hint");

					// show alert about successful password change
					this.alertService.success({title: this.errorMessage})
						// after pressing OK
						.then(() => {
							// go to home screen
							this.router.navigate(["/"]);
							// show login modal
							this.modalServiceProxy.get().showLoginModal();
						});

				}, (err) => {
					// display error if token is invalid or other issue happened
					if (err.status === 400) {
						this.errorMessage = this.translate.instant("account.reset-password.reset-no-match-hint");
					} else {
						this.errorMessage = this.translate.instant("account.reset-password.reset-error-hint");
					}
					this.isLoading = false;
				});
		}
	}

	// set validity of confirm field
	public passwordConfirmChange() {
		if (this.resetPasswordRequest.newPassword !== this.resetPasswordRequest.newPasswordConfirm) {
			(<HTMLInputElement> this._passwordConfirmInputField.nativeElement).setCustomValidity("does-not-match");
		} else {
			(<HTMLInputElement> this._passwordConfirmInputField.nativeElement).setCustomValidity("");
		}
	}

}
