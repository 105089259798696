import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from "@angular/core";
import {fadeInOutAnimation, flyUpAnimation} from "../../../commons/animations/modalAnimations";
import {User} from "../../../model/data/user/User";
import {Gender} from "../../../model/data/enums/Gender";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {NgbDateAdapter} from "@ng-bootstrap/ng-bootstrap";
import {IsoDateAdapter} from "../../../commons/utils/IsoDateAdapter";
import {UserRequest} from "../../../model/request/user/UserRequest";
import {Router} from "@angular/router";
import {Interest} from "../../../model/data/enums/Interest";
import {ImageType, ImageUploadRequest} from "../../../model/request/user/ImageUploadRequest";
import {UserStatus} from "../../../model/data/enums/UserStatus";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";

@Component({
	selector: "app-profile-edit-modal",
	templateUrl: "./profile-edit-modal.component.html",
	styleUrls: ["./profile-edit-modal.component.scss"],
	animations: [
		fadeInOutAnimation, flyUpAnimation
	],
	providers: [{provide: NgbDateAdapter, useClass: IsoDateAdapter}]
})
export class ProfileEditModalComponent implements OnInit {

	public closeAction: EventEmitter<User | null> = new EventEmitter<User | null>();

	// loading indicator
	public isLoading = false;

	@ViewChild("editForm", { static: true })
	private _editForm: ElementRef;

	@ViewChild("avatarUploader", { static: true })
	public avatarUploader: ElementRef;

	@ViewChild("coverUploader", { static: true })
	public coverUploader: ElementRef;

	// user
	public user: User;

	// gender keys
	public genderKeys: Array<string> = [];

	public statusKeys: Array<string> = [];

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
	            @Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				private router: Router,
				private changeDetector: ChangeDetectorRef) {

		// gender enum keys
		for (let key in Gender) {
			this.genderKeys.push(Gender[key]);
		}

		for (let key in Interest) {
			this.interestKeys.push(Interest[key]);
		}

		// for (let key in UserStatus) {
			this.statusKeys.push(UserStatus.ACTIVE);
			this.statusKeys.push(UserStatus.ON_HOLIDAY);
		// }

	}

	// interest keys
	public interestKeys: Array<string> = [];

	ngOnInit() {
	}

	public saveAction() {

		// set loading, disable all inputs
		this.isLoading = true;

		// check form validity
		const isFormValid = (<HTMLFormElement> this._editForm.nativeElement).checkValidity();

		// set bootstrap class indicating state
		(<HTMLFormElement> this._editForm.nativeElement).classList.add("was-validated");

		if (isFormValid) {
			// send update request
			this.api.profile().updateProfile(UserRequest.fromUser(this.user)).subscribe((user: User) => {
					this.isLoading = false;
					// this.service.onUserUpdateEvent(user);
					this.closeAction.emit(user);
				}, () => {
					this.isLoading = false;
				});
		}

	}

	/**
	 * Triggers avatar upload action.
	 */
	public uploadAvatarAction() {
		this.modalServiceProxy.get().showImageUploadModal();
		// (<HTMLInputElement>this.avatarUploader.nativeElement).click();
	}

	/**
	 * Triggers cover upload action
	 */
	public uploadCoverAction() {
		(<HTMLInputElement>this.coverUploader.nativeElement).click();
	}

	/**
	 * Handles avatar upload action.
	 */
	public handleAvatarChange() {
		// // file input
		// const input = (<HTMLInputElement>this.avatarUploader.nativeElement);
		// const files: FileList = input.files;
		//
		// // return if no files
		// if (files.length === 0) {
		// 	return;
		// }
		//
		// const request = new ImageUploadRequest();
		// request.type = ImageType.PROFILE;
		// request.images = files;
		//
		// this.api.profile().uploadProfileImage(request)
		// 	.then((response: User) => {
		// 		this.user = response;
		// 		this.changeDetector.detectChanges();
		// 	});
	}

	/**
	 * Handles cover upload action.
	 */
	public handleCoverChange() {
		// file input
		const input = (<HTMLInputElement>this.coverUploader.nativeElement);
		const files: FileList = input.files;

		// return if no files
		if (files.length === 0) {
			return;
		}

		const request = new ImageUploadRequest();
		request.type = ImageType.BACKGROUND;
		request.images = files;

		this.api.profile().uploadProfileImage(request)
			.then((response: User) => {
				this.user = response;
				this.changeDetector.detectChanges();
			});
	}

	/**
	 * Navigates the actual user to the change password page.
	 */
	public onClickChangePassword() {
		this.closeAction.emit(null);
		this.router.navigate(["/user", this.user.userName, "change-password"]);
	}

}
