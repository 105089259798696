import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginModalComponent} from "./modals/login-modal/login-modal.component";
import {UserAvatarComponent} from "./components/user-avatar/user-avatar.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule, Routes} from "@angular/router";
import {UserListDisplayComponent} from "./components/user-list-display/user-list-display.component";
import {InlineSVGModule} from "ng-inline-svg";
import {ForgotPasswordPageComponent} from "./pages/forgot-password-page/forgot-password-page.component";
import {ResetPasswordPageComponent} from "./pages/reset-password-page/reset-password-page.component";
import {NoAuthGuardService} from "./guard/noauth-guard.service";
import {NgDatepickerModule} from "ng2-datepicker";
import {ChangePasswordPageComponent} from "./pages/change-password-page/change-password-page.component";
import {AuthGuardService} from "./guard/auth-guard.service";
import {EmailVerificationComponent} from "./pages/email-verification/email-verification.component";

const routes: Routes = [
	// Checkout page
	{
		path: "reset-password",
		component: ForgotPasswordPageComponent,
		canActivate: [NoAuthGuardService]
	},
	{
		path: "reset-password/:token",
		component: ResetPasswordPageComponent,
		canActivate: [NoAuthGuardService]
	},
	{
		path: "email-verification/:token",
		component: EmailVerificationComponent,
		canActivate: [NoAuthGuardService]
	},
	{
		path: "user/:userName/change-password",
		component: ChangePasswordPageComponent,
		canActivate: [AuthGuardService]
	}
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		NgbModule,
		HttpClientModule,
		RouterModule.forChild(routes),
		InlineSVGModule,
		NgDatepickerModule,

		FormsModule,
		ReactiveFormsModule,
	],
	declarations: [
		UserAvatarComponent,
		LoginModalComponent,
		UserListDisplayComponent,
		ForgotPasswordPageComponent,
		ResetPasswordPageComponent,
		ChangePasswordPageComponent,
		EmailVerificationComponent,
	],
	exports: [
		UserAvatarComponent,
		UserListDisplayComponent
	],
	entryComponents: [
		LoginModalComponent,
	]
})
export class UserModule {
}
