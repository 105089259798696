import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ParseLinksPipe} from "./pipes/ParseLinksPipe";
import {RemoveLinksPipe} from "./pipes/RemoveLinksPipe";

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		ParseLinksPipe,
		RemoveLinksPipe
	],
	exports: [
		ParseLinksPipe,
		RemoveLinksPipe
	]
})
export class ModelModule {
}
