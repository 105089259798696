import {DatabaseEntity} from "../../DatabaseEntity";
import {User} from "../../user/User";
import {CartItem} from "./CartItem";
import {CurrencyType} from "../../enums/CurrencyType";
import Decimal from "decimal.js";
import {Organization} from "../../organization/Organization";

export class Cart extends DatabaseEntity {

	user: Partial<User>;
	currency: CurrencyType;
	total: Decimal;
	subTotal: Decimal;
	totalOffered: Decimal;
	charityAccount: Partial<Organization>;
	charityAmount: Decimal;
	shippingPrice: Decimal;
	// fees: Array<Partial<Fee>>;
	items: Array<Partial<CartItem>>;

}
