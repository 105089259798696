import {BehaviorSubject} from "rxjs";
import {Inject, Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {Feed} from "../../../model/data/feed/Feed";
import {FeedListResponse} from "../../../model/response/FeedListResponse";
import {SearchRequest} from "../../../model/request/search/SearchRequest";
import {SearchSuggestionsResponse} from "../../../model/response/SearchSuggestionsResponse";
import {HomeSearchUrlService} from "../home-search-url/home-search-url.service";
import {CategoryService} from "../../../commons/services/category/category.service";
import {ActivatedRoute, Event, NavigationEnd, NavigationStart, Router} from "@angular/router";

/**
 * This service is responsible for providing data to the Home-Feed (HomeMainComponent).
 */
@Injectable()
export class HomeFeedDataService {

	// create empty feed
	private _feedData$: BehaviorSubject<Feed> = new BehaviorSubject<Feed>(undefined);
	private _feedListResponse = new FeedListResponse();

	private _filter$: BehaviorSubject<SearchRequest> = new BehaviorSubject<SearchRequest>(new SearchRequest());

	private _suggestions$: BehaviorSubject<SearchSuggestionsResponse> = new BehaviorSubject<SearchSuggestionsResponse>(undefined);

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(Router) private router: Router,
				@Inject(HomeSearchUrlService) private urlService: HomeSearchUrlService,
				@Inject(CategoryService) private categoryService: CategoryService) {


		this.router.events.subscribe((ev :Event)  => {
			if(ev instanceof NavigationEnd) {
				const filter = this.urlService.getSearchFromUrl();
				if (filter) {
					this.setFilter(filter, false);
				} else {
					this.getFeedFirstPage();
				}
			}
		})

	}

	public getFeedFirstPage(): void {
		this.api.feed().getFeed(1).subscribe((data: FeedListResponse) => {
			this.feedListResponse = data;
			this.feedListResponse.isNextPageAuthoritativeForSearch = false;
			this._feedData$.next(data.docs);
		});
	}

	public getFeedNextPage() {
		if (!this.feedListResponse.hasNextPage) {
			return;
		}

		if (this.hasActiveFilter()) {
			this.search();
		} else {
			this.api.feed().getFeed(this.feedListResponse.nextPage).subscribe((data: FeedListResponse) => {
				this.feedListResponse = data;
				this.feedListResponse.isNextPageAuthoritativeForSearch = false;
				const updatedDocs: Feed = this.feedData$.value.concat(data.docs);
				this.feedData$.next(updatedDocs);
			});
		}
	}

	public search() {
		const nextPage = this.feedListResponse.isNextPageAuthoritativeForSearch ? this.feedListResponse.nextPage : 1;
		this.api.search().search(nextPage, this.getFilter()).subscribe((data: any) => {
			let updatedDocs: Feed;
			if (this.feedListResponse.isNextPageAuthoritativeForSearch) {
				updatedDocs = this.feedData$.value.concat(data.docs);
			} else {
				updatedDocs = data.docs;
			}

			this.feedListResponse = data;
			this.feedListResponse.isNextPageAuthoritativeForSearch = true;
			this.feedData$.next(updatedDocs);
		});
	}

	public clearFilter() {
		const text = this.getFilter().text;
		const f = new SearchRequest();
		if (text && !this.isTextFilterIncludeTypeFilter(text.toLowerCase())) {
			f.text = text;
		}
		// clear filter except text
		this.setFilter(f);
	}

	public getSuggestions() {
		this.api.search().getSuggestions(this.getFilter()).subscribe((data: SearchSuggestionsResponse) => {
			const currentFilter = this.getFilter();
			const hasActiveCategoryFilter = currentFilter.category || currentFilter.subCategory || currentFilter.subSubCategory;

			const s = {
					users: data.users.slice(0, 5),
					// only show category suggestions if there is no category is set originally
					categories: hasActiveCategoryFilter ? [] : data.categories.slice(0, 5),
					brands: data.brands.slice(0, 5),
			};

			this._suggestions$.next(s);
		});
	}

	private hasActiveFilter() {
		const currentFilter = this.getFilter();
		return (
			(currentFilter.hasOwnProperty("types") && currentFilter.types && currentFilter.types.length) ||
			(currentFilter.hasOwnProperty("tags") && currentFilter.tags && currentFilter.tags.length) ||
			(currentFilter.hasOwnProperty("text") && currentFilter.text && currentFilter.text.length > 1) ||
			(currentFilter.hasOwnProperty("rating")) ||
			(currentFilter.hasOwnProperty("bottomPrice")) ||
			(currentFilter.hasOwnProperty("topPrice")) ||
			(currentFilter.hasOwnProperty("category")) ||
			(currentFilter.hasOwnProperty("subCategory")) ||
			(currentFilter.hasOwnProperty("subSubCategory")) ||
			(currentFilter.hasOwnProperty("brand"))
		);
	}

	private isTextFilterIncludeTypeFilter(text: string): boolean {
		return text.includes("product") || text.includes("post") || text.includes("blog") || text.includes("event");
	}

	// getters and setters
	get feedData$(): BehaviorSubject<Feed> {
		return this._feedData$;
	}

	set feedListResponse(value: FeedListResponse) {
		this._feedListResponse = value;
	}

	get feedListResponse(): FeedListResponse {
		return this._feedListResponse;
	}

	get filter$(): BehaviorSubject<SearchRequest> {
		return this._filter$;
	}

	public getFilter(): SearchRequest {
		return this._filter$.getValue();
	}

	// setting the filter object will trigger a search api call
	public setFilter(value: SearchRequest, updateUrlParams = true) {
		if (updateUrlParams) {
			this.urlService.setSearchUrlParams(value);
		}

		// reset property, because user has changed the filter, the next page turned irrelevant
		this.feedListResponse.isNextPageAuthoritativeForSearch = false;
		this._filter$.next(value) ;

		if (this.hasActiveFilter()) {
			this.search();

			// load suggestions as well
			if (this.getFilter().text && this.getFilter().text.length > 1) {
				this.getSuggestions();
			}
		} else {
			// revert feed back to normal
			this.feedData$.next(undefined);
			this.getFeedFirstPage();

			// scroll to top
			const feedRef = document.getElementById("product-feed");
			if (feedRef) {
				feedRef.scrollTo(0, 0);
			}
		}
	}

	get suggestions$(): BehaviorSubject<SearchSuggestionsResponse> {
		return this._suggestions$;
	}
}
