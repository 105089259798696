import {Component, Input, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";

@Component({
	selector: "app-profile-menu",
	templateUrl: "./profile-menu.component.html",
	styleUrls: ["./profile-menu.component.scss"]
})
export class ProfileMenuComponent implements OnInit {

	@Input()
	public user: User;

	constructor() {
	}

	ngOnInit() {
	}

}
