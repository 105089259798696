import {DatabaseEntity} from "../DatabaseEntity";
import {User} from "../user/User";
import {CommentType} from "../enums/CommentType";
import {Like} from "./Like";

export class Comment extends DatabaseEntity {

	message: string;
	type: CommentType;
	content: string; // id of product, post etc...
	likes?: Array<Partial<Like>>;
	comments?: Array<Partial<Comment>>;
	user: Partial<User>;

}
