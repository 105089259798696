import {EventEmitter, Injectable} from "@angular/core";
import {Content} from "../../../model/content/Content";

@Injectable()
export class UgcReFetchService {

	private _ugcFeedContentChangeEvent = new EventEmitter<Content>();

	constructor() {
	}

	public onUgcContentChange(content: Content) {
		this._ugcFeedContentChangeEvent.emit(content);
	}

	get ugcFeedContentChangeEvent(): EventEmitter<Content> {
		return this._ugcFeedContentChangeEvent;
	}

}
