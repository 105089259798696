import {DatabaseEntity} from "../../DatabaseEntity";
import {User} from "../../user/User";
import {Category} from "../category/Category";
import Decimal from "decimal.js";
import {Like} from "../../social/Like";
import {Share} from "../../../share/Share";
import {Variant} from "./Variant";
import {Comment} from "../../social/Comment";

export class Product extends DatabaseEntity {

	title: string;
	description: string;
	user: Partial<User>;
	brandName: string;
	category: Partial<Category>;
	rating: Decimal;
	numberOfLikes: number;
	numberOfComments: number;
	numberOfShares: number;
	numberOfPurchase: number;
	numberOfVariants: number;
	numberOfCollections: number;
	reviews: number;
	tags: Array<string>;
	charity: Decimal;
	likes?: Array<Partial<Like>>;
	comments?: Array<Partial<Comment>>;
	shares?: Array<Partial<Share>>;
	// media: Array<Partial<Media>>;
	variants?: Array<Partial<Variant>>;

}
