import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {Content} from "../../../model/content/Content";
import {PostService} from "../../services/post/post.service";
import {PermissionLogicService} from "../../../model/services/permission-logic/permission-logic.service";
import {UpdateService} from "../../../commons/services/update/update.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {TranslateService} from "@ngx-translate/core";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";

@Component({
	selector: "app-blogpost-page",
	templateUrl: "./blogpost-page.component.html",
	styleUrls: ["./blogpost-page.component.scss"]
})
export class BlogPostPageComponent implements OnInit, OnDestroy {

	public blogpost: Content;
	private sub;

	public readonly _userAvatarDisplayType = UserAvatarDisplayType.IMAGE_ONLY;

	public isEditMode = true;

	constructor(private route: ActivatedRoute,
				private router: Router,
				private titleService: PageTitleService,
				private location: Location,
				private postServiceRef: PostService,
				private updateService: UpdateService,
				private permissionLogicService: PermissionLogicService,
				private alertService: SweetAlertService,
				private translate: TranslateService,
				public userSessionService: UserSessionService,
				@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	ngOnInit() {
		this.route.data.subscribe((data) => {
			this.blogpost = data.blogpost;

			// reverse media array
			if (this.blogpost && this.blogpost.media && this.blogpost.media.length) {
				this.blogpost.media = this.blogpost.media.reverse();
			}

			this.titleService.setPageTitle(this.blogpost.title);
		});

		this.isEditMode = this.permissionLogicService.isPostEditable(this.blogpost);

		this.userSessionService.userData.subscribe((user) => {
			if (!user) {
				this.isEditMode = false;
			} else {
				this.isEditMode = this.permissionLogicService.isPostEditable(this.blogpost);
			}
		});

		this.sub = this.updateService.contentChangeEvent.subscribe((bp: Content) => {
			this.blogpost = bp;

			if (this.blogpost && this.blogpost.media && this.blogpost.media.length) {
				this.blogpost.media = this.blogpost.media.reverse();
			}

			this.titleService.setPageTitle(this.blogpost.title);
		});
	}

	ngOnDestroy(): void {
		// unsubscribe
		this.sub.unsubscribe();
	}

	backButtonAction() {
		this.location.back();
	}

	public editBlogPostAction() {
		this.postServiceRef.openBlogPostEditModal(this.blogpost);
	}

	deleteBlogPostAction() {
		this.alertService.confirm({title: this.translate.instant("alert.delete")}).then((resp) => {
			if (resp.hasOwnProperty("value") && resp.value) {
				this.api.admin().deleteUgcContent(this.blogpost._id).subscribe(() => {
					this.router.navigate(["/"]).then(() => {
						location.reload();
					});
				});
			}
		});

	}
}
