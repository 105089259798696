import {EventEmitter, Injectable, Output} from "@angular/core";

@Injectable()
export class FeedEventsService {

	@Output()
	private _containerSizeChangeEvent: EventEmitter<number> = new EventEmitter();

	constructor() {
	}


	get containerSizeChangeEvent(): EventEmitter<any> {
		return this._containerSizeChangeEvent;
	}
}
