import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {Router} from "@angular/router";
import {PostService} from "../../services/post/post.service";
import {Content} from "../../../model/content/Content";
import {ContentType} from "../../../model/data/enums/ContentType";
import {mainFeedAnimations} from "../../../commons/animations/mainFeedAnimations";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {PostBackgroundService} from "../../../commons/services/post-background/post-background.service";

@Component({
	selector: "app-conversations-feed-item",
	templateUrl: "./conversations-feed-item.component.html",
	styleUrls: ["./conversations-feed-item.component.scss"],
	animations: [mainFeedAnimations],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationsFeedItemComponent implements OnInit {

	private readonly _avatarMode = UserAvatarDisplayType.NAME_ONLY;

	@Input()
	data: Content;

	@Output()
	public imageLoaded: EventEmitter<void> = new EventEmitter<void>();

	public type: "post" | "microblogpost";

	constructor(private router: Router,
				private postService: PostService,
				private userSessionService: UserSessionService,
				private postBackgroundService: PostBackgroundService) {
	}

	ngOnInit() {
		this.getLastImageIndex();

		switch (this.data.type) {
			case ContentType.MICROBLOGPOST:
				this.type = "microblogpost";
				break;
			case ContentType.POST:
				this.type = "post";
				break;
		}
	}

	get avatarMode(): UserAvatarDisplayType {
		return this._avatarMode;
	}

	public clickAction() {
		if (this.data.type === ContentType.MICROBLOGPOST) {
			this.router.navigate(["blogs", this.data._id]);
		} else {
			if (this.userSessionService.navigation) {
				this.postService.openPostModal(this.data);
			}
		}
	}

	public getLastImageIndex(): number {
		return this.data.media.length - 1;
	}

	public preventNav() {
		this.userSessionService.preventNavigation();
	}

	public getBackground() {
		return this.postBackgroundService.getBackground(this.data.backgroundId);
	}

	public getTrimmedContent() {
		if (this.data.description.length > 100) {
			return this.data.description.substr(0, 100) + "...";
		}
		return this.data.description;
	}

}
