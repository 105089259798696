import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LoginRejectionReason, UserSessionService} from "../../services/user-session/user-session.service";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {Router} from "@angular/router";
import {AuthService, FacebookLoginProvider, SocialUser} from "angularx-social-login";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {
	emailAvailableValidator,
	usernameAvailableValidator,
	usernameCharacters
} from "../../../registration/validators/RegistrationValidators";
import {RegistrationRequest} from "../../../model/request/registration/RegistrationRequest";

@Component({
	selector: "app-login-modal",
	templateUrl: "./login-modal.component.html",
	styleUrls: ["./login-modal.component.scss"]
})
export class LoginModalComponent implements OnInit, AfterViewInit {

	// error message to display
	public errorMessage = null;
	public isLoading = false;
	public loginFormGroup: FormGroup;

	@ViewChild("emailInputField", {static: true})
	private _emailInputField: ElementRef;

	constructor(
		private activeModal: NgbActiveModal,
		private translate: TranslateService,
		private userSessionService: UserSessionService,
		private formBuilder: FormBuilder,
		// private facebook: FacebookService,
		private router: Router,
		private api: ApiCommunicationService,
		public modalServiceProxy: ModalServiceProxy,
		private authService: AuthService
	) {
	}

	ngOnInit() {
		// angular form
		this.loginFormGroup = this.formBuilder.group({
			email: [null, [
				    Validators.required,
				    Validators.email
			    ]
			],
			password: [null, [Validators.required]],
		});


	}

	ngAfterViewInit() {
		// focus on element when modal is shown
		(<HTMLInputElement> this._emailInputField.nativeElement).focus();
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginFormGroup.controls;
	}

	public registrationLinkAction(): void {
		// close this modal
		this.close();
		this.router.navigate(["/registration"]);
	}

	public forgotPasswordLinkAction(): void {
		// close this modal
		this.close();
		this.router.navigate(["/reset-password"]);
	}

	public registerAsMerchantAction(): void {

		// close login modal
		this.close();

		this.router.navigate(["/registration", "merchant"]);
	}

	/**
	 * Login button click action
	 */
	public loginButtonAction(): void {

		// reset error message
		this.errorMessage = null;

		// stop here if form is invalid
		if (this.loginFormGroup.invalid) {
			return;
		}

		// *** At this point, validation was successful, actual login procedure can be called... ***
		this.loginFormGroup.disable();

		// do actual login with User Session Service
		this.userSessionService
			.loginWithCredentialsAction(
				this.loginFormGroup.value.email,
				this.loginFormGroup.value.password
			)
			.then(value => {

				// re-enable form
				this.loginFormGroup.enable();

				// close this modal
				this.activeModal.close();

			}).catch((reason: LoginRejectionReason) => {

				// resolve key or unknown
				const rejectReason = reason || LoginRejectionReason.UNKNOWN;

				// setting the reason
				this.errorMessage = this.translate.instant(rejectReason.toString());

				this.loginFormGroup.enable();

				// focus on email input field
				(<HTMLInputElement>this._emailInputField.nativeElement).focus();

				// reset password field if rejection reason is bad credentials
				if (rejectReason === LoginRejectionReason.BAD_CREDENTIALS) {
					this.loginFormGroup.patchValue({password: ""});
					this.loginFormGroup.markAsPristine();
				}
		});

	}

	public facebookLoginAction(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
			this.userSessionService.loginWithFacebookAction(user).then(() => {
				// close this modal
				this.activeModal.close();
			}).catch((rejectReason) => {
				// setting the reason
				this.errorMessage = this.translate.instant(rejectReason.toString());
			});
		});
	}

	public close(): void {
		this.activeModal.close();
	}

	public showResendButton(): boolean {
		return this.errorMessage.startsWith("Incomplete registration");
	}

	public resendEmailVerificationAction() {
		this.api.profile().requestEmailVerification(this.loginFormGroup.value.email).subscribe(() => {
			this.errorMessage = null;
		});
	}

}
