import {OrderAddressData} from "../../data/checkout/OrderAddressData";
import {Address} from "../../data/checkout/Address";

export class CheckoutPreProcessingRequest implements OrderAddressData {

	accept_pap: boolean;
	is_shipping_same_as_billing: boolean;
	shipping_address?: Address = new Address();
	billing_address: Address = new Address();

	// unused property, just to identify it is a guest buy or not
	isLoggedInUser = false;

	public finalize(): void {
		if (this.is_shipping_same_as_billing) {
			this.shipping_address = this.billing_address;
		}
	}
}
