import {AbstractApiConnector} from "./AbstractApiConnector";
import {CheckoutRequest} from "../request/checkout/CheckoutRequest";
import {Observable} from "rxjs";

export class PaymentApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/payment";

	public checkout(data: CheckoutRequest): Observable<any> {
		return this.apiClient
			.post(this.apiRoute + "/checkout", data);
	}

}
