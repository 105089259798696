import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NavbarComponent} from "./components/navbar/navbar.component";
import {TranslateModule} from "@ngx-translate/core";
import {DrawerComponent} from "./components/drawer/drawer.component";
import {UserModule} from "../user/user.module";
import {RouterModule} from "@angular/router";
import {InlineSVGModule} from "ng-inline-svg";
import {CartMenuComponent} from "./components/cart-menu/cart-menu.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ShopModule} from "../shop/shop.module";
import {MomentModule} from "ngx-moment";
import {AddContentComponent} from "./components/add-content/add-content.component";
import {NotificationModule} from "../notification/notification.module";
import {FormsModule} from "@angular/forms";
import {NgxEditorModule} from "ngx-editor";
import {TextMaskModule} from "angular2-text-mask";
import {FloatingTextSearchComponent} from "./components/floating-text-search/floating-text-search.component";

/**
 * The commons module.
 *
 * Contains common parts parts that are used almost everywhere.
 * E.g. navigation bar belongs here.
 */
@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		UserModule,
		ShopModule,
		RouterModule,
		InlineSVGModule,
		NgbModule,
		MomentModule,
		NotificationModule,
		FormsModule,
		NgxEditorModule,
		TextMaskModule,
	],
	declarations: [
		NavbarComponent,
		DrawerComponent,
		CartMenuComponent,
		AddContentComponent,
		FloatingTextSearchComponent,
	],
	exports: [
		NavbarComponent,
		DrawerComponent,
	]
})
export class CommonsModule {
}
