import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoginModalComponent} from "../../../user/modals/login-modal/login-modal.component";
import {ProfileImageUploadModalComponent} from "../../../my-profile/modals/profile-image-upload-modal/profile-image-upload-modal.component";

@Injectable()
export class ModalService {

	constructor(
		private modalService: NgbModal
	) {
	}

	public showLoginModal(): void {
		this.modalService.open(LoginModalComponent, {size: "lg", windowClass: "modal-xl", backdrop: "static"});
	}

	public showImageUploadModal(): void {
		const modal = this.modalService.open(ProfileImageUploadModalComponent, {size: "lg", windowClass: "modal-xl"});
		(<ProfileImageUploadModalComponent>modal.componentInstance).modalRef = modal;
	}

	public closeLoginModal() {
		this.modalService.dismissAll();
	}

}
