import {Injectable} from "@angular/core";
import * as Swal from "sweetalert2";


@Injectable()
export class SweetAlertService {

	constructor() { }

	swal(args = {}) {
		return Swal.default.fire(args);
	}

	prompt(options: Swal.SweetAlertOptions) {
		const baseOptions = {
			showCancelButton: true,
			confirmButtonText: "Submit",
			input: "text"
		};
		return this.swal((<any>Object).assign({}, baseOptions, options));
	}

	confirm(options: Swal.SweetAlertOptions) {
		const baseOptions = {
			showCancelButton: true,
			confirmButtonText: "Confirm",
			type: "warning"
		};
		return this.swal((<any>Object).assign(baseOptions, options));
	}

	alert(options: Swal.SweetAlertOptions) {
		const baseOptions = {
			confirmButtonText: "OK",
			type: "info"
		};
		return this.swal((<any>Object).assign(baseOptions, options));
	}

	question(options: Swal.SweetAlertOptions) {
		return this.alert((<any>Object).assign({ type: "question" }, options));
	}

	success(options: Swal.SweetAlertOptions) {
		return this.alert((<any>Object).assign({ type: "success" }, options));
	}

	error(options: Swal.SweetAlertOptions) {
		return this.alert((<any>Object).assign({ type: "error" }, options));
	}

	warning(options: Swal.SweetAlertOptions) {
		return this.alert((<any>Object).assign({ type: "warning" }, options));
	}

	info(options: Swal.SweetAlertOptions) {
		return this.alert((<any>Object).assign({ type: "info" }, options));
	}
}
