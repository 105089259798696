import {Component, Input, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";

@Component({
	selector: "app-user-list-display",
	templateUrl: "./user-list-display.component.html",
	styleUrls: ["./user-list-display.component.scss"]
})
export class UserListDisplayComponent implements OnInit {

	@Input()
	public users: Array<User>;

	constructor() {
	}

	ngOnInit() {
	}



}
