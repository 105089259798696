import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {User} from "../../model/data/user/User";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {Observable} from "rxjs";

@Injectable()
export class FollowingResolverService implements Resolve<Array<User>> {

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<User>> {

		// get the user object from parent route
		const user: User = route.parent.data.user;

		return this.api.relationship().getFollowings(user._id);

	}
}
