import {AbstractApiConnector} from "./AbstractApiConnector";
import {Category} from "../data/shop/category/Category";
import {ResponseWrapper} from "../response/wrappers/ResponseWrapper";
import {Observable} from "rxjs";

export class CategoryApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/category";

	public getCategories(): Observable<Array<Category>> {
		return this.apiClient.get(this.apiRoute);
	}

	public getCategory(categoryId: string): Observable<ResponseWrapper<Category>> {
		return this.http
			.get<ResponseWrapper<Category>>(this.apiBaseUrl + this.apiRoute + "/" + categoryId);
	}


}
