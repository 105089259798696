import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {Product} from "../../model/data/shop/product/Product";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {map} from "rxjs/operators";
import {PageTitleService} from "../../commons/services/page-title/page-title.service";
import {Event, NavigationStart, Router} from "@angular/router";

export enum ProductModalState {
	CLOSE = "close",
	OPEN = "open"
}

// https://betterprogramming.pub/create-a-modal-for-your-angular-app-without-libs-671bd7280867
@Injectable()
export class ProductPageModalService {

	private _display: BehaviorSubject<ProductModalState> =
		new BehaviorSubject(ProductModalState.CLOSE);

	private _productData$: BehaviorSubject<Product> = new BehaviorSubject<Product>(undefined);
	private _queryParam = "";

	constructor(private api: ApiCommunicationService,
				private router: Router,
				private titleService: PageTitleService) {
		this.router.events.subscribe((ev: Event) => {
			if (ev instanceof NavigationStart) {
				this.close();
			}
		});
	}

	watch(): Observable<ProductModalState> {
		return this._display.asObservable();
	}

	open(id: string) {
		this.api.product().getProduct(id).pipe(
			map(response => {
				response.variants.map(v => {
					if (v.media && v.media.length && v.media[0].position !== undefined) {
						v.media = v.media.sort((a, b) => {
							if (a.position < b.position) {
								return -1;
							}
							if (a.position > b.position) {
								return 1;
							}
							return 0;
						});
					}
				});

				return response;
			})).subscribe(response => {
			this.setProduct(response);
		}, error => {
				console.error(`Couldn't fetch product ${id}, error=${error}`);
		});
	}

	close() {
		this.setProduct(undefined);
		this.titleService.resetPageTitle();
	}

	private setDisplay(state: ProductModalState) {
		this._display.next(state);
	}

	private setProduct(product: Product) {
		this._productData$.next(product);
		if (product) {
			const href = location.href.split("?");
			if (href && href.length > 1) {
				this._queryParam = href[1];
			}
			window.history.replaceState({}, "", `/product/${product._id}`);
			this.setDisplay(ProductModalState.OPEN);
		} else {
			window.history.replaceState({}, "", this._queryParam.length ? `/?${this._queryParam}` : "");
			this.setDisplay(ProductModalState.CLOSE);
		}
	}

	get productData$(): BehaviorSubject<Product> {
		return this._productData$;
	}


}
