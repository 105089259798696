import {Content} from "../../content/Content";

export class PostRequest {
	description: string;
	backgroundId: number;

	public static fromPost(post: Content): PostRequest {
		return {
			description: post.description,
			backgroundId: post.backgroundId
		};
	}

}
