import {Component, Input, OnInit} from "@angular/core";
import {CommentsService} from "../../services/comments/comments.service";
import {AbstractSocialButton} from "./abstract-social-button";
import {nullAnimation} from "../../animations/socialIconsStateChangeAnimation";
import {Router} from "@angular/router";
import {PostService} from "../../services/post/post.service";
import {Content} from "../../../model/content/Content";

@Component({
	selector: "app-social-button-comment",
	templateUrl: "./abstract-social-button.html",
	styleUrls: ["./abstract-social-button.scss"],
	providers: [
		CommentsService
	],
	animations: [
		nullAnimation
	]
})
export class SocialButtonCommentComponent extends AbstractSocialButton implements OnInit {

	@Input()
	public item: Content;

	@Input()
	public title: string;

	@Input()
	public type: "product" | "post" | "microblogpost" | "event" = "product";

	constructor(private commentsService: CommentsService,
				private router: Router,
				private postService: PostService) {
		super("comment", "lovegivr icon lov-speech-bubble");
	}

	ngOnInit() {

		// init number to display
		if (this.item) {
			this._displayedNumber = this.item.numberOfComments;
		}
	}

	clickAction(): void {
		if (this.item) {
			if (this.type === "post") {
				this.postService.openPostModal(this.item);
			}

			if (this.type === "microblogpost") {
				this.router.navigate(["blogpost", this.item._id]);
			}
		}
	}

	get displayedNumber(): number {
		return this.item.numberOfComments;
	}
}
