import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: "app-user-following",
	templateUrl: "./user-following.component.html",
	styleUrls: ["./user-following.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFollowingComponent implements OnInit {

	public username: string;

	public followings: Array<User>;

	constructor(@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
				private route: ActivatedRoute) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe( (data) => {

			this.username = data.username;
			this.followings = data.followings;

			this.changeDetector.detectChanges();
		});



	}

}
