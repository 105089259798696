import {DatabaseEntity} from "../DatabaseEntity";
import {Gender} from "../enums/Gender";
import {Role} from "../enums/Role";
import {UserStatus} from "../enums/UserStatus";
import {Interest} from "../enums/Interest";
import {Media} from "../../media/Media";

export class User extends DatabaseEntity {

	displayName: string;
	userName: string;
	email: string;
	bio?: string;
	gender: Gender;
	interestedIn?: Interest;
	role: Role;
	status: UserStatus;
	verified: boolean;
	website?: string;
	profileImage?: Partial<Media>;
	coverImage?: Partial<Media>;

}
