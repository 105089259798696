import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ContentType} from "../../../model/data/enums/ContentType";
import {BehaviorSubject, Subscription} from "rxjs";

import {SearchRequest} from "../../../model/request/search/SearchRequest";

@Component({
	selector: "app-type-selector",
	templateUrl: "./type-selector.component.html",
	styleUrls: ["./type-selector.component.scss"]
})
export class TypeSelectorComponent implements OnInit, OnDestroy {

	// types selectable
	public readonly typeProduct = ContentType.PRODUCT;
	public readonly typePost = ContentType.POST;
	public readonly typeBlog = ContentType.MICROBLOGPOST;
	public readonly typeEvent = ContentType.EVENT;

	public types = [ContentType.POST, ContentType.EVENT, ContentType.PRODUCT, ContentType.MICROBLOGPOST];
	public selectedTypes: Array<ContentType> = [];

	private filterSubscription: Subscription;

	@Output("onChange")
	public onChange = new EventEmitter<Array<ContentType>>();

	@Input("currentFilter")
	public currentFilter: BehaviorSubject<SearchRequest> = new BehaviorSubject<SearchRequest>(undefined);

	@Input("clearAction")
	public clearAction = () => {};

	constructor(private changeDetector: ChangeDetectorRef) {
	}

	public ngOnInit(): void {
		this.filterSubscription = this.currentFilter.subscribe(f => {
			this.selectedTypes = f && f.types ? f.types : [];
			this.changeDetector.detectChanges();
		});
	}

	public ngOnDestroy(): void {
		this.filterSubscription.unsubscribe();
	}

	public typeSelected(type: ContentType) {
		this.selectedTypes = [type];
		this.onChange.emit(this.selectedTypes);
	}

	public isSelected(type: ContentType) {
		return this.selectedTypes.indexOf(type) !== -1;
	}

	public clearClicked() {
		this.selectedTypes = [];
		this.onChange.emit(this.selectedTypes);
		this.clearAction();
	}

}
