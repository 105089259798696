import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {CollectionListResponse} from "../../../model/response/CollectionListResponse";
import {CreateCollectionRequest} from "../../../model/request/collection/CreateCollectionRequest";
import {Collection} from "../../../model/data/collection/Collection";
import {CollectionType} from "../../../model/data/enums/CollectionType";
import {Observable, of} from "rxjs";
import {Product} from "../../../model/data/shop/product/Product";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";

@Component({
	selector: "app-user-collections",
	templateUrl: "./user-collections.component.html",
	styleUrls: ["./user-collections.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCollectionsComponent implements OnInit {

	public username = "";

	// active collection
	public collections: CollectionListResponse = null;

	public writePermission = false;

	public createCollectionRequest = new CreateCollectionRequest();

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private changeDetector: ChangeDetectorRef,
		private api: ApiCommunicationService,
		private sweetAlertService: SweetAlertService,
		private userSessionService: UserSessionService
	) {
	}

	ngOnInit() {
		this.createCollectionRequest.type = CollectionType.WISHLIST;

		// get product from api
		this.route.data.subscribe((data) => {

			this.username = data.username;
			this.collections = data.collections;

			this.changeDetector.detectChanges();

		});

		this.userSessionService.userData.subscribe((user) => {
			if (!user) {
				this.writePermission = false;
			} else {
				if (this.username === user.userName) {
					this.writePermission = true;
				}
			}
		});

		this.route.parent.data.subscribe((parentData) => {
			this.writePermission = parentData.writePermission;
			this.changeDetector.detectChanges();
		});

	}

	public createCollection() {
		this.sweetAlertService.prompt({title: "Please enter the name of the collection"}).then((response: any) => {
			const name: string = response.value;

			if (!name || name.trim() === "") {
				return;
			}

			this.createCollectionRequest.name = name;

			this.api.collection().createCollection(this.createCollectionRequest).subscribe((resp: Collection) => {
					this.collections.docs.push(resp);
					this.changeDetector.detectChanges();
			});

		});
	}

	public deleteCollectionAction(event: MouseEvent, id) {
		this.sweetAlertService.confirm({title: "Are you sure to delete this collection?"}).then((response) => {
			if (response.hasOwnProperty("value") && response.value) {
				event.preventDefault();
				event.stopPropagation();

					this.api.collection().deleteCollection(id).subscribe(() => {
							this.collections.docs = this.collections.docs.filter(obj => obj._id !== id);
							this.changeDetector.detectChanges();
						});

			}
		});
	}

	public getProductsAsObservable(collectionId: string): Observable<Array<Product>> {
		// @ts-ignore
		return of(this.collections.docs.find(c => c._id === collectionId).products);
	}
}
