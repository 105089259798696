import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {Router} from "@angular/router";
import {Role} from "../../../model/data/enums/Role";

export enum UserAvatarDisplayType {
	NAME_ONLY = "display-name-only",
	IMAGE_ONLY = "display-image-only",
	NAME_NEXT_TO_IMAGE = "display-name-next-to-image",
	NAME_UNDER_IMAGE = "display-name-under-image"
}

@Component({
	selector: "app-user-avatar",
	templateUrl: "./user-avatar.component.html",
	styleUrls: ["./user-avatar.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnInit {

	@Input()
	public userObject: User;

	@Input()
	public displayType: UserAvatarDisplayType | string = UserAvatarDisplayType.NAME_UNDER_IMAGE;

	@Input()
	public nameOverride: String;

	@Input()
	public navigationPath: string;

	@Input()
	public displayAtSymbol = true;

	@Input()
	public preventClickAction = false;

	constructor(private router: Router) {
	}

	ngOnInit() {
	}

	public getUserName(): string {
		if (this.userObject == null) {
			return "bnana_joe";
		} else {
			// return this.userObject.userName;
			return this.userObject.displayName;
		}
	}

	public onClick() {
		if (!this.preventClickAction) {
			const path: string[] = ["/user", this.getUserName(), this.userObject._id];
			if (this.navigationPath) {
				path.push(this.navigationPath);
			} else {
				if (this.userObject.role === Role.MERCHANT) {
					path.push("shop");
				} else {
					path.push("conversations");
				}
			}

			this.router.navigate(path);
		}
	}

}
