import {Injectable} from "@angular/core";
import {CheckoutPreProcessingRequest} from "../../model/request/checkout/CheckoutPreProcessingRequest";
import {CheckoutRequest} from "../../model/request/checkout/CheckoutRequest";
import {AddToCartRequest} from "../../model/request/cart/AddToCartRequest";

@Injectable()
export class ShippingInfoService {

	private static readonly TMP_SHIPPING_INFO = "tmpShippingInfo";
	private static readonly TMP_USER = "tmpUser";
	private static readonly CART = "cart";
	private static readonly CART_REQUEST = "cartRequest";

	constructor() {
	}

	static setShippingInfo(value: CheckoutPreProcessingRequest, isLoggedInUser: boolean) {
		localStorage.setItem(this.TMP_SHIPPING_INFO, JSON.stringify(value));
		localStorage.setItem(this.TMP_USER, JSON.stringify(isLoggedInUser));
	}

	public static getShippingInfo(): CheckoutRequest {
		const rv = new CheckoutRequest();
		const shippingInfo = <CheckoutPreProcessingRequest>JSON.parse(localStorage.getItem(this.TMP_SHIPPING_INFO));
		const isUserLoggedIn: boolean = JSON.parse(localStorage.getItem(this.TMP_USER));

		if (shippingInfo.is_shipping_same_as_billing) {
			rv.shippingSameAsBilling = shippingInfo.is_shipping_same_as_billing;
		} else {
			rv.shippingSameAsBilling = false;
		}

		rv.acceptedTC = shippingInfo.accept_pap;

		rv.customer = {
			fullName: shippingInfo.billing_address.fullName,
			email: shippingInfo.billing_address.email_address,
			phone: shippingInfo.billing_address.phone
		};

		rv.billingAddress = {
			address: shippingInfo.billing_address.address,
			city: shippingInfo.billing_address.city,
			country: shippingInfo.billing_address.country,
			province: shippingInfo.billing_address.province,
			zip: shippingInfo.billing_address.zip
		};

		rv.shippingAddress = {
			address: shippingInfo.shipping_address.address,
			city: shippingInfo.shipping_address.city,
			country: shippingInfo.shipping_address.country,
			province: shippingInfo.shipping_address.province,
			zip: shippingInfo.shipping_address.zip
		};

		// cart has to be set // guest user buy
		if (!isUserLoggedIn) {
			const guestUserCart: AddToCartRequest = JSON.parse(localStorage.getItem("cartRequest"));

			rv.cart = {
				items: guestUserCart.items
			};

			if (guestUserCart.charityAccout) {
				rv.cart.charityAccount = guestUserCart.charityAccout;
			}

			rv.cart.items = guestUserCart.items;
		}

		return rv;
	}

	public static clearLocalStorage() {
		localStorage.removeItem(this.TMP_USER);
		localStorage.removeItem(this.TMP_SHIPPING_INFO);
		localStorage.removeItem(this.CART_REQUEST);
		localStorage.removeItem(this.CART);
	}

}
