import {EventEmitter, Injectable} from "@angular/core";
import {Content} from "../../../model/content/Content";

@Injectable()
export class UpdateService {

	// emitters
	private _contentChangeEvent = new EventEmitter<Content>();

	constructor() {
	}

	public updateBlogPost(bp: Content) {
		this._contentChangeEvent.emit(bp);
	}

	public updatePost(p: Content) {
		this._contentChangeEvent.emit(p);
	}

	public updateEvent(e: Content) {
		this._contentChangeEvent.emit(e);
	}

	get contentChangeEvent(): EventEmitter<Content> {
		return this._contentChangeEvent;
	}

}
