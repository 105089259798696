import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {FeedListResponse} from "../response/FeedListResponse";

export class FeedApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/feed";

	// Product feeds
	public getFeed(pageNumber): Observable<FeedListResponse> {
		return this.apiClient.get(this.apiRoute + "/" + pageNumber);
	}

}
