import {Component, Input, OnInit} from "@angular/core";
import {PostService} from "../../../social/services/post/post.service";
import {EventService} from "../../../social/services/event/event.service";
import {Router} from "@angular/router";

@Component({
	selector: "app-add-content",
	templateUrl: "./add-content.component.html",
	styleUrls: ["./add-content.component.scss"]
})
export class AddContentComponent implements OnInit {

	@Input()
	public placement = "bottom-right";

	constructor(public postService: PostService,
				public eventService: EventService,
	            public router: Router) {
	}

	ngOnInit() {
	}

	public createBlogPost() {
		this.router.navigate(["/blogs/manage/new"]);
	}

}
