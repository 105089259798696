import {Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {Category} from "../../../model/data/shop/category/Category";
import {CategoryLevel} from "../../../model/data/enums/CategoryLevel";

@Injectable({
	providedIn: "root"
})
export class CategoryService {

	private _categories: Array<Category>;

	constructor(private api: ApiCommunicationService) {

		this.api.category().getCategories().subscribe((c) => {
			this._categories = c;
		});
	}

	public getCategory(id: string): Category {
		return this!._categories.find(c => c._id === id);
	}

	public getCategoryDeep(id: string, categories?: Array<Category>): Category {
		const searchCategories = categories || this!._categories;

		if (!searchCategories)
			return null;

		const directMatch = searchCategories!.find(c => c._id === id);

		if (directMatch)
			return directMatch;

		// recursive version
		for (let cat of searchCategories) {
			if (cat.subCategories) {
				const subMatch = this.getCategoryDeep(id, cat.subCategories);
				if (subMatch)
					return subMatch
			}
		}

		return null;
	}

	public getCategoryTree(category: Category): Category {
		switch (category.level) {
			case CategoryLevel.MAIN - 1:
				return this.categories.find(c => c._id === category._id);
			case CategoryLevel.SUB - 1:
				return this.categories.find(c => c.subCategories.some(sc => sc._id === category._id));
			case CategoryLevel.SUB_OF_SUB - 1:
				return this.categories.find(c => c.subCategories.find(sc => sc.subCategories.some(ssc => ssc._id === category._id)));
			default:
				return null;
		}
	}

	get categories(): Array<Category> {
		return this._categories;
	}

}
