import {ChangeDetectionStrategy, Component, Inject, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";

@Component({
	selector: "app-profile-page",
	templateUrl: "./profile-page.component.html",
	styleUrls: ["./profile-page.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePageComponent implements OnInit {

	public user: User;

	constructor(@Inject(UserSessionService) private userSessionService: UserSessionService) {
	}

	ngOnInit() {
		this.userSessionService.userData.subscribe((u) => {
			this.user = u;
		});
	}

}
