import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from "@angular/core";
import {Content} from "../../../../../model/content/Content";

@Component({
	selector: "app-post-modal-gallery",
	templateUrl: "./post-modal-gallery.component.html",
	styleUrls: ["./post-modal-gallery.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostModalGalleryComponent implements OnInit {

	@Input()
	public post: Content;

	public videos: Array<string>;
	public images: Array<string>;

	constructor(@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.initMedia();
	}

	private initMedia() {
		if (this.post.media && this.post.media.length) {

			// go through media items
			for (let i = this.post.media.length - 1; i >= 0; i--) {
				// if it has url than it is a video
				if (this.post.media[i].url) {
					if (!this.videos) {
						this.videos = [];
					}
					this.videos.push(this.post.media[i].url);

					// if urlLarge is present then it is an image
				} else if (this.post.media[i].urlLarge) {
					if (!this.images) {
						this.images = [];
					}
					this.images.push(this.post.media[i].urlLarge);
				}

			}

			this.changeDetector.detectChanges();
		}
	}

}
