import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";

export class AdminApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/admin";

	public deleteUgcContent(contentId: string): Observable<any> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/ugc/${contentId}`);
	}

}
