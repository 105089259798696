import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Product} from "../../../model/data/shop/product/Product";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
	selector: "app-merchant-offers",
	templateUrl: "./merchant-offers.component.html",
	styleUrls: ["./merchant-offers.component.scss"]
})
export class MerchantOffersComponent implements OnInit {

	public username: string;
	public _offers: BehaviorSubject<Array<Product>> = new BehaviorSubject([]);

	constructor(private route: ActivatedRoute,
				private location: Location,
				private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe( (data) => {

			this.username = data.username;
			this._offers.next(data.offers.docs);

			this.changeDetector.detectChanges();

		});

	}

	get offers(): Observable<Array<Product>> {
		return this._offers.asObservable();
	}

}
