import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {Product} from "../../../model/data/shop/product/Product";
import {BehaviorSubject} from "rxjs";

@Component({
	selector: "app-merchant-shop",
	templateUrl: "./merchant-shop.component.html",
	styleUrls: ["./merchant-shop.component.scss"]
})
export class MerchantShopComponent implements OnInit {

	public username: string;
	public _products: BehaviorSubject<Array<Product>> = new BehaviorSubject([]);
	

	constructor(private route: ActivatedRoute,
				private location: Location,
				private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe( (data: any) => {

			this.username = data.username;
			this._products.next(data.products);
			this.changeDetector.detectChanges();

		});

	}


}
