import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";

export const commentEntryAnimation =

	trigger("commentEntryAnimation", [
		state("in", style({transform: "translateY(0)"})),
		transition("void => *", [
			animate("800ms ease", keyframes([
				style({opacity: 0, transform: "translateY(100%)", height: 0, offset: 0}),
				style({opacity: 1, transform: "translateY(-15px)", height: "*",  offset: 0.5}),
				style({opacity: 1, transform: "translateY(0)", height: "*",     offset: 1.0})
			]))
		]),
		transition("* => void", [
			animate("800ms ease", keyframes([
				style({opacity: 1, transform: "translateY(0)",     offset: 0}),
				style({opacity: 1, transform: "translateY(15px)", offset: 0.5}),
				style({opacity: 0, transform: "translateY(-100%)",  offset: 1.0})
			]))
		])
	]);
