import {AfterViewInit, Component, OnInit} from "@angular/core";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {ModalServiceProxy} from "../../services/modal/modal.service.proxy";
import {NavigationEnd, Router} from "@angular/router";
import {Role} from "../../../model/data/enums/Role";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";

/**
 * Interface defining menu item.
 */
interface MenuItem {
	title: string;
	icon: string;
	action: Function;
}

@Component({
	selector: "app-drawer",
	templateUrl: "./drawer.component.html",
	styleUrls: ["./drawer.component.scss"]
})
export class DrawerComponent implements OnInit, AfterViewInit {

	// display options for components
	public _userAvatarDisplayType = UserAvatarDisplayType.NAME_UNDER_IMAGE;

	// logged in menu items
	private _loggedInMenuItems: Array<MenuItem> = [
		{
			title: "account.my-profile",
			icon: "fi fi-sr-user",
			action: () => this.profileAction()
		},
		// {
		// 	title: "profile.user-generated-content.collections",
		// 	icon: "las la-store",
		// 	action: () => this.collectionsAction()
		// },
		// {
		// 	title: "account.settings",
		// 	icon: "las la-cog",
		// 	action: () => this.profileAction()
		// },
		{
			title: "commons.terms-and-policies.title",
			icon: "fi fi-ss-book-alt",
			action: () => this.termsAndPoliciesAction()
		},
		{
			title: "commons.drawer.charity",
			icon: "fi fi-sr-hand-holding-heart",
			action: () => this.charityAction()
		},
		{
			title: "account.sign-out",
			icon: "fi fi-bs-sign-out-alt",
			action: () => this.logoutAction()
		}
	];

	private _loggedOutMenuItems: Array<MenuItem> = [
		{
			title: "account.sign-in",
			icon: "fi fi-bs-enter",
			action: () => this.loginAction()
		},
		{
			title: "Sign up",
			icon: "fi fi-sr-user-add",
			action: () => this.registerAction()
		},
		{
			title: "commons.drawer.register-as-merchant",
			icon: "fi fi-ss-store-alt",
			action: () => this.registerAsMerchantAction()
		},
		{
			title: "commons.terms-and-policies.title",
			icon: "fi fi-ss-book-alt",
			action: () => this.termsAndPoliciesAction()
		},
		{
			title: "commons.drawer.charity",
			icon: "fi fi-sr-hand-holding-heart",
			action: () => this.charityAction()
		}
	];

	// toggle for menu
	private _isActive = false;

	constructor(
		private modalServiceProxy: ModalServiceProxy,
		private _userSessionService: UserSessionService,
		private router: Router
	) {
	}

	ngOnInit() {

		// close drawer on navigation
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this._isActive) {
					this.setActive(false);
				}
			}
		});

	}

	ngAfterViewInit() {
	}

	public collectionsAction() {
		this.setActive(false);
		const user = this._userSessionService.userDataValue;
		if (user.role !== Role.MERCHANT) {
			this.router.navigate(["/user", user.userName, user._id, "collections"]);
		} else {
			this.router.navigate(["/user", user.userName, user._id, "shop"]);
		}
	}

	public profileAction() {
		this.setActive(false);
		this.router.navigate(["/profile"]);
	}

	public loginAction() {
		this.setActive(false);
		this.modalServiceProxy.get().showLoginModal();
	}

	public registerAction() {
		this.setActive(false);
		this.router.navigate(["/registration"]);
	}

	public registerAsMerchantAction() {
		this.setActive(false);
		this.router.navigate(["/registration/merchant"]);
	}

	public charityAction() {
		this.setActive(false);
		this.router.navigate(["/charities"]);
	}


	public logoutAction() {
		this.setActive(false);
		this._userSessionService.logoutAction();
	}

	public termsAndPoliciesAction() {
		this.setActive(false);
		this.router.navigate(["/information"]);
	}

	public toggleDrawer() {
		this._isActive = !this._isActive;
	}

	public setActive(active: boolean) {
		this._isActive = active;
	}

	get isActive(): boolean {
		return this._isActive;
	}

	get loggedInMenuItems(): Array<MenuItem> {
		return this._loggedInMenuItems;
	}

	get loggedOutMenuItems(): Array<MenuItem> {
		return this._loggedOutMenuItems;
	}

	get userSessionService(): UserSessionService {
		return this._userSessionService;
	}
}
