import {Inject, Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {Observable, BehaviorSubject} from "rxjs";
import {CachedObject} from "../../../model/data/cache/CachedObject";

@Injectable()
export class TagsDataService {

	private storedTags: BehaviorSubject<any> = new BehaviorSubject<CachedObject<any>>(null);

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	// TODO
	public getTopTags(): Observable<CachedObject<any>> {
		return null;
	}

}
