import {Component, OnInit} from "@angular/core";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {ActivatedRoute} from "@angular/router";
import {Charity} from "../../../model/data/charity/Charity";

@Component({
	selector: "app-charity-page",
	templateUrl: "./charity-page.component.html",
	styleUrls: ["./charity-page.component.scss"]
})
export class CharityPageComponent implements OnInit {

	public charities: Array<any>;
	public total: number;

	constructor(private titleService: PageTitleService,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		// set page title
		this.titleService.setPageTitle("Charities");

		// resolve route data
		this.route.data.subscribe((data) => {
			// set charities
			this.charities = data.charities.charities;
			this.total = data.charities.total;
		});
	}

}
