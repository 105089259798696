import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {MicroBlogPostRequest} from "../../../model/request/microblogpost/MicroBlogPostRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {Router} from "@angular/router";
import {Content} from "../../../model/content/Content";
import {NGX_EDITOR_CONFIG} from "../../config/ngx-editor-config";

@Component({
	selector: "app-create-blogpost-modal",
	templateUrl: "./create-blogpost-modal.component.html",
	styleUrls: ["./create-blogpost-modal.component.scss"]
})
export class CreateBlogpostModalComponent implements OnInit {

	public request: MicroBlogPostRequest = new MicroBlogPostRequest();
	public isLoading = false;
	public modalRef: NgbModalRef;

	public ngxEditorConfig = NGX_EDITOR_CONFIG;

	@ViewChild("fileInput", { static: true })
	public fileInput: ElementRef;

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService,
				private router: Router) {
	}

	ngOnInit() {
	}

	createPostAction(): void {
		this.isLoading = true;

		if (!this.request.title ||
			!this.request.text ||
			!(<HTMLInputElement>this.fileInput.nativeElement).files.length
		) {
			this.isLoading = false;
			this.alertService.error({title: `Could not create your blog post. <br> Title, description or image(s) are missing.`});
			return;
		}

	}

}
