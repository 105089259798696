import Decimal from "decimal.js";
import {Content} from "../../content/Content";

export class EventRequest {

	title?: string;
	description?: string;
	startDate?: Date;
	endDate?: Date;
	genreOfEvent?: string;
	location?: string;
	price?: number;
	linkToBookingSite?: string;

	// external link to the event
	link?: string;

	public static fromEvent(event: Content) {
		const rv = new EventRequest();

		if (event.info.type === "EXTERNAL") {
			rv.link = event.info.link;
		} else {
			rv.title = event.title;
			rv.description = event.description;
			rv.startDate = new Date(event.info.startDate);
			rv.endDate = new Date(event.info.endDate);
			rv.genreOfEvent = event.info.genreOfEvent;
			rv.location = event.info.location;
			rv.price = event.info.priceMax;
			rv.linkToBookingSite = event.info.linkToBookingSite;
		}

		return rv;
	}

}
