import {Injectable, InjectionToken, Injector} from "@angular/core";

// Injection token
	export const MODAL_SERVICE_TOKEN = new InjectionToken("ModalService");

@Injectable()
export class ModalServiceProxy {

	constructor(
		private injector: Injector
	) {
	}

	public get(): any {
		return this.injector.get(MODAL_SERVICE_TOKEN);
	}


}
