import {CurrencyType} from "../../enums/CurrencyType";
import Decimal from "decimal.js";
import {DatabaseEntity} from "../../DatabaseEntity";
import {User} from "../../user/User";

export class CartItem extends DatabaseEntity {

	fee: {
		shipping: number;
		transactionFee: number;
	};

	totalShippingPrice: number;
	totalCharity: number;
	totalAgreedTransactionFee: number;
	totalOfferedPrice: number;
	totalPrice: number;
	cartItemGroupId: string;
	merchant: Partial<User>;

	items: [{
		productImage: string;
		variantId: string;
		productId: string;
		productName: string;
		brandName: string;
		rating: Decimal;
		charity: Decimal;
		variantName: string;
		sku: string;
		specs: any;
		currency: CurrencyType;
		price: Decimal;
		totalPrice: Decimal;
		totalOfferedPrice: Decimal;
		shippingPrice: Decimal;
		totalShippingPrice: Decimal;
		discount: Decimal;
		quantity: number;
		merchant: Partial<User>;
	}];

}
