import {catchError, map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {User} from "../../model/data/user/User";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";

@Injectable()
export class UserResolverService implements Resolve<User> {

	constructor(private api: ApiCommunicationService,
				private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Promise<User> | User {
		return this.api.profile().getProfile(route.params["userId"]).pipe(
			map((response: User) => response), catchError((err) => {

				// error message
				console.error("Could not load user" + route.params["username"], err);

				// navigate to home
				this.router.navigateByUrl("/").then();

				// reject promise
				return new Promise<User>((resole, reject) => {
					reject();
				});

			}), );
	}
}
