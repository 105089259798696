import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {OrderListResponse} from "../../../model/response/OrderListResponse";
import {Order} from "../../../model/data/checkout/Order";
import {OrderStatus} from "../../../model/data/enums/OrderStatus";

@Component({
	selector: "app-profile-orders",
	templateUrl: "./profile-orders.component.html",
	styleUrls: ["./profile-orders.component.scss"]
})
export class ProfileOrdersComponent implements OnInit {

	orders: Array<Order>;

	constructor(private api: ApiCommunicationService,
	            private cd: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.api.order().getCustomerOrder(1).subscribe((resp: OrderListResponse) => {
			this.orders = resp.docs;
			this.cd.detectChanges();
		});
	}

	public mapOrderStatus(s: OrderStatus) {
		switch (s) {
			case OrderStatus.AWAITING_SHIPMENT: return "Awaiting shipment";
			case OrderStatus.PARTIALLY_DISPATCHED: return "Partially dispatched";
			case OrderStatus.COMPLETED: return "Completed";
			case OrderStatus.DISPATCHED: return "Dispatched";
			case OrderStatus.CANCELLED: return "Awaiting fulfilment";
			case OrderStatus.DECLINED: return "Declined";
			case OrderStatus.REFUNDED: return "Refunded";
			case OrderStatus.PARTIALLY_REFUNDED: return "Partially refunded";
			case OrderStatus.AWAITING_FULFILMENT: return "Awaiting fulfilment";
			case OrderStatus.AWAITING_PAYMENT: return "Awaiting fulfilment";

		}
	}

}
