import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {CategoryDescriptor} from "../../../model/data/shop/category/CategoryDescriptor";
import {Category} from "../../../model/data/shop/category/Category";
import {BehaviorSubject, Subscription} from "rxjs";
import {CategoryService} from "../../../commons/services/category/category.service";
import {SearchRequest} from "../../../model/request/search/SearchRequest";

@Component({
	selector: "app-category-selector",
	templateUrl: "./category-selector.component.html",
	styleUrls: ["./category-selector.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategorySelectorComponent implements OnInit, OnDestroy {

	@Input("categories")
	public input: Array<Category>;

	@Input("currentFilter")
	public currentFilter: BehaviorSubject<SearchRequest> = new BehaviorSubject<SearchRequest>(undefined);

	// active categories
	private _activeCategories: Array<any>;

	@Output("onChange")
	public categories = new EventEmitter<Array<string>>();
	public categoriesContainer: any = {};

	private default: CategoryDescriptor;

	private filterSubscription: Subscription;

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				private changeDetector: ChangeDetectorRef,
				private categoryService: CategoryService) {
		this._activeCategories = [];
	}

	ngOnDestroy(): void {
		this.filterSubscription.unsubscribe();
	}

	ngOnInit() {
		this.filterSubscription = this.currentFilter.subscribe(f => {
			this.init(f);
		});
	}

	public init(f) {
		// create parent
		this.default = {
			_id: null,
			name: null,
			subCategories: this.input,
			checked: false
		};

		// reset active categories
		this._activeCategories = [];
		this.categoriesContainer = {};
		// set first category as active
		this._activeCategories.push(this.default);

		const local = f;
		if (local && local.category) {
			this._activeCategories.unshift(this.categoryService.getCategory(local.category));
			this.categoriesContainer = Object.assign(this.categoriesContainer, {"category": local.category});
			if (local.subCategory) {
				this._activeCategories.unshift(this.activeCategories[0].subCategories.find(c => c._id === local.subCategory));
				this.categoriesContainer = Object.assign(this.categoriesContainer, {"subCategory": local.subCategory});
				if (local.subSubCategory) {
					this._activeCategories.unshift(this.activeCategories[0].subCategories.find(c => c._id === local.subSubCategory));
					this.categoriesContainer = Object.assign(this.categoriesContainer, {"subSubCategory": local.subSubCategory});
				}
			}
		}

		this.changeDetector.detectChanges();
	}

	public activateSubCategory(subCategory: CategoryDescriptor): void {

		// @ts-ignore
		switch (subCategory.level) {
			case 0:
				this.categoriesContainer = Object.assign(this.categoriesContainer, {"category": subCategory._id});
				break;
			case 1:
				this.categoriesContainer = Object.assign(this.categoriesContainer, {"subCategory": subCategory._id});
				break;
			case 2:
				this.categoriesContainer = Object.assign(this.categoriesContainer, {"subSubCategory": subCategory._id});
				break;
		}

		// emit search
		this.categories.emit(this.categoriesContainer);

		this.changeDetector.detectChanges();
	}

	public goToParentCategory(cat: any): void {
		switch (cat.level) {
			case 0:
				delete this.categoriesContainer.category;
				delete this.categoriesContainer.subCategory;
				delete this.categoriesContainer.subSubCategory;

				// reset active categories
				this._activeCategories = [];
				// set this category as active
				this._activeCategories.unshift(this.default);

				break;
			case 1:
				delete this.categoriesContainer.subCategory;
				delete this.categoriesContainer.subSubCategory;

				this._activeCategories.shift();
				// shift only if have an active subSubCategory
				if (this.activeCategories.length >= 3) {
					this._activeCategories.shift();
				}

				break;
			case 2:
				delete this.categoriesContainer.subSubCategory;

				this._activeCategories.shift();

				break;
		}

		// emit search
		this.categories.emit(this.categoriesContainer);

		this.changeDetector.detectChanges();
	}

	get activeCategories(): Array<any> {
		return this._activeCategories;
	}

}
