import {EventEmitter, Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SubmitProductRatingModalComponent} from "../modals/submit-product-rating-modal/submit-product-rating-modal.component";
import {Product} from "../../model/data/shop/product/Product";
import {ProductRatingsModalComponent} from "../modals/product-ratings-modal/product-ratings-modal.component";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../user/services/user-session/user-session.service";
import {ReviewListResponse} from "../../model/response/ReviewListResponse";
import {Review} from "../../model/review/Review";
import {User} from "../../model/data/user/User";

@Injectable({
	providedIn: "root"
})
export class ProductRatingsService {

	public onReview = new EventEmitter<void>();

	constructor(private modalService: NgbModal,
				private api: ApiCommunicationService,
				private userSessionService: UserSessionService) {
	}

	public openProductRateSubmitModal(reviews: ReviewListResponse, rating?: number, product?: Product) {

		// open ratings list modal if no user is logged in
		if (!this.userSessionService.isLoggedInValue) {
			this.openProductRatingModal(reviews);
			return;
		}

		// check eligibility
		this.api.review().checkEligibility(product._id)
			// open modal if rating is eligible
			.subscribe((response) => {

				// TODO RR
				// response.success
				if (true) {
					// open modal
					const modalRef = this.modalService.open(SubmitProductRatingModalComponent);

					// set product
					(<SubmitProductRatingModalComponent>modalRef.componentInstance).productId = product._id;
					(<SubmitProductRatingModalComponent>modalRef.componentInstance).productName = product.title;

					const loggedInUser: User = this.userSessionService.userDataValue;
					const isUserReviewed = reviews.docs.some((review: Review) => review.user._id === loggedInUser._id);
					// assign previous rating if already exists
					if (isUserReviewed) {
						(<SubmitProductRatingModalComponent>modalRef.componentInstance).review =
							reviews.docs.find((review: Review) => review.user._id === loggedInUser._id);
					}

					// set rating if needed
					if (rating) {
						(<SubmitProductRatingModalComponent>modalRef.componentInstance).review.rating = rating;
					}

					// subscribe to close action
					(<SubmitProductRatingModalComponent>modalRef.componentInstance).closeAction.subscribe(() => {
						modalRef.close();
						this.onReview.emit();
					});
				} else {
					// open ratings list modal if user is not eligible to rate the product
					this.openProductRatingModal(reviews);
				}

			});
	}

	public openProductRatingModal(reviews?: ReviewListResponse) {
		const modalRef = this.modalService.open(ProductRatingsModalComponent);

		// set fields
		(<ProductRatingsModalComponent>modalRef.componentInstance).reviews = reviews;

		// subscribe to edit action
		(<ProductRatingsModalComponent>modalRef.componentInstance).openEditModalEvent.subscribe(() => {

			modalRef.close();

			this.openProductRateSubmitModal(reviews);

		});
	}

}
