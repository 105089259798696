import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ForgotPasswordRequest} from "../../../model/request/user/ForgotPasswordRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {TranslateService} from "@ngx-translate/core";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";

@Component({
	selector: "app-forgot-password-page",
	templateUrl: "./forgot-password-page.component.html",
	styleUrls: ["./forgot-password-page.component.scss"]
})
export class ForgotPasswordPageComponent implements OnInit, AfterViewInit {

	@ViewChild("forgotForm", { static: true })
	private _forgotForm: ElementRef;
	@ViewChild("emailInputField", { static: true })
	private _emailInputField: ElementRef;

	public errorMessage: string;
	public success = false;
	public isLoading = false;

	public forgotPasswordRequest: ForgotPasswordRequest = new ForgotPasswordRequest();

	constructor(private api: ApiCommunicationService,
				private pageTitleService: PageTitleService,
				private translate: TranslateService) {
	}

	ngOnInit() {
		// set page title
		this.pageTitleService.setPageTitle("Forgotten password");
	}

	ngAfterViewInit() {
		// focus on element when modal is shown
		(<HTMLInputElement> this._emailInputField.nativeElement).focus();
	}

	public sendForgotPasswordRequest() {

		// reset error message
		this.errorMessage = null;

		// check form validity
		const isFormValid = (<HTMLFormElement> this._forgotForm.nativeElement).checkValidity();

		// set bootstrap class indicating state
		(<HTMLFormElement> this._forgotForm.nativeElement).classList.add("was-validated");

		// form is invalid
		if ( isFormValid ) {
			this.errorMessage = null;
			this.isLoading = true;
			this.success = false;

			this.api.profile().forgotPassword(this.forgotPasswordRequest)
				.subscribe((response) => {
					this.success = true;
					this.errorMessage = this.translate.instant("account.reset-password.forgot-success-hint");
				}, (err) => {
					if (err.status === 404) {
						this.errorMessage = this.translate.instant("account.reset-password.forgot-no-match-hint");
					} else {
						this.errorMessage = this.translate.instant("account.reset-password.forgot-error-hint");
					}
					this.isLoading = false;
				});
		}
	}

}
