import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {User} from "../../model/data/user/User";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";

@Injectable()
export class FollowersResolverService implements Resolve<Array<User>> {

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<User>> {

		// get the user object from parent route
		const userId: string = route.params["userId"];

		return this.api.relationship().getFollowers(userId);
	}
}
