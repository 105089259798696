import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {OrderListResponse} from "../response/OrderListResponse";
import {CancelReason} from "../data/enums/CancelReason";
import {Order} from "../data/checkout/Order";

export class OrderApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/order";

	public getCustomerOrder(pageNumber: number): Observable<OrderListResponse> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${pageNumber}/customer`);
	}

	public getOrder(orderId: string): Observable<Order> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${orderId}`);
	}

	public cancelOrder(orderId: string): Observable<any> {
		return this.apiClient.authorizedOnly().patch(`${this.apiRoute}/${orderId}`, {cancelReason: CancelReason.CUSTOMER});
	}

}
