import {EventEmitter, Inject, Injectable} from "@angular/core";
import {User} from "../../model/data/user/User";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";

@Injectable()
export class ProfileChangeEventService {

	private _userUpdateEvent = new EventEmitter<User>();
	private _followEvent = new EventEmitter<Array<User>>();

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	public onUserUpdateEvent(user: User) {
		this._userUpdateEvent.emit(user);
	}

	get userUpdateEvent(): EventEmitter<User> {
		return this._userUpdateEvent;
	}

	public onFollowEvent(userId: string) {
		this.api.relationship().getFollowers(userId).subscribe((followers: Array<User>) => {
			this._followEvent.emit(followers);
		});
	}

	get followEvent(): EventEmitter<Array<User>> {
		return this._followEvent;
	}

}
