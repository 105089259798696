import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";

@Injectable()
export class UsernameResolverService implements Resolve<string> {

	constructor() {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> | string {
		// get the username from parent route's resolver
		return route.parent.data.user.userName;
	}

}
