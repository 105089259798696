import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {User} from "../../model/data/user/User";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {CollectionListResponse} from "../../model/response/CollectionListResponse";
import {CollectionType} from "../../model/data/enums/CollectionType";
import {Product} from "../../model/data/shop/product/Product";

@Injectable()
export class ShopResolverService implements Resolve<Array<Product>> {

	constructor(private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		Observable<Array<Product>> | Promise<Array<Product>> | Array<Product> {

		// get the user object from parent route
		const user: User = route.parent.data.user;
		return this.api.product().getMerchantShop(user._id);

	}

}
