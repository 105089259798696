import {Component, Input, OnInit} from "@angular/core";

@Component({
	selector: "app-charity-bar",
	templateUrl: "./charity-bar.component.html",
	styleUrls: ["./charity-bar.component.scss"]
})
export class CharityBarComponent implements OnInit {

	@Input("charities")
	public total: number;

	constructor() {
	}

	ngOnInit() {
	}

}
