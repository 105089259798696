import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PostBackgroundService} from "../../../commons/services/post-background/post-background.service";

@Component({
	selector: "app-gradient-selector",
	templateUrl: "./gradient-selector.component.html",
	styleUrls: ["./gradient-selector.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradientSelectorComponent implements OnInit {

	public options = [];

	@Input() selectedOption: Object;
	@Output() selectedOptionChange: EventEmitter<Object> = new EventEmitter<Object>();

	constructor(private postBackgroundService: PostBackgroundService) {
	}

	ngOnInit() {
		this.options = this.postBackgroundService.backgrounds;
	}

}
