import {Component, EventEmitter, OnInit} from "@angular/core";
import {Cart} from "../../../model/data/shop/cart/Cart";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {CartService} from "../../../model/services/cart/cart.service";
import {fadeInOutAnimation, flyInOutAnimation} from "../../../commons/animations/modalAnimations";
import {Router} from "@angular/router";

@Component({
	selector: "app-charity-modal",
	templateUrl: "./charity-modal.component.html",
	styleUrls: ["./charity-modal.component.scss"],
	animations: [
		fadeInOutAnimation, flyInOutAnimation
	]
})
export class CharityModalComponent implements OnInit {

	public saveActionEvent: EventEmitter<any> = new EventEmitter<any>();
	public closeAction: EventEmitter<void> = new EventEmitter<void>();

	public cart: Cart;
	public availableCharities: Array<any>;

	public isLoading = false;

	public charitiesTotal: number;

	constructor(private api: ApiCommunicationService,
				private router: Router,
				private cartService: CartService) {
	}

	ngOnInit() {
		// get charity data from backend
		this.api.charity().getCharitySummarization().subscribe((response) => {
			this.availableCharities = response.charities;
			this.charitiesTotal = response.total;
		});

		// close on navigation
		this.router.events.subscribe((e) => {
			this.closeAction.emit();
		});
	}

	/**
	 * Ensures that only one checkbox is selected at a time.
	 * @param {string} charityId
	 */
	public ensureOnlyOneCharityIsSelected(charityId: string): void {
		this.availableCharities.filter(c => c._id !== charityId)
			.forEach(c => c["selected"] = false);
	}

	/**
	 * Used to determine if singular or plural form needs to be used on the button
	 * @returns {number} the number of charities selected
	 */
	public getNumberOfSelectedCharities(): number {
		if (!this.availableCharities || !this.availableCharities.length) {
			return 0;
		}
		// number of charities have "selected" attribute set to true
		return this.availableCharities.filter(c => c["selected"]).length;
	}

	/**
	 * Save action
	 */
	public selectCharitiesAction() {

		// get selected charity
		const selectedCharity = this.availableCharities.find(c => c["selected"]);

		// close if no charities are selected
		if (selectedCharity) {
			this.doSelect(selectedCharity._id);
		}

	}

	private doSelect(charityId) {
		this.isLoading = true;

		// add charity trough shared cart service
		this.cartService.addCharity(charityId)
			.then(() => {
				// close and pass new cart to service
				this.closeAction.emit();
			}).catch(() => {
				this.isLoading = false;
		});
	}

}
