import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import {Content} from "../../../../../model/content/Content";

// Hack
declare var WaveSurfer: any;

@Component({
	selector: "app-post-modal-audio-player",
	templateUrl: "./post-modal-audio-player.component.html",
	styleUrls: ["./post-modal-audio-player.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostModalAudioPlayerComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input()
	public post: Content;

	// URL for the audio
	public audioUrl: string;

	@ViewChild("playerContainer", {static: true})
	public playerContainer: ElementRef;

	private waveSurferInstance: any;

	// true if player is actively playing
	public playing = false;

	constructor(@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.audioUrl = this.post.media.find(m => m.audio).audio;
	}

	ngAfterViewInit(): void {
		// load audio player for podcast
		this.waveSurferInstance = WaveSurfer.create({
			container: this.playerContainer.nativeElement,
			barWidth: 2,
			barHeight: 1, // the height of the wave
			barGap: null, // the optional spacing between bars of the wave, if not provided will be calculated in legacy format
			waveColor: "#FFF",
			cursorColor: "#e8561d"
		});

		// load audio url - we assume that there is ony one audio file
		this.waveSurferInstance.load(this.audioUrl);
		this.waveSurferInstance.on("play", () => this.updatePlayerStatus());
		this.waveSurferInstance.on("pause", () => this.updatePlayerStatus());
		this.waveSurferInstance.on("finish", () => this.updatePlayerStatus());

		this.changeDetector.detectChanges();
	}

	ngOnDestroy() {
		// stop the playback
		this.waveSurferInstance.stop();
		delete this.waveSurferInstance;
	}

	private updatePlayerStatus() {
		this.playing = this.waveSurferInstance.isPlaying();
	}

	public playPause() {
		this.waveSurferInstance?.playPause();
		this.changeDetector.detectChanges();
	}

}
