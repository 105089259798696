import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {MicroBlogPostRequest} from "../../../model/request/microblogpost/MicroBlogPostRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {Content} from "../../../model/content/Content";
import {UpdateService} from "../../../commons/services/update/update.service";
import {Router} from "@angular/router";
import {NGX_EDITOR_CONFIG} from "../../config/ngx-editor-config";

@Component({
	selector: "app-edit-blogpost-modal",
	templateUrl: "./edit-blogpost-modal.component.html",
	styleUrls: ["./edit-blogpost-modal.component.scss"]
})
export class EditBlogpostModalComponent implements OnInit {

	public request: MicroBlogPostRequest = new MicroBlogPostRequest();
	public isLoading = false;
	public postData: Content;
	public modalRef: NgbModalRef;

	public ngxEditorConfig = NGX_EDITOR_CONFIG;

	@ViewChild("fileInput", { static: true })
	public fileInput: ElementRef;

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService,
				private router: Router,
				private updateService: UpdateService) {
	}

	ngOnInit() {
	}

	public setupPost(post: Content) {
		this.request = MicroBlogPostRequest.fromMicroBlogPost(post);
		this.postData = post;
	}

	editPostAction(): void {
		this.isLoading = true;
		this.api.microblogpost()
			.editMBPost(this.postData._id, this.request, (<HTMLInputElement>this.fileInput.nativeElement).files)
			.then((data: Content) => {
				this.isLoading = false;
				this.updateService.updateBlogPost(data);
				this.alertService.success({title: "Blog post saved successfully"});
				this.modalRef.close();
		}).catch((err) => {
			err = JSON.parse(err);
			if (err.status === 413) {
				this.alertService.error({title: `Could not update your blog post. <br> The size of the selected image(s) is too large. (10Mb)`});
			} else {
				if (err.message === "IMAGE RESIZE ERROR") {
					err.message = "Image(s) can not be resized";
				}
				this.alertService.error({title: `Could not update your blog post. <br> ${err.message}.`});
			}
			this.isLoading = false;
		});
	}
	
	deleteBlogPost(): void {
		this.isLoading = true;
		this.api.microblogpost()
			.deleteMicroBlogPost(this.postData._id).subscribe((response: any) => {
				this.isLoading = false;
				this.alertService.success({title: "Blog post deleted successfully"}).then(() => {
					this.modalRef.close();
					this.router.navigate(["/"]);
				});
		}, err => {
			this.alertService.error({title: "Could not delete your blog post."});
			this.isLoading = false;
		});
	}

}
