import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {UserStatisticsResponse} from "../../model/response/UserStatisticsResponse";

@Injectable()
export class UserStatsResolverService implements Resolve<UserStatisticsResponse> {

	constructor(private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		Observable<UserStatisticsResponse> | Promise<UserStatisticsResponse> | UserStatisticsResponse {
		return this.api.profile().getUserStatistics(route.params["userId"]);
	}

}
