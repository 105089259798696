import {ContentType} from "../../data/enums/ContentType";

export class SearchRequest {

	text?: string;
	rating?: number;
	bottomPrice?: number;
	topPrice?: number;
	tags?: Array<string>;
	category?: string;
	subCategory?: string;
	subSubCategory?: string;
	brand?: string;
	types: Array<ContentType>;

	public static transform(payload: SearchRequest): any {
		const rv = {};

		if (payload.text) {
			Object.assign(rv, {text: payload.text});
		}

		if (payload.rating) {
			Object.assign(rv, {rating: payload.rating});
		}

		if (payload.bottomPrice) {
			Object.assign(rv, {bottomPrice: payload.bottomPrice});
		}

		if (payload.topPrice) {
			Object.assign(rv, {topPrice: payload.topPrice});
		}

		if (payload.category) {
			Object.assign(rv, {category: payload.category});
		}

		if (payload.subCategory) {
			Object.assign(rv, {subCategory: payload.subCategory});
		}

		if (payload.subSubCategory) {
			Object.assign(rv, {subSubCategory: payload.subSubCategory});
		}

		if (payload.brand) {
			Object.assign(rv, {brand: payload.brand});
		}

		if (payload.tags && payload.tags.length) {
			Object.assign(rv, {tags: payload.tags});
		}

		if (payload.types && payload.types.length) {
			Object.assign(rv, {types: payload.types});
		}

		return rv;
	}

	static serialize(request: SearchRequest) {
		return JSON.stringify(
			Object.keys(request).filter((k) => {
				// has value and not trimmable or trimmed version is still valid
				return !!request[k] && (!request[k]["trim"] || !!request[k].trim());
			})
				// reassemble object from original request and list of keys
				.reduce((obj, key) => {
					obj[key] = request[key];
					return obj;
				}, {})
		);
	}
}
