import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Product} from "../data/shop/product/Product";
import {ProductListResponse} from "../response/ProductListResponse";
import {ReportRequest} from "../request/report/ReportRequest";

export class ProductApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/product";

	public getProduct(productId: string): Observable<Product> {
		return this.apiClient.get(this.apiRoute + "/" + productId);
	}

	public getMerchantShop(userId: string): Observable<Array<Product>> {
		return this.apiClient.get(`${this.apiRoute}/${userId}/shop`);
	}

	public getPopularProducts(pageNumber: number): Observable<ProductListResponse> {
		return this.apiClient.get(this.apiRoute + "/popular/" + pageNumber);
	}

	public report(request: ReportRequest): Observable<any> {
		return this.apiClient.authorizedOnly().post(this.apiRoute + "/report", request);
	}

	public getMoreLikeThis(pageNumber: number, productId: string): Observable<ProductListResponse> {
		return this.apiClient.get(this.apiRoute + "/more/" + pageNumber, {product: productId});
	}

}
