import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Product} from "../../../model/data/shop/product/Product";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {mainFeedAnimations} from "../../../commons/animations/mainFeedAnimations";
import {Router} from "@angular/router";
import {ProductPageModalService} from "../../services/product-page-modal.service";

@Component({
	selector: "app-product-feed-item",
	templateUrl: "./product-feed-item.component.html",
	styleUrls: ["./product-feed-item.component.scss"],
	animations: [mainFeedAnimations]
})
export class ProductFeedItemComponent implements OnInit {

	@Input()
	public product: Product;

	@Input()
	public resize = false;
	private action: boolean;

	@Output()
	public imageLoaded: EventEmitter<void> = new EventEmitter<void>();

	constructor(private productPageService: ProductPageModalService,
				public userSessionService: UserSessionService,
				public router: Router) {
	}

	ngOnInit(): void {

		// ordering the images of the variants
		if (this.product.variants && this.product.variants.length && this.product.variants[0].media && this.product.variants[0].media.length) {
			this.product.variants[0].media = this.product.variants[0].media.sort((a, b) => {
				if (a.position < b.position) { return -1; }
				if (a.position > b.position) { return 1; }
				return 0;
			});
		}
	}

	openProductPage(_id: string) {
		this.productPageService.open(_id);
	}

	public getItemContainerSize() {
		if (this.resize && this.product.numberOfLikes > 35) {
			return true;
		} else if (this.resize && this.product.numberOfLikes >= 3 && this.product.numberOfLikes <= 35) {
			return true;
		}
	}

	public addToBasket() {

	}

	public preventNav() {
		this.userSessionService.preventNavigation();
	}

}
