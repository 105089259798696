import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {Content} from "../../../../../model/content/Content";
import * as Autolinker from "autolinker/dist/autolinker.min";

declare const IFrameAPI: any;

@Component({
	selector: "app-post-modal-spotify-player",
	templateUrl: "./post-modal-spotify-player.component.html",
	styleUrls: ["./post-modal-spotify-player.component.scss"]
})
export class PostModalSpotifyPlayerComponent implements OnInit, AfterViewInit {

	@Input()
	public post: Content;

	@ViewChild("playerContainer", {static: true})
	public playerContainer: ElementRef;

	constructor() {
	}

	ngOnInit() {


	}

	ngAfterViewInit() {

		const spotifyEmbeds: Array<string> = [];

		if (this.post.description) {
			const matches = Autolinker.parse(this.post.description);
			matches.forEach(match => {
				// only care about spotify links
				if (match.type === "url" && (new URL(match.getUrl())).hostname === "open.spotify.com") {
					spotifyEmbeds.push(match.getUrl());
				}
			});
		}

		if (!spotifyEmbeds.length) {
			return;
		}

		const element = this.playerContainer.nativeElement;
		const options = {
			// we can only display the first one at the moment
			uri: spotifyEmbeds[0]
		};
		const callback = (EmbedController) => {};
		IFrameAPI.createController(element, options, callback);
	}

}
