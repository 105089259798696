import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {NotificationDataService} from "../../../model/services/notification-data/notification-data.service";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {NavigationEnd, Router} from "@angular/router";
import {UserNotification} from "../../../model/data/notification/UserNotification";
import {User} from "../../../model/data/user/User";
import {Role} from "../../../model/data/enums/Role";

@Component({
	selector: "app-notification-menu",
	templateUrl: "./notification-menu.component.html",
	styleUrls: ["./notification-menu.component.scss"]
})
export class NotificationMenuComponent implements OnInit, AfterViewInit {

	// display options for components
	public userAvatarDisplayType = UserAvatarDisplayType.IMAGE_ONLY;

	@ViewChild("menu", { static: true })
	private menu: NgbDropdown;

	public notifications: Array<UserNotification> = null;

	constructor(@Inject(NotificationDataService) public notificationService: NotificationDataService,
				private router: Router,
				public userSessionService: UserSessionService,
				private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		// hide when location changes
		this.router.events.subscribe( (event) => {
			if (event instanceof NavigationEnd) {
				if (this.menu) {
					this.menu.close();
				}
			}
		});
	}


	ngAfterViewInit(): void {
		// TODO RR
		// this.notificationService.lastNotifications.subscribe((data) => {
		// 	this.notifications = data;
		// 	this.changeDetector.detectChanges();
		// });
	}

	public openUserData() {
		this.router.navigate(["/profile"]);
	}

	public onOpenChangeAction(isOpen): void {
		// on close event
		if (!isOpen) {
			this.notificationService.clearAllNotifications();
		}
	}

}
