import {Component, OnDestroy, OnInit} from "@angular/core";
import {HomeFeedDataService} from "../../services/home-feed-data/home-feed-data.service";
import {SearchRequest} from "../../../model/request/search/SearchRequest";
import {Subscription} from "rxjs";
import {SearchSuggestionsResponse} from "../../../model/response/SearchSuggestionsResponse";
import {UserAvatarDisplayType} from "../../../user/components/user-avatar/user-avatar.component";
import {Category} from "../../../model/data/shop/category/Category";
import {CategoryLevel} from "../../../model/data/enums/CategoryLevel";
import {CategoryService} from "../../../commons/services/category/category.service";
import {ContentType} from "../../../model/data/enums/ContentType";

@Component({
	selector: "app-main-feed-suggestion",
	templateUrl: "./main-feed-suggestion.component.html",
	styleUrls: ["./main-feed-suggestion.component.scss"]
})
export class MainFeedSuggestionComponent implements OnInit, OnDestroy {

	private _suggestions: SearchSuggestionsResponse = new SearchSuggestionsResponse();
	private _suggestionsSubscription: Subscription;

	public _userAvatarDisplayType = UserAvatarDisplayType.NAME_UNDER_IMAGE;

	constructor(private feedDataService: HomeFeedDataService,
				private categoryService: CategoryService) {

		this._suggestionsSubscription = this.feedDataService.suggestions$.subscribe(s => {
			this._suggestions = s;
		});
	}

	public ngOnInit() {
	}

	public ngOnDestroy(): void {
		this._suggestionsSubscription.unsubscribe();
	}

	public applyBrandFilter(brand: string) {
		const f = this.feedDataService.getFilter();
		f.brand = brand;

		// reset filter term
		delete f.text;
		// tslint:disable-next-line:max-line-length
		f.types = (f.types && f.types.length && !f.types.some(t => t === ContentType.PRODUCT)) ? [...f.types, ContentType.PRODUCT] : [ContentType.PRODUCT];
		this.feedDataService.setFilter(f);
	}

	public applyCategoryFilter(category: Category) {
		const f = this.feedDataService.getFilter();
		const rv = this.categoryService.getCategoryTree(category);

		// set category according to level
		if (category.level === CategoryLevel.MAIN - 1) {
			f.category = category._id;
		} else if (category.level === CategoryLevel.SUB - 1) {
			f.category = rv._id;
			f.subCategory = category._id;
		} else if (category.level === CategoryLevel.SUB_OF_SUB - 1) {
			f.category = rv._id;
			f.subCategory = rv.subCategories.find(sc => sc.subCategories.some(ssc => ssc._id === category._id))._id;
			f.subSubCategory = category._id;
		}

		// reset filter term
		delete f.text;
		// tslint:disable-next-line:max-line-length
		f.types = (f.types && f.types.length && !f.types.some(t => t === ContentType.PRODUCT)) ? [...f.types, ContentType.PRODUCT] : [ContentType.PRODUCT];
		this.feedDataService.setFilter(f);
	}


	public getFilter(): SearchRequest {
		return this.feedDataService.getFilter();
	}

	get categories(): Array<Category> {
		return (this._suggestions && this._suggestions.categories) ? this._suggestions.categories : [];
	}

	get users() {
		return (this._suggestions && this._suggestions.users) ? this._suggestions.users : [];
	}

	get brands() {
		return (this._suggestions && this._suggestions.brands) ? this._suggestions.brands : [];
	}

	get activeCategoryName() : string {
		const category = this.getFilter().subSubCategory ||
			this.getFilter().subCategory ||
			this.getFilter().category;

		if (!category) {
			return null;
		}

		const categoryObj = this.categoryService.getCategoryDeep(category);
		return categoryObj && categoryObj.name;

	}

}
