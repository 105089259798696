import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {User} from "../data/user/User";
import {UserRequest} from "../request/user/UserRequest";
import {ForgotPasswordRequest} from "../request/user/ForgotPasswordRequest";
import {ResetPasswordRequest} from "../request/user/ResetPasswordRequest";
import {ChangePasswordRequest} from "../request/user/ChangePasswordRequest";
import {ImageType, ImageUploadRequest} from "../request/user/ImageUploadRequest";
import {UserStatisticsResponse} from "../response/UserStatisticsResponse";
import {ContentListResponse} from "../response/ContentListResponse";
import {ProductListResponse} from "../response/ProductListResponse";
import {LikedContent} from "../data/social/LikedContent";
import {FeedbackRequest} from "../request/feedback/FeedbackRequest";

export class ProfileApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/profile";

	public getMyProfile(): Observable<User> {
		return this.apiClient.authorizedOnly().get(this.apiRoute);
	}

	public getProfile(userId: string): Observable<User> {
		return this.apiClient.get(`${this.apiRoute}/${userId}`);
	}

	public validateEmail(email: string): Observable<any> {
		return this.apiClient.get(this.apiRoute + "/valid", {email: email});
	}

	public validateUsername(userName: string): Observable<any> {
		return this.apiClient.get(this.apiRoute + "/valid", {userName: userName});
	}

	public getUserStatistics(userId: string): Observable<UserStatisticsResponse> {
		return this.apiClient.get(this.apiRoute + "/" + userId + "/statistic");
	}

	public updateProfile(request: UserRequest): Observable<User> {
		return this.apiClient.authorizedOnly().patch(this.apiRoute, request);
	}

	public uploadProfileImage(request: ImageUploadRequest): Promise<User> {
		const formData = new FormData();
		// append the images if any
		if (request.images && request.images.length) {
			for (let i = 0; i < request.images.length; i++) {
				formData.append("image", request.images[i], request.images[i].name);
			}
			formData.append("type", request.type);
		}


		return this.apiClient.authorizedOnly()
			.formDataRequest("PATCH", this.apiRoute + "/upload", formData);
	}

	public uploadSingleProfileImage(file: File): Promise<User> {
		const formData = new FormData();
		formData.append("image", file, file.name);
		formData.append("type", ImageType.PROFILE);

		return this.apiClient.authorizedOnly()
			.formDataRequest("PATCH", this.apiRoute + "/upload", formData);
	}

	public forgotPassword(request: ForgotPasswordRequest): Observable<any> {
		return this.apiClient.post(this.apiRoute + "/forgotPassword", request);
	}

	public resetPassword(request: ResetPasswordRequest): Observable<any> {
		return this.apiClient.post(this.apiRoute + "/resetPassword", request);
	}

	public changePassword(request: ChangePasswordRequest): Observable<any> {
		return this.apiClient.authorizedOnly().post(this.apiRoute + "/changePassword", request);
	}

	public getBlogPosts(pageNumber: number, userId: string): Observable<ContentListResponse> {
		return this.apiClient.get(`${this.apiRoute}/blogs/${pageNumber}/${userId}`);
	}

	public getConversations(pageNumber: number, userId: string): Observable<ContentListResponse> {
		return this.apiClient.get(`${this.apiRoute}/conversations/${pageNumber}/${userId}`);
	}

	public getEvents(pageNumber: number, userId: string): Observable<ContentListResponse> {
		return this.apiClient.get(`${this.apiRoute}/events/${pageNumber}/${userId}`);
	}

	public getLiked(pageNumber: number, userId: string): Observable<LikedContent> {
		return this.apiClient.get(`${this.apiRoute}/liked/${pageNumber}/${userId}`);
	}

	public getOffers(pageNumber: number, userId: string): Observable<ProductListResponse> {
		return this.apiClient.get(`${this.apiRoute}/offers/${pageNumber}/${userId}`);
	}

	public getTopSellers(pageNumber: number, userId: string): Observable<ProductListResponse> {
		return this.apiClient.get(`${this.apiRoute}/topSellers/${pageNumber}/${userId}`);
	}

	public feedback(request: FeedbackRequest): Observable<any> {
		return this.apiClient.post(`${this.apiRoute}/feedback`, request);
	}

	public requestEmailVerification(email: string): Observable<any> {
		return this.apiClient.post(`${this.apiRoute}/requestVerification`, {email: email});
	}

	public verifyAccount(token: string): Observable<any> {
		return this.apiClient.post(`${this.apiRoute}/verifyEmail`, {token: token});
	}

}
