import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {User} from "../../model/data/user/User";
import {Observable} from "rxjs";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";

@Injectable()
export class FollowersResolverChildService implements Resolve<Array<User>> {

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<User>> | Array<User> {
		return this.api.relationship().getFollowers(route.parent.params["userId"]);
	}

}
