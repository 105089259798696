import {ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector} from "@angular/core";
import {ApiCommunicationService} from "../../model/services/api-communication/api-communication.service";
import {Observable} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {CharityModalComponent} from "../modals/charity-modal/charity-modal.component";
import {Cart} from "../../model/data/shop/cart/Cart";

@Injectable({
	providedIn: "root"
})
export class CharityModalService {

	constructor(@Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver,
				@Inject(DOCUMENT) private document,
				private injector: Injector,
				private api: ApiCommunicationService,
				private applicationRef: ApplicationRef) {
	}


	public openModal(cart: Cart): Observable<any>  {

		// body element
		const containerElement = this.document.body;

		// resolve component factory
		const factory = this.factoryResolver
			.resolveComponentFactory(CharityModalComponent);

		// create the new component, assign it to the instance reference
		const instanceRef = factory
			.create(this.injector, containerElement);

		// assign params
		instanceRef.instance.cart = cart;

		// attach view
		this.applicationRef.attachView(instanceRef.hostView);

		// append component element
		containerElement.appendChild(instanceRef.location.nativeElement);

		// close action
		instanceRef.instance.closeAction.subscribe(() => {
			instanceRef.destroy();
		});

		// return observable
		return instanceRef.instance.saveActionEvent.asObservable();

	}

}
