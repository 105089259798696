import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
	selector: "app-tags",
	templateUrl: "./tags.component.html",
	styleUrls: ["./tags.component.scss"]
})
export class TagsComponent implements OnInit {

	@Input()
	public tags: Array<string> = [];

	@Output("tag")
	public tag = new EventEmitter<string>();

	constructor() {
	}

	ngOnInit() {
	}

	setTag(tag: string) {
		this.tag.emit(tag);
	}

}
