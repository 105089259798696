import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {User} from "../data/user/User";

export class RelationshipApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/relationship";

	public getFollowers(userId: string): Observable<Array<User>> {
		return this.apiClient.get(`${this.apiRoute}/${userId}/followers`);
	}

	public getFollowings(userId: string): Observable<Array<User>> {
		return this.apiClient.get(`${this.apiRoute}/${userId}/followings`);
	}

	public followUser(followRequest: any): Observable<any> {
		return this.apiClient.authorizedOnly().post(this.apiRoute, followRequest);
	}

	public unfollowUser(userId: string): Observable<any> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${userId}`);
	}

	public blockUser(blockRequest: any): Observable<any> {
		return this.apiClient.authorizedOnly().post(this.apiRoute, blockRequest);
	}

}
