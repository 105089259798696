import {Component, Inject, Input, OnInit, ViewChild} from "@angular/core";
import {AbstractSocialButton} from "./abstract-social-button";
import {nullAnimation} from "../../animations/socialIconsStateChangeAnimation";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../../user/services/user-session/user-session.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {Content} from "../../../model/content/Content";
import {Product} from "../../../model/data/shop/product/Product";
import {ShareRequest} from "../../../model/request/share/ShareRequest";
import {SocialService} from "ngx-social-button";
import {SharePlatform} from "../../../model/data/enums/SharePlatform";
import {ContentType} from "../../../model/data/enums/ContentType";

@Component({
	selector: "app-social-button-share",
	templateUrl: "./social-button-share.component.html",
	styleUrls: ["./abstract-social-button.scss"],
	animations: [
		nullAnimation
	]
})
export class SocialButtonShareComponent extends AbstractSocialButton implements OnInit {

	@Input()
	public item: Content | Product;

	@Input()
	public type: "product" | "post" | "microblogpost" | "shop" | "event" = "product";

	@ViewChild("shareDrop", { static: true })
	private shareDrop: NgbDropdown;

	public sharableUrl: string;

	constructor(private api: ApiCommunicationService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				private userSessionService: UserSessionService,
				private socialAuthService: SocialService) {
		super("share", "icon fi fi-sr-share");
	}

	ngOnInit() {

		// init number to display
		if (this.item) {
			this._displayedNumber = this.item.numberOfShares;
		}

		// assign user object if not passed
		const user = this.userSessionService.userDataValue;

		// check if the current user has liked the item
		if (user && this.item && this.item.shares) {
			// get the user's share (if any)
			this._iconState = this.item.shares.some((shares) => shares.user.userName === user.userName);
		} else {
			this._iconState = false;
		}

		// generate sharable url
		this.sharableUrl = encodeURI(this.getSharableUrl());

	}

	clickAction(): void {

		// check if dropdown is open. Close it if yes.
		// if (this._iconState) {
		// 	return;
		// }

		// check if user is present
		if (!this.userSessionService.userDataValue || !this.userSessionService.userDataValue._id) {
			this.noActiveUserAction();
			return;
		}

		if (this._iconState) {
			this.shareDrop.open();
			return;
		}

	}


	private noActiveUserAction(): void {
		this.modalServiceProxy.get().showLoginModal();
	}

	private getSharableUrl(): string {
		let url = location.origin + "/";
		// let url = "https://lovegivr-qa-frontend.uksouth.cloudapp.azure.com" + "/#/";

		switch (this.type.toUpperCase()) {
			case "PRODUCT":
				url = `${url}product/${this.item._id}`;
				break;
			case "MICRO BLOG POST":
				url = `${url}blogpost/${this.item._id}`;
				break;
			case "EVENT":
				url = `${url}event/${this.item._id}`;
				break;
			case "POST":
				url = `${url}?content=${this.item._id}`;
				break;
			case "SHOP":
				url = `${url}user/${this.item.user.userName}/${this.item.user._id}/shop`;
				break;
		}

		return url;
	}

	public facebookSharingAction() {

		// already shared
		if (this._iconState) {
			return;
		}

		const shareRequest = new ShareRequest();

		if (this.type.toUpperCase() === "PRODUCT") {
			shareRequest.platform = SharePlatform.FACEBOOK;
			shareRequest.type = ContentType.PRODUCT;
			shareRequest.content = this.item._id;

			this.api.share().shareProduct(shareRequest).subscribe((resp) => {
				this.sharableUrl = `${this.sharableUrl}?share=${resp._id}`;
				this._displayedNumber++;
				this._iconState = true;
				this.facebookShare();
			});

		} else {
			this.facebookShare();
		}

	}

	private facebookShare() {
		const shareObj = {
			href: this.sharableUrl
			// image: "https://lovegivrqa.blob.core.windows.net/5d36f38985e29a0c503a6489/24fadd93-c55d-4e45-a046-b006a5bcd867.png"
		};

		this.socialAuthService.facebookSharing(shareObj).then(() => {
		});
	}

	checkIsUserLoggedIn() {
		if (!this.userSessionService.userDataValue || !this.userSessionService.userDataValue._id) {
			this.noActiveUserAction();
		}
	}
}
