import {Content} from "../../content/Content";

export class MicroBlogPostRequest {
	title: string;
	text: string; // description


	public static fromMicroBlogPost(post: Content): MicroBlogPostRequest {
		return {
			title: post.title,
			text: post.description
		};
	}
}
