import {Injectable} from "@angular/core";
import {NgxMasonryComponent, NgxMasonryOptions} from "ngx-masonry";

@Injectable({
	providedIn: "root"
})
export class MasonaryService {

	// feed div, contains all the items
	private _containerElement: NgxMasonryComponent;

	// timer for adjusting layout. Spawned after image is loaded.
	private layoutAdjustTimer: number;

	// breakpoints - based on the number of columns displayed
	private _breakPoints = {
		"columns-1": 400,
		"columns-2": 800,
		"columns-3": -1
	};

	// masonry options
	private _masonryOptions: NgxMasonryOptions | {transitionDuration: number} = {
		percentPosition: true,
		itemSelector: ".item",
		resize: true,
		transitionDuration: 0
	};

	// class representing the displayed columns
	private _columnsClass = "";

	constructor() {
	}

	private adjustColumnClass() {
		const containerWidth = this._containerElement?._msnry?.containerWidth;

		if (containerWidth <= this.breakPoints["columns-1"]) {
			this._columnsClass = "columns-1";
		} else if (containerWidth <= this.breakPoints["columns-2"]) {
			this._columnsClass = "columns-2";
		} else {
			this._columnsClass = "columns-3";
		}
	}

	public adjustLayout(): void {
		this.adjustColumnClass();
		this.containerElement.reloadItems();
		this.containerElement.layout();
	}

	public onImageLoaded() {
		// do not respawn if timer is present
		if (this.layoutAdjustTimer) {
			return;
		}
		// adjust layout in 300ms
		// @ts-ignore
		this.layoutAdjustTimer = setTimeout(() => {
			this.adjustLayout();
			this.layoutAdjustTimer = undefined;
		}, 300);
	}

	get breakPoints(): { "columns-1": number; "columns-3": number; "columns-2": number } {
		return this._breakPoints;
	}

	get masonryOptions(): NgxMasonryOptions | { transitionDuration: number } {
		return this._masonryOptions;
	}

	get columnsClass(): string {
		return this._columnsClass;
	}

	get containerElement(): NgxMasonryComponent {
		return this._containerElement;
	}

	set containerElement(value: NgxMasonryComponent) {
		this._containerElement = value;

		// adjust column class after initialization
		if (this._containerElement) {
			this.adjustColumnClass();
		}
	}

}
