import {Injectable} from "@angular/core";
import {SearchRequest} from "../../../model/request/search/SearchRequest";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpUrlEncodingCodec} from "@angular/common/http";

import * as _ from "lodash";

@Injectable({
	providedIn: "root"
})
export class HomeSearchUrlService {

	private codec = new HttpUrlEncodingCodec;

	constructor(private router: Router,
				private activatedRoute: ActivatedRoute) {
	}

	public setSearchUrlParams(filter: SearchRequest) {
		if (!filter) {
			return;
		}

		const encodedFilter = SearchRequest.serialize(filter);

		_.isEmpty(filter) ?
			this.router.navigate(["/"]).then() :
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams: {search: encodedFilter},
				queryParamsHandling: "merge"
			}).then();
	}

	public getSearchFromUrl(): any {

		if (this.activatedRoute.snapshot.queryParams['search']) {
			try {
				return JSON.parse(this.activatedRoute.snapshot.queryParams['search']);
			} catch (e) {
				console.log("Something went wrong during decoding the url params. Error=", e);
			}
		}

		return null;
	}


}
