import {ContentType} from "../../data/enums/ContentType";
import {SharePlatform} from "../../data/enums/SharePlatform";

export class ShareRequest {

	type: ContentType;
	message: string;
	platform: SharePlatform;
	content: string;

}

