import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../response/wrappers/ResponseWrapper";
import {UserNotification} from "../data/notification/UserNotification";

export class NotificationApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/notification";

	public getNotifications(): Observable<ResponseWrapper<Array<UserNotification>>> {
		return this.apiClient
			.get(this.apiRoute + "/");
	}

	public updateNotification(notificationId: string, payload: any): Observable<ResponseWrapper<any>> {
		return this.apiClient
			.put(this.apiRoute + "/" + notificationId, payload);
	}

	public clearAllNotifications(): Observable<ResponseWrapper<any>> {
		return this.apiClient
			.get(this.apiRoute + "/clear");
	}

}
