import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	OnInit,
	ViewChild
} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {NgbTabset} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {FeedEventsService} from "../../../commons/services/feed-events/feed-events.service";
import {Role} from "../../../model/data/enums/Role";
import {UserStatisticsResponse} from "../../../model/response/UserStatisticsResponse";

@Component({
	selector: "app-user-page",
	templateUrl: "./user-page.component.html",
	styleUrls: ["./user-page.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPageComponent implements OnInit, AfterViewInit {

	// main content container
	@ViewChild("content", { static: false })
	private content: ElementRef;

	// tabs bootstrap component
	@ViewChild(NgbTabset, { static: false })
	private sectionTabs: NgbTabset;

	// sidebar shown
	private _userCardVisible = true;

	// user object to be used
	public user: User;

	// if the user page is a merchant pags
	public isMerchant: boolean;

	// write permission
	public writePermission = false;

	public followers: Array<User>;
	public stats: UserStatisticsResponse;

	// md screen
	private readonly bootstrapScreenWidthSm = 1100;


	constructor(
		@Inject(PageTitleService)
		private pageTitleService: PageTitleService,
		private route: ActivatedRoute,
		private changeDetector: ChangeDetectorRef,
		private feedEventsService: FeedEventsService
	) {
	}

	/**
	 * Angular Lifecycle Hook. Responsible for the following tasks:
	 * - Set the page title.
	 */
	ngOnInit(): void {

		// get product from api
		this.route.data.subscribe( (data) => {

			// assign user object
			this.user = data.user;
			this.followers = data.followers;
			this.stats = data.stats;

			// is the user merchant
			this.isMerchant = this.user.role === Role.MERCHANT;

			// has write permission?
			this.writePermission = data.writePermission;

			// set page title
			this.pageTitleService.setPageTitle("@" + this.user.userName + "'s profile");
			this.changeDetector.detectChanges();
		});

		// show or hide user-card based on window width
		if (window.innerWidth <= this.bootstrapScreenWidthSm) {
			this._userCardVisible = false;
		}

	}

	ngAfterViewInit(): void {
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		// show or hide user-card based on window width
		if (event.target.innerWidth < this.bootstrapScreenWidthSm) {
			this._userCardVisible = false;
		}
	}

	/**
	 * This method toggles visibility of the user card on the page.
	 */
	public toggleUserCard(): void {

		// send feed event
		this.feedEventsService.containerSizeChangeEvent.emit();

		// toggle card
		this._userCardVisible = !this._userCardVisible;
	}


	get userCardVisible(): boolean {
		return this._userCardVisible;
	}

}
