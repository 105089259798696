import {DatabaseEntity} from "../DatabaseEntity";
import {User} from "../user/User";
import {LikeType} from "../enums/LikeType";

export class Like extends DatabaseEntity {

	type: LikeType;
	content: string;
	user: Partial<User>;

}
