import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ContentListResponse} from "../../../model/response/ContentListResponse";
import {ContentType} from "../../../model/data/enums/ContentType";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {UpdateService} from "../../../commons/services/update/update.service";
import {Content} from "../../../model/content/Content";

@Component({
	selector: "app-conversations",
	templateUrl: "./conversations.component.html",
	styleUrls: ["./conversations.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationsComponent implements OnInit, OnDestroy {

	@Input()
	public data: ContentListResponse;

	@Input()
	public type: ContentType;

	private request;
	protected readonly selector = "#feed";

	private sub;

	constructor(private api: ApiCommunicationService,
				private changeDetector: ChangeDetectorRef,
				private updateService: UpdateService) {
	}

	ngOnInit() {
		this.sub = this.updateService.contentChangeEvent.subscribe((content: Content) => {
			if (content === null) {
				this.api.post().getPosts(1).subscribe((response: ContentListResponse) => {
					this.data = response;
					this.changeDetector.detectChanges();
				});
			} else {
				this.data.docs[this.data.docs.indexOf(this.data.docs.find(c => content._id === c._id))] = content;
				this.changeDetector.detectChanges();
			}
		});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	onScrollDown() {
		if (this.data.hasNextPage) {
			switch (this.type) {
				case ContentType.POST:
					this.request = this.api.post().getPosts(this.data.nextPage);
					break;
				case ContentType.MICROBLOGPOST:
					this.request = this.api.microblogpost().getMicroBlogPosts(this.data.nextPage);
					break;
				case ContentType.EVENT:
					this.request = this.api.event().getEvents(this.data.nextPage);
					break;
				default:
					break;
			}

			// fetching next page
			this.fetchNextPage();
		}
	}

	private fetchNextPage() {
		this.request.subscribe((response: ContentListResponse) => {
			this.data.hasNextPage = response.hasNextPage;
			this.data.nextPage = response.nextPage;
			this.data.docs.push(...response.docs);
			this.changeDetector.markForCheck();
		});
	}

}
