export class AddToCartRequest {

	charityAccout?: string;

	items: [{
			variant: string, // variant ID
			quantity: number
	}];

}
