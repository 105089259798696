export class ComissionTriggerReqeust {

	// needed when buy triggered from any collection
	collectionId?: string;
	productId?: string;

	// needed when share via social platform
	shareId?: string;

}

