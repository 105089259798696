import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotificationMenuComponent} from "./components/notification-menu/notification-menu.component";
import {NotificationPageComponent} from "./pages/notification-page/notification-page.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {UserModule} from "../user/user.module";
import {TranslateModule} from "@ngx-translate/core";
import {MomentModule} from "ngx-moment";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuardService} from "../user/guard/auth-guard.service";


const routes: Routes = [
	// Product page
	{
		path: "notifications",
		component: NotificationPageComponent,
		canActivate: [AuthGuardService]
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		NgbModule,
		UserModule,
		TranslateModule,
		MomentModule
	],
	declarations: [
		NotificationMenuComponent,
		NotificationPageComponent
	],
	exports: [
		NotificationMenuComponent
	]
})
export class NotificationModule {
}
