import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {PostRequest} from "../../../model/request/post/PostRequest";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Content} from "../../../model/content/Content";
import {UpdateService} from "../../../commons/services/update/update.service";
import {PostBackgroundService} from "../../../commons/services/post-background/post-background.service";

@Component({
	selector: "app-edit-post-modal",
	templateUrl: "./edit-post-modal.component.html",
	styleUrls: ["./edit-post-modal.component.scss"]
})
export class EditPostModalComponent implements OnInit {

	public request: PostRequest = new PostRequest();
	public postData: Content;
	public isLoading = false;

	@ViewChild("imageInput", { static: true })
	public imageInput: ElementRef;

	@ViewChild("videoInput", { static: true })
	public videoInput: ElementRef;

	public modalRef: NgbModalRef;

	public selectedBackground: any;

	imagePreviewUrls = [];
	videoPreviewUrl = null;

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService,
				private updateService: UpdateService,
				private postBackgroundService: PostBackgroundService) {
	}

	ngOnInit() {
	}

	public setupPost(post: Content) {
		this.request = PostRequest.fromPost(post);
		this.postData = post;
		if (this.postData.backgroundId) {
			this.selectedBackground = this.postBackgroundService.getBackground(this.postData.backgroundId);
		}
		if (this.postData.media.length > 0) {
			this.imagePreviewUrls = this.postData.media.map(m => m.urlSmall).filter(m => !!m);
			this.videoPreviewUrl = this.postData.media.map(m => m.url).filter(m => !!m);
		}
	}

	onImageSelected(event) {
		console.log(event.target);
		this.imagePreviewUrls = [];
		const files = event.target.files;
		Array.from(files).forEach(file => {
			const reader = new FileReader();

			reader.readAsDataURL(<Blob> file);
			reader.onload = (event) => {
				this.imagePreviewUrls.push((<FileReader>event.target).result);
			};
		});
	}

	onVideoSelected(event) {
		const file = event.target.files && event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(<Blob> file);
			reader.onload = (event) => {
				this.videoPreviewUrl = (<FileReader>event.target).result;
			};
		}
	}

	mediaAttached(): boolean {
		return this.imagePreviewUrls.length > 0;
	}

	editPostAction(): void {
		this.isLoading = true;

		if (this.selectedBackground) {
			this.request.backgroundId = this.selectedBackground.id;
		} else {
			delete this.request["backgroundId"];
		}

		this.api.post()
			.editPost(
				this.postData._id,
				this.request,
				(<HTMLInputElement>this.imageInput.nativeElement).files,
				(<HTMLInputElement>this.videoInput.nativeElement).files)
			.then((data: Content) => {
				this.isLoading = false;
				this.updateService.updatePost(data);
				this.alertService.success({title: "Your conversation has been updated"});
				this.modalRef.close();
			}).catch((err) => {
				err = JSON.parse(err);
				if (err.status === 413) {
					this.alertService.error({title: `Could not update your conversation. <br> The size of the selected image(s) is too large. (10Mb)`});
				} else {
					if (err.message === "IMAGE RESIZE ERROR") {
						err.message = "Image(s) can not be resized";
					}
					this.alertService.error({title: `Could not update your conversation. <br> ${err.message}.`});
				}
				this.isLoading = false;
		});
	}

	deletePost() {
		this.isLoading = true;
		this.api.post()
			.deletePost(this.postData._id).subscribe((response: any) => {
			this.isLoading = false;
			this.alertService.success({title: "Your conversation has been deleted"}).then(() => {
				this.updateService.updatePost(null);
				this.modalRef.close();
			});
		}, (err) => {
			this.alertService.error({title: "Could not delete your conversation."});
			this.isLoading = false;
		});
	}

}
