import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateEventModalComponent} from "../../modals/create-event-modal/create-event-modal.component";
import {EditEventModalComponent} from "../../modals/edit-event-modal/edit-event-modal.component";
import {Content} from "../../../model/content/Content";

@Injectable()
export class EventService {

	constructor(private modalService: NgbModal) {
	}

	public openEventCreationModal() {
		const modal = this.modalService.open(CreateEventModalComponent, {size: "lg", backdrop: "static"});
		// pass modal reference
		(<CreateEventModalComponent>modal.componentInstance).modalRef = modal;
	}

	public openEventEditModal(event: Content) {
		const modal = this.modalService.open(EditEventModalComponent, {size: "lg", backdrop: "static"});
		(<EditEventModalComponent>modal.componentInstance).modalRef = modal;
		(<EditEventModalComponent>modal.componentInstance).setUpEvent(event);
	}

}
