import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {MicroBlogPostRequest} from "../request/microblogpost/MicroBlogPostRequest";
import {Content} from "../content/Content";
import {ContentListResponse} from "../response/ContentListResponse";
import {ContentType} from "../data/enums/ContentType";
import {ReportRequest} from "../request/report/ReportRequest";
import {BlogPost} from "../data/social/BlogPost";

export class MicroBlogPostApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/blogpost";

	/**
	 * Creates a new blogpost with just the text content;
	 * @param request
	 */
	public createBlogPost(request): Observable<BlogPost> {
		return this.apiClient
			.authorizedOnly()
			.post(this.apiRoute, request);
	}

	/**
	 * Creates a new blogpost with just the text content;
	 * @param postId
	 * @param request
	 */
	public updateBlogPost(postId: string, request): Observable<BlogPost> {
		return this.apiClient
			.authorizedOnly()
			.patch(`${this.apiRoute}/${postId}`, request);
	}

	/**
	 * Publishes a post
	 * @param postId
	 * @param request
	 */
	public publishBlogPost(postId: string, request: {versionId: string}): Observable<BlogPost> {
		return this.apiClient
			.authorizedOnly()
			.put(`${this.apiRoute}/${postId}`, request);
	}

	public getMicroBlogPostMeta(microBlogPostId: string): Observable<BlogPost> {
		return this.apiClient.get(this.apiRoute + "/meta/" + microBlogPostId);
	}

	public uploadMedia(postId: string, images?: FileList): Promise<BlogPost> {
		const formData = new FormData();

		// append the images if any
		if (images && images.length) {
			for (let i = 0; i < images.length; i++) {
				formData.append("image", images[i], images[i].name);
			}
		}

		return this.apiClient
			.formDataRequest("POST", `${this.apiRoute}/${postId}/media`, formData);
	}

	public getUserMicroBlogPosts(): Observable<Array<BlogPost>> {
		return this.apiClient.get(`${this.apiRoute}/meta/`);
	}

	/**TODO REMOVE**/


	public editMBPost(mbId: string, request: MicroBlogPostRequest, images?: FileList): Promise<Content> {
		const formData = new FormData();

		// append the title and description
		formData.append("title", request.title);
		if (request.text && request.text.trim() !== "") {
			formData.append("description", request.text);
		}

		// append the images if any
		if (images && images.length) {
			for (let i = 0; i < images.length; i++) {
				formData.append("image", images[i], images[i].name);
			}
		}

		return this.apiClient
			.formDataRequest("PATCH", this.apiRoute + "/" + mbId, formData);
	}

	public getMicroBlogPosts(pageNumber: number): Observable<ContentListResponse> {
		return this.apiClient.get(`${this.apiRoute}/${pageNumber}/list`, {type: ContentType.MICROBLOGPOST});
	}

	public getMicroBlogPost(microBlogPostId: string): Observable<Content> {
		return this.apiClient.get(this.apiRoute + "/" + microBlogPostId);
	}

	public deleteMicroBlogPost(microBlogPostId: string): Observable<any> {
		return this.apiClient.delete(this.apiRoute + "/" + microBlogPostId);
	}

	public report(request: ReportRequest): Observable<any> {
		return this.apiClient.authorizedOnly().post(this.apiRoute + "/report", request);
	}

}
