import {Gender} from "../../data/enums/Gender";
import {User} from "../../data/user/User";
import {Interest} from "../../data/enums/Interest";
import {UserStatus} from "../../data/enums/UserStatus";

export class UserRequest {

	displayName?: string;
	bio?: string;

	gender?: Gender;
	interestedIn?: Interest;
	status: UserStatus;

	public static fromUser(user: User): UserRequest {
		return {
			displayName: user.displayName,
			bio: user.bio,
			gender: user.gender,
			interestedIn: user.interestedIn,
			status: user.status
		};
	}

}
