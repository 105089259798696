import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {UserSessionService} from "../services/user-session/user-session.service";

/**
 * Auth guard
 * This service prevents unauthorized users from using 'login-required' pages)
 * It is used in routing as provider for "CanActivate"
 */
@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(@Inject(UserSessionService) private userSessionService: UserSessionService,
				private router: Router) {
	}

	// functionality only accessible when user is logged in
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		// get value
		const isLoggedIn = this.userSessionService.isLoggedInValue;

		if (!isLoggedIn) {
			// fall back to login page
			this.router.navigate(["/"]);
		}

		// decision based on logged in state
		return isLoggedIn;
	}

}
