import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {TranslateModule} from "@ngx-translate/core";
import {ShopModule} from "../shop/shop.module";
import {SocialModule} from "../social/social.module";
import {CommonsModule} from "../commons/commons.module";
import {HomeFiltersComponent} from "./components/home-filters/home-filters.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {IonRangeSliderModule} from "ng2-ion-range-slider";
import {TagsModule} from "../tags/tags.module";
import {RouterModule, Routes} from "@angular/router";
import {MainFeedComponent} from "./components/main-feed/main-feed.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CanNavigate} from "../commons/guards/can-navigate";
import {NgxMasonryModule} from "ngx-masonry";
import {MainFeedSuggestionComponent} from "./components/main-feed-suggestion/main-feed-suggestion.component";
import {HomeSearchUrlService} from "./services/home-search-url/home-search-url.service";
import {UserModule} from "../user/user.module";
import {InlineSVGModule} from "ng-inline-svg";

const routes: Routes = [
	// Product page
	{
		path: "",
		component: HomePageComponent,
		canDeactivate: [CanNavigate]
	}
];


@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		TranslateModule.forChild(),
		NgbModule,
		ShopModule,
		SocialModule,
		TagsModule,
		CommonsModule,
		FormsModule,
		IonRangeSliderModule,
		NgxMasonryModule,
		UserModule,
		InlineSVGModule,

		// scroll paging
		InfiniteScrollModule,
	],
	declarations: [
		HomePageComponent,
		MainFeedComponent,
		HomeFiltersComponent,
		MainFeedSuggestionComponent
	],
	providers: [
		HomeSearchUrlService,
		{
			provide: "Window",
			useValue: window
		}
	]
})
export class HomeModule {
}
