import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ConversationsComponent} from "./components/conversations/conversations.component";
import {SocialFeedComponent} from "./components/social-feed/social-feed.component";
import {SocialButtonLikeComponent} from "./components/social-button/social-button-like.component";
import {SocialButtonsComponent} from "./components/social-buttons/social-buttons.component";
import {SocialButtonAddToCollectionComponent} from "./components/social-button/social-button-add-to-collection.component";
import {SocialButtonShareComponent} from "./components/social-button/social-button-share.component";
import {SocialButtonCommentComponent} from "./components/social-button/social-button-comment.component";
import {ConversationsItemSmallComponent} from "./components/conversations-item-small/conversations-item.component-small";
import {UserModule} from "../user/user.module";
import {CommentsModalComponent} from "./modals/comments-modal/comments-modal.component";
import {CommentsDisplayComponent} from "./components/comments-display/comments-display.component";
import {EventFeedItemComponent} from "./components/event-feed-item/event-feed-item.component";
import {ConversationsFeedItemComponent} from "./components/conversations-feed-item/conversations-feed-item.component";
import {CollectionService} from "./services/collection/collection.service";
import {CreatePostModalComponent} from "./modals/create-post-modal/create-post-modal.component";
import {CreateBlogpostModalComponent} from "./modals/create-blogpost-modal/create-blogpost-modal.component";
import {NgxEditorModule} from "ngx-editor";
import {FormsModule} from "@angular/forms";
import {BlogPostPageComponent} from "./pages/blogpost-page/blogpost-page.component";
import {RouterModule, Routes} from "@angular/router";
import {BlogPostCommentsComponent} from "./components/blogpost-comments/blogpost-comments.component";
import {PostModalComponent} from "./modals/post-modal/post-modal.component";
import {EventPageComponent} from "./pages/event-page/event-page.component";
import {EventResolverService} from "./resolvers/event-resolver.service";
import {EventCommentsComponent} from "./components/event-comments/eventCommentsComponent";
import {EditPostModalComponent} from "./modals/edit-post-modal/edit-post-modal.component";
import {EditBlogpostModalComponent} from "./modals/edit-blogpost-modal/edit-blogpost-modal.component";
import {SocialButtonReportComponent} from "./components/social-button/social-button-report.component";
import {MomentModule} from "ngx-moment";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CreateEventModalComponent} from "./modals/create-event-modal/create-event-modal.component";
import {NgDatepickerModule} from "ng2-datepicker";
import {EventService} from "./services/event/event.service";
import {UgcReFetchService} from "./services/ugc-feed/ugc-re-fetch.service";
import {LikeService} from "./services/like/like.service";
import {EditEventModalComponent} from "./modals/edit-event-modal/edit-event-modal.component";
import {ReportModalComponent} from "./modals/report-modal/report-modal.component";
import {AngularDateTimePickerModule} from "angular2-datetimepicker";
import {GradientSelectorComponent} from "./components/gradient-selector/gradient-selector.component";
import {EscapeHtmlPipe} from "./pipes/keep-html.pipe";
import {ModelModule} from "../model/model.module";
import {PostModalGalleryComponent} from "./modals/post-modal/partial/post-modal-gallery/post-modal-gallery.component";
import {PostModalAudioPlayerComponent} from "./modals/post-modal/partial/post-modal-audio-player/post-modal-audio-player.component";
import {PostModalSpotifyPlayerComponent} from "./modals/post-modal/partial/post-modal-spotify-player/post-modal-spotify-player.component";

const routes: Routes = [
	{
		path: "event/:id",
		component: EventPageComponent,
		resolve: {
			event: EventResolverService
		}
	}
];

/**
 * Social module.
 *
 * Responsible for all social interactions including (but not limited to):
 * - Commenting
 * - Liking
 * - Conversations panel
 * - External social share
 */
@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		NgbModule,
		UserModule,
		FormsModule,
		RouterModule.forChild(routes),
		// ngx-editor
		NgxEditorModule,
		MomentModule,
		ModelModule,

		NgDatepickerModule,
		AngularDateTimePickerModule,

		// scroll paging
		InfiniteScrollModule,
	],
	declarations: [
		SocialFeedComponent,
		SocialButtonLikeComponent,
		SocialButtonsComponent,
		SocialButtonAddToCollectionComponent,
		SocialButtonShareComponent,
		SocialButtonCommentComponent,
		SocialButtonReportComponent,
		CommentsModalComponent,
		CommentsDisplayComponent,
		EventFeedItemComponent,
		ConversationsComponent,
		ConversationsItemSmallComponent,
		ConversationsFeedItemComponent,
		CreatePostModalComponent,
		CreateBlogpostModalComponent,
		BlogPostPageComponent,
		BlogPostCommentsComponent,
		PostModalComponent,
		EventPageComponent,
		EventCommentsComponent,
		EditPostModalComponent,
		EditBlogpostModalComponent,
		CreateEventModalComponent,
		EditEventModalComponent,
		ReportModalComponent,
		GradientSelectorComponent,
		EscapeHtmlPipe,
		PostModalGalleryComponent,
		PostModalAudioPlayerComponent,
		PostModalSpotifyPlayerComponent
	],
    exports: [
        ConversationsComponent,
        SocialFeedComponent,
        SocialButtonsComponent,
        CommentsModalComponent,
        CommentsDisplayComponent,
        CreatePostModalComponent,
        EventFeedItemComponent,
        ConversationsFeedItemComponent,
        CreateBlogpostModalComponent,
        PostModalComponent,
        EditPostModalComponent,
        EditBlogpostModalComponent,
        CreateEventModalComponent,
        EditEventModalComponent,
        SocialButtonShareComponent,
        GradientSelectorComponent,
        SocialButtonLikeComponent
    ],
	entryComponents: [
		CommentsModalComponent,
		CreatePostModalComponent,
		CreateBlogpostModalComponent,
		PostModalComponent,
		EditPostModalComponent,
		EditBlogpostModalComponent,
		CreateEventModalComponent,
		EditEventModalComponent,
		ReportModalComponent
	],
	providers: [
		NgbModal,
		CollectionService,
		EventResolverService,
		EventService,
		UgcReFetchService,
		LikeService
	]
})
export class SocialModule {
}
